import { Division, Event } from "../../types";
import WordUtils from "../../utils/word-utils";
import EventUtils from "../utils/event-utils";

class EventNavigationComponent {

  container_selector: string
  subtitle: string
  division: Division

  event_content_function: (division_id: number, loading_callback: () => void, callback: (response: Array<Event>) => void) => void
  on_event_change_callback: (event: Event) => void
  
  constructor(container_selector: string, subtitle: string, division: Division, event_content_function: (division_id: number, loading_callback: () => void, callback: (response: Array<Event>) => void) => void) {
    this.container_selector = container_selector;
    this.subtitle = subtitle;
    this.division = division;

    this.event_content_function = event_content_function;
  }

  render(): void {
    this.event_content_function(
      this.division.id,
      () => { },
      (events) => {
        const past_events = events.filter(event => event.date < new Date().getTime())
        
        this.setEvent(past_events)

        this.on_event_change_callback(past_events[past_events.length - 1])
      }
    )
  }

  setEvent(past_events: Array<Event>, target_event: Event = undefined): void {
    const last_event = target_event === undefined ? past_events[past_events.length - 1] : target_event

    const next_events = past_events.filter(event => event.date > last_event.date)
    const previous_events = past_events.filter(event => event.date < last_event.date)

    const next = this.getEarliest(next_events);
    const previous = this.getLatest(previous_events);

    const content = this.getContent(previous, last_event, next);
    $(this.container_selector).html(content);

    this.regChangeListeners(previous, last_event, next, past_events)
  }

  getContent(previous: Event, active_event: Event, next: Event): string {
    return `
      <div class="col-md-12 event-navigation">
        <div class="heading-section ftco-animate fadeInUp ftco-animated">
          <span class="subheading">${this.subtitle}</span>
          
          <h2 class="mb-4">
            <span class="previous ${previous ? 'active' : ''}"><i class="mdi mdi-chevron-left"></i></span> 
            ${ WordUtils.FIRST_UPPER_CASE(active_event.track.name) }
            <span class="next ${next ? 'active' : ''}"><i class="mdi mdi-chevron-right"></i></span>
          </h2>
          
        </div>
      </div>
    `
  }

  regChangeListeners(previous: Event, active_event: Event, next: Event, past_events: Array<Event>): void {
    EventUtils.REMOVE_ALL_EVENTS(`.event-navigation .previous.active`)
    EventUtils.REGISTER_CLICK_EVENT(`.event-navigation .previous.active`, () => {
      this.setEvent(past_events, previous)
      this.navigateToEvent(previous)
    })

    EventUtils.REMOVE_ALL_EVENTS(`.event-navigation .next.active`)
    EventUtils.REGISTER_CLICK_EVENT(`.event-navigation .next.active`, () => {
      this.setEvent(past_events, next)
      this.navigateToEvent(next)
    })
  }

  onEventChange(callback: (event: Event) => void): EventNavigationComponent {
    this.on_event_change_callback = callback;
    return this;
  }

  private navigateToEvent(event: Event) {
    this.on_event_change_callback(event);
  }

  private getEarliest(events: Array<Event>): Event | undefined {
    return events.length === 0 
      ? undefined 
      : events.reduce(function(left, right) {
          return left.date < right.date ? left : right;
        });
  }

  private getLatest(events: Array<Event>): Event | undefined {
    return events.length === 0 
      ? undefined 
      : events.reduce(function(left, right) {
          return left.date > right.date ? left : right;
        });
  }
}

export default EventNavigationComponent
import { ResultEntry, StandingEntry, Team, User, Vehicle } from "../../../types";
import ResultUtils from "../../utils/result-utils";
import StandingUtils from "../../utils/standing-utils";

class TeamBannerComponent {

    container_selector: string
    team: Team
    position: number
  
    constructor(container_selector: string, team: Team, position: number) {
      this.container_selector = container_selector;
      this.team = team;
      this.position = position;
    }
  
    render(): void {
      const content = this.getContent(this.team);
      $(this.container_selector).append(content);
    }
  
    getContent(team: Team): string {  
      return `
        <div class="team-banner-container col-md-6">
          <div class="team-header container" style="display: block; border-top:4px solid ${team.color ? team.color : ResultUtils.GET_COLOR_BY_VEHICLE(team.vehicle)};">
            
            <div class="row g-2">
              <div class="team-driver-details col-md-12 col-xl-12">
                <div class="row" style="align-items: center;">
                  <div class="team-details col-xl-3 col-lg-12" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; padding-right: 0px;">
                    ${ this.getTeamDetails(team) }
                  </div>

                  <div class="col-md-3">
                    ${ this.getUsersContent(team.users, team.color, team.vehicle) }
                  </div>

                  <div class="col-md-6">
                    ${ team.vehicle 
                      ? `<div class="team-car-details col-md-12 ${team.vehicle.vehicle_category === 'F121' ? 'f1' : ''}">
                          <img src="https://images.onlineformularacing.co.uk/Vehicles/${team.vehicle.id}.webp" />
                        </div>`
                      : `<div class="team-car-details col-md-12">
                          <img src="https://images.onlineformularacing.co.uk/Vehicles/unknown.webp" />
                        </div>`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      `
    }

    getTeamDetails(team: Team) : string {
        const vehicle = team.vehicle
        return `<div class="username">${team.name}</div>
                <div class="constructor">${vehicle ? `${vehicle?.manufacturer} ${vehicle?.name}` : 'No Specific Vehicle.'}</div>`
    }

    getUsersContent(users: Array<User>, team_color: string, vehicle: Vehicle): string {
      let content = '<table class="race-leaderboard leaderboard" id="race-leaderboard" style="border-collapse: collapse !important;">'

      users.forEach((user, index) => {
        content += `
          <tr class="driver">
            <td style="padding-right: 0px;" class="position"><span>${index + 1}</span></td>
            <td class="driver" style="border-left: 4px solid ${team_color ? team_color : ResultUtils.GET_COLOR_BY_VEHICLE(vehicle)};">
                <a href="#/results" class="clickable-username color-white">${user.username}</a>
            </td>
          </tr>
        `
      })

      return content + '</table>'
    }
}

export default TeamBannerComponent
import ApiUtils from '../../utils/api-utils'
import { api } from '../../config'
import { RosterEntry, SubmitRosterEntryRequest } from '../../types'
import store from '../../store/store'

const GET_ROSTER = (division_id: number, success_callback: (response: Array<RosterEntry>) => void = () => {}, error_callback = () => {}): JQuery.jqXHR<any> => {
  return $.ajax({
    type: "GET",
    url: `${api}/division/team?division_id=${division_id}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  })
}


const SUBMIT_ROSTER = (division_id: number, roster: Array<SubmitRosterEntryRequest>, loading_callback = () => {}, success_callback = () => {}, error_callback = () => {}): void => {
  loading_callback()

  $.ajax({
    type: "POST",
    url: `${api}/roster`,
    data: JSON.stringify({
      season_id: division_id,
      roster: roster,
      token: store.ACCESS_TOKEN()
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  })
}

export { GET_ROSTER, SUBMIT_ROSTER }
import { TimeTrialEvent, TimeTrialEventEntry } from "../../../types";
import YoutubeUtils from "../../../utils/youtube-utils";
import TimeUtils from "../../utils/time-utils";
import Modal from "../modal";

class TimeTrialEventEntryDetailsModal extends Modal<TimeTrialEventEntry> {

  event: TimeTrialEvent

  constructor(event: TimeTrialEvent, entry: TimeTrialEventEntry) {
      super('time-trial-event-details-modal', 'Entrty Details', entry)
      this.event = event;
  }

  getTemplate(entry: TimeTrialEventEntry): string {
    return `
      <div class="modal fade" id="time-trial-entry-modal" tabindex="-1" role="dialog" aria-labelledby="time-trial-entry-modals" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="time-trial-modals">Entry Details</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="form-group">
                  <label for="time-trial-entry-event">Event</label>
                  <select style="-webkit-appearance: none;" id="time-trial-entry-event" class="form-control" disabled>
                    <option value="${this.event.id}">${this.event.title} (${this.event.track.name})</option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="time-trial-entry-time">Time</label><br/>
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="mdi mdi-timetable"></i>
                      </span>
                    </div>
                    <input id="time-trial-entry-time" autocomplete="off" type="text" value="${TimeUtils.GET_FORMATTED_TIME(entry.time)}" placeholder="01:12.901" class="form-control" disabled/>
                  </div>
                </div>

                <div class="form-group">
                  <label for="time-trial-entry-video-link">Video</label><br/>
                  ${ YoutubeUtils.GET_PREVIEW(entry.media_link) }
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">Close</button>
              </div>
          </div>
        </div>
      </div>
    `
  }

}

export default TimeTrialEventEntryDetailsModal
export type Page = {
  name: string
  path: string
}

export type PageDefintions = {
  INDEX: Page
  PROFILE: Page
  INBOX: Page
  REPORTING: Page
  RESULTS: Page
  TEAMS_ROSTER: Page
  STANDINGS: Page
  LANDING: Page
  DEFAULT: Page
  WELCOME: Page
  LEAGUE_DETAILS: Page
  LEAGUE_USERS: Page
  DIVISIONS: Page
  CREATE_LEAGUE: Page
  LOGIN: Page
  REGISTER: Page
  REQUEST_PASSWORD_RESET: Page
  RESET_PASSWORD: Page
  TIME_TRIAL: Page
  STEWARDING: Page
  USERS: Page
  EVENTS: Page
  EVENTS_SIGNUP: Page
  TEAMS: Page
  RACE_RESULTS: Page
  QUALI_RESULTS: Page
}

export const Pages: PageDefintions = {
  LOGIN: {
		name: 'Login',
		path: '/login.html'
	},
  REGISTER: {
		name: 'Register',
		path: '/register.html'
	},
  REQUEST_PASSWORD_RESET: {
		name: 'Request password reset',
		path: '/request-password-reset.html'
	},
  RESET_PASSWORD: {
		name: 'Reset password',
		path: '/reset-password.html'
	},
  INDEX: {
		name: 'Loading',
		path: '#/'
	},
  PROFILE: {
    name: 'Profile',
    path: '#/profile'
  },
  INBOX: {
    name: 'Inbox',
    path: '#/inbox'
  },
  LANDING: {
    name: 'Landings page',
		path: '/landing-page.html'
  },
  DEFAULT: {
		name: 'Loading',
		path: '#/'
	},
  WELCOME: {
    name: 'Welcome',
    path: '#/welcome'
  },
  LEAGUE_DETAILS: {
    name: 'League Details',
    path: '#/league'
  },
  LEAGUE_USERS: {
    name: 'League Users Dashboard',
    path: '#/league-users'
  },
  DIVISIONS: {
    name: 'Divisions Dashboard',
    path: '#/divisions'
  },
  REPORTING: {
    name: 'Reporting Dashboard',
    path: '#/reporting'
  },
  TEAMS_ROSTER: {
    name: 'Teams Roster Dashboard',
    path: '#/teams-roster'
  },
  RESULTS: {
    name: 'Results Dashboard',
    path: '#/results'
  },
  STANDINGS: {
    name: 'Standings Dashboard',
    path: '#/standings'
  },
  CREATE_LEAGUE: {
    name: 'Create League',
		path: '/league-creator.html'
  },
  TIME_TRIAL: {
    name: 'Time Trial',
		path: '#/time-trial'
  },
  STEWARDING: {
    name: 'Stewarding',
		path: '#/stewarding'
  },
  USERS: {
    name: 'Users',
    path: '#/users-overview'
  },
  EVENTS: {
    name: 'Events',
    path: '#/events-overview'
  },
  EVENTS_SIGNUP: {
    name: 'Events Signup',
    path: '#/events-signup-overview'
  },
  TEAMS: {
    name: 'Teams',
		path: '#/teams-overview'
  },
  RACE_RESULTS: {
    name: 'Race Results',
		path: '#/race-result-overview'
  },
  QUALI_RESULTS: {
    name: 'Qualifying Results',
		path: '#/qualifying-result-overview'
  }
}

export interface UniqueEntity {
  id: number
}

export type League = {
  code: string
  name: string
  shortName?: string
  color: string
  game?: Game
  year?: number
  top_tier_id: number
  pages: Array<Page>
  enabled: boolean
}

export interface Division extends UniqueEntity {
  name: string
  code: string
  game: Game
  active: boolean
}
export interface PositionPointsDistribution {
  1: PositionPointDistribution
  2: PositionPointDistribution
  3: PositionPointDistribution
  4: PositionPointDistribution
  5: PositionPointDistribution
  6: PositionPointDistribution
  7: PositionPointDistribution
  8: PositionPointDistribution
  9: PositionPointDistribution
  10: PositionPointDistribution
  11: PositionPointDistribution
  12: PositionPointDistribution
  13: PositionPointDistribution
  14: PositionPointDistribution
  15: PositionPointDistribution
  16: PositionPointDistribution
  17: PositionPointDistribution
  18: PositionPointDistribution
  19: PositionPointDistribution
  20: PositionPointDistribution
}
export interface PointDistribution extends UniqueEntity {
  name: string,
  description: string,
  points: PositionPointsDistribution
}

export interface PositionPointDistribution {
  fastest_lap: number
  points: number
}
export interface DriverStandingEntry extends StandingEntry {
  user: User
  vehicle: Vehicle
}

export interface StandingEntry {
  points: number
  team: Team
}
export interface UserPrivileges extends UniqueEntity {
  username: string
  flag: string
  league_privileges: Array<Privilege>
  division_privileges: Array<Privilege>
}

export interface Privilege extends UniqueEntity {
  name: string
  description: string
}

export interface Event extends UniqueEntity {
  position: number
  date: number
  track: Track
  season: Division
  laps: number
  description: string
  event_configuration: any
  event_configuration_schema_id: number
  stream_url: string
  race_results: Array<RaceResultEntryModel>
  qualifying_results: Array<Array<QualifyingResultEntryModel>>
  qualifying_length: QualifyingLength
  event_race_points: RacePoints
  event_qualifying_points: QualifyingPoints
  event_signups: Array<EventSignup>
}

export interface EventSignup {
  user: User,
  response: boolean
}

export interface RacePoints extends UniqueEntity {
  name: string
  points_distribution: Array<RacePointsPosition>
}

export interface QualifyingPoints extends UniqueEntity {
  name: string
  points_distribution: Array<QualifyingPointsPosition>
}

export interface QualifyingPointsPosition extends UniqueEntity {
  position: number
  points: number
  qualifying_result_type: ResultType
}

export interface RacePointsPosition extends UniqueEntity {
  position: number
  points: number
  fastest_lap_points: number
  race_result_type: ResultType
}

export interface QualifyingLength extends UniqueEntity {
	name: string
	code: string
}

export interface Game extends UniqueEntity {
	name: string
  code: string
  console: Platform
}

export interface Platform extends UniqueEntity {
  name: string
  code: string
}

export interface Track extends UniqueEntity {
  name: string
  description: string
  flag: string
  country: string
}

export interface User extends UniqueEntity {
  username: string
  number: number
  country_flag: string
  user_image_id: number
}

export interface Vehicle extends UniqueEntity {
  name: string
  game: Game
  manufacturer: string
  vehicle_category: string
}

export interface Team extends UniqueEntity {
  name: string,
  color: string,
  vehicle: Vehicle
  users: Array<User>
}

export interface RosterEntry {
	id: number
	user: User
	vehicle: Vehicle
}

export interface AdditionalInfo extends UniqueEntity {
  date: number
  description: string
  user: User
}

export interface ReportVote extends UniqueEntity {
  date: number,
  penalties: Array<PenaltyDefinition>
  penalty_user: User
  is_users: boolean
}

export interface Report extends UniqueEntity {
	media_link: string
	description: string
	reporter_user: User
  reported_users: Array<User>
	date: Date
  track: Track,
  event_id: number,
  report_votes: Array<ReportVote>
  report_penalties: Array<ReportPenalty>
  report_additional_info: Array<AdditionalInfo>
}

export interface PenaltyDefinition extends UniqueEntity {
  name: string
  code: string
  penalty_points: number
}

export interface ReportPenalty extends UniqueEntity {
  date: number
  description: string
  penalty: PenaltyDefinition
  issuer_user: User
  penalty_user: User
  served_event: Event
  received_event: Event 
}

export interface TimeTrialEvent extends UniqueEntity {
  title: string
  vehicle: Vehicle
  track: Track
}

export interface TimeTrialEventEntry extends UniqueEntity {
  user: User
  time: number
  media_link: string
  verified: boolean
}

export interface ResultEntry extends UniqueEntity {
  finish_position: number,
  user: User,
  team: Team,
  vehicle: Vehicle,
  result_type: ResultType
  points: number
}

export interface ResultType extends UniqueEntity {
  name: string,
  code: string
}

export interface RaceResultEntryModel extends ResultEntry {
  laps: number,
  total_race_time: number,
  fastest_lap_time: number,
  finish_position: number,
  fastest_lap: boolean,
  stops: number,
  start_position: number,
  race_result_type: ResultType,
}

export interface RaceResultEntry extends ResultEntry {
  laps: number,
  total_race_time: number,
  fastest_lap_time: number,
  finish_position: number,
  fastest_lap: boolean,
  stops: number,
  start_position: number,
}

export interface QualifyingResultEntryModel extends ResultEntry {
  fastest_time: number
  tyre_compound: TyreCompound,
  qualifying_result_type: ResultType
}

export interface QualifyingResultEntry extends ResultEntry {
  fastest_time: number
  tyre_compound: TyreCompound
}

export interface TyreCompound extends UniqueEntity {
  name: string
  code: string
  game: Game
}

export type AuthorizationReponse = {
  success: boolean
  message: string
  code: number
  user_id: string
}

export interface Response {
  success: boolean,
  code: number,
  message?: string
}

export interface RetrievalResponse<T> extends Response {
  values?: Array<T>
  object: T
}

export interface SubmitSignUpRequest {
  accepted: boolean
  event_id: number
  user_id: number
}

export interface SubmitReportRequest {
  event_id: number
  user_ids: Array<number>
  media_link: string
  description: string
}

export interface SubmitAdditionalInfoRequest {
  report_id: number,
  description: string
}

export interface SubmitVoteRequest {
  report_id: number,
  penalty_ids: Array<number>,
  penalty_user_id: number
}

export interface SubmitPenaltyRequest {
  report_id: number,
  penalty_id: number,
  penalty_user_id: number,
  received_event_id: number,
  description: string
}

export interface ServePenaltyRequest {
  report_penalty_id: number
  event_id: number
}

export interface SubmitTimeTrialEventEntryRequest {
  time_trial_event_id: number
  time: number
  media_link: string
}

export interface SubmitRosterEntryRequest {
  vehicle_id: number, 
  user_id: number
}

export interface SubmitDivisionPrivilegeRequest {
  division_id: number
  division_privilege_ids: Array<number>
  user_id: number
}
export interface SubmitLeaguePrivilegeRequest {
  league_id: number
  league_privilege_ids: Array<number>
  user_id: number
}
export interface SubmitRaceResultRequest {
  event_id: number
  results: Array<SubmitRaceResultEntryRequest>
}

export interface SubmitRaceResultEntryRequest {
  laps: number
  total_race_time: number
  fastest_lap_time: number
  finish_position: number
  fastest_lap: boolean
  points: number
  stops: number
  start_position: number
  race_result_type_id: number
  user_id: number
  vehicle_id: number
  division_team_id: number
}

export interface SubmitQualifyingResultRequest {
  event_id: number
  results: Array<SubmitQualifyingResultEntryRequest>
}

export interface SubmitQualifyingResultEntryRequest {
  fastest_time: number
  points: number,
  finish_position: number,
  tyre_compound_id: number,
  qualifying_result_type_id: number,
  user_id: number,
  vehicle_id: number,
  division_team_id: number,
}

export interface CreateOnineRacingLeagueRequest {
  name: string,
  code: string,
  description: string,
  facebook_url : string | null,
  youtube_url : string | null,
  instagram_url : string | null,
  twitch_url : string | null,
  twitter_url : string | null,
  discord_url : string | null,
  slack_url: string | null
}

export interface DivisionRequest {
  game_id: number
  name: string
  code: string
}

export interface CreateEventRequest {
  date: number,
  description: string,
  event_configuration: any,
  event_configuration_schema_id: number,
  division_id: number,
  event_id: number,
  track_id: number,
  stream_url: string,
}

export { CreateEventRequest as EditEventRequest }

export interface CreateTeamRequest {
  name: string
  division_id: number
  vehicle_id: number
}

export interface UserDivisionSubmission {
  user_id: number
  division_id: number
}

export interface TeamUserSubmission {
  user_id: number
  division_team_id: number
}

export interface EditTeamRequest {
  id: number,
  name: string,
  vehicle_id: number,
  division_id: number,
}

export interface DeleteTeamRequest {
  division_team_id: number
}

export interface OnlineRacingLeague extends UniqueEntity {
  name: string,
  code: string,
  description: string,
  owner: User
  administrators: Array<User>
  facebook_url : string | null,
  youtube_url : string | null,
  instagram_url : string | null,
  twitch_url : string | null,
  twitter_url : string | null,
  discord_url : string | null,
  slack_url: string | null
}

export type Duration = {
  years: number
  months: number
  days: number
  hours: number
  minutes: number
}

export type LocalDate = {
	year: number
	month: LocalMonth
	day: LocalDay
	hour: number
	minute: number
	second: number
}

export type LocalMonth = {
	name: string
	number: number
}

export type LocalDay = {
	name: string
	number: number
}

export interface Message {
  id: number
  subject: string
  message: string
  date: number
  read: boolean
  league: OnlineRacingLeague
}
import { Division, Team, User, Vehicle } from "../../../types";
import { TeamActions, VehicleActions } from "../../actions";
import LoaderUtils from "../../utils/loader-utils";
import EventUtils from "../../utils/event-utils";
import Modal from "../modal";
import ConfirmationModal from "../confirmation-modal";
import AlertUtils from "../../../utils/alert-utils";
import PermissionUtils from "../../../utils/permission-utils";
import CardComponent from "../../components/card/card-component";
import { or } from "ajv/dist/compile/codegen";
import ValidationUtils from "../../utils/validation-utils";

const id = 'create-team-modal'
const title = 'Create a team'

const DELETE_TEAM_PERMISSION = 11

const NONE_VEHICLE: Vehicle = { 
  id: -1, 
  name: 'None', 
  manufacturer: '', 
  game: { 
    id: -1, 
    name: '', 
    code: '', 
    console: { 
      id: -1, 
      name: '', 
      code: ''
    }
  }, 
  vehicle_category: ''
}
class EditTeamModal extends Modal<Team> {

    division: Division
    origin_card: CardComponent<User>
  
    constructor(division: Division, team: Team, origin_card: CardComponent<User>) {
      super(id, title, team)
      this.division = division;
      this.origin_card = origin_card;
    }
  
    getTemplate(): string {
      return `
        <div class="modal fade" id="${this.id}" tabindex="-1" role="dialog" aria-labelledby="submit-report-modals" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="submit-report-modals">${title}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">    
                <div class="form-group vehicles">
                  <label>Vehicle</label>
                  <div class="sk-three-bounce select-loader loader">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                  </div>
  
                  <select class="loaded-content form-control" required>
                      
                  </select>
                </div>      

                <div class="form-group name">
                  <label for="name">Name</label><br/>
                  <div class="input-group mb-2">
                    <input type="text" class="form-control" placeholder="" aria-label="">
                  </div>
                  <p style="font-size: 90%">&nbsp;&nbsp;<b>Note:</b> Provide an <b>appropriate</b> name and <b>avoid</b> real sponsor names.</p>
                </div>
                <div class="form-group color">
                  <label for="name">Color</label><br/>
                  <div class="input-group mb-2">
                    <input type="text" class="form-control" placeholder="#" aria-label="">
                  </div>
                  <p style="font-size: 90%">&nbsp;&nbsp;<b>Note:</b> Provide the hex color code to use for this team.</p>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-pill delete-btn ${PermissionUtils.HAS_PERMISSION(DELETE_TEAM_PERMISSION) ? '"' : 'disabled" disabled'}>
                  <div style="display:none; height: 20px;" class="sk-three-bounce loader">
                    <div style="background-color:white;" class="bounce1"></div>
                    <div style="background-color:white;" class="bounce2"></div>
                    <div style="background-color:white;" class="bounce3"></div>
                  </div>
                  <span class="loaded-content">Delete</span>
                </button>

                <button id="save-team-btn" type="button" class="btn btn-primary btn-pill">
                  <div style="display:none; height: 20px;" class="sk-three-bounce loader">
                    <div style="background-color:white;" class="bounce1"></div>
                    <div style="background-color:white;" class="bounce2"></div>
                    <div style="background-color:white;" class="bounce3"></div>
                  </div>
                  <span class="loaded-content">Save</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      `
    }
  
    getModalActions(team: Team): void {
      this.getFormData(team)

      EventUtils.REMOVE_ALL_EVENTS(`#${this.id} button.delete-btn`)

      if(PermissionUtils.HAS_PERMISSION(DELETE_TEAM_PERMISSION)) {
        EventUtils.REGISTER_CLICK_EVENT(`#${this.id} button.delete-btn`, () => {
          new ConfirmationModal(`Are you sure you want to delete the '<b>${team.name}</b>' team?`,
            () => {
              TeamActions.DELETE(
                { division_team_id: team.id },
                () => { LoaderUtils.LOADING(`#${this.id} button.delete-btn`) },
                () => {
                  AlertUtils.NOTIFY('Team was succesfully deleted.', 'Success!')
                  LoaderUtils.LOADED(`#${this.id} button.delete-btn`, 'Delete')

                  this.close()
                }
              )
            }
          ).render()
        })
      }

      EventUtils.REMOVE_ALL_EVENTS(`#${this.id} button#save-team-btn`)
      EventUtils.REGISTER_CLICK_EVENT(`#${this.id} button#save-team-btn`, () => {
        const name = <string> $(`#${this.id} .name input`).val()
        const color = <string> $(`#${this.id} .color input`).val()
        const vehicle_id = <string> $(`#${this.id} .vehicles select`).val()

        let is_valid = true;

        if(color.startsWith("#")) {
          ValidationUtils.RESET(`.color input`)
        } else {
          ValidationUtils.INVALIDATE(`.color input`, 'Hex code has to start with #.')
          is_valid = false;
        }

        if(! is_valid) {
          return;
        }

        const request = {
          id: team.id,
          division_id: this.division.id,
          vehicle_id: parseInt(vehicle_id),
          name: name,
          color: color
        }

        TeamActions.EDIT(
          request,
          () => { LoaderUtils.LOADING(`#${this.id} button#save-team-btn`) },
          () => {
            this.entity.name = name
            this.entity.vehicle.id = parseInt(vehicle_id)

            this.origin_card.title = name

            this.close();
            LoaderUtils.LOADED(`#${this.id} button#save-team-btn`, 'Save')
          },
          () => {
            LoaderUtils.LOADED(`#${this.id} button#save-team-btn`, 'Save')
          }
        )
      })
    }

    private getFormData(team: Team): void {
      if(team.vehicle === undefined) {
        team.vehicle = NONE_VEHICLE
      }

      VehicleActions.GET_VEHICLES(
        this.division.game.id,
        () => { LoaderUtils.LOADING(`#${this.id} .vehicles`) },
        (vehicles_response) => { 
          vehicles_response.unshift(NONE_VEHICLE);
          const vehicles_content = this.getVehiclesContent(vehicles_response, team.vehicle?.id);
          LoaderUtils.LOADED(`#${this.id} .vehicles`, vehicles_content) 
        },
      )

      $(`#${this.id} .name input`).val(team.name)
      $(`#${this.id} .color input`).val(team.color)
    }
  
    private getVehiclesContent(vehicles: Array<Vehicle>, vehicle_id: number): string {
      const hasDifferentCategories = [ ...new Set(vehicles.filter(vehicle => vehicle.vehicle_category !== '').map(vehicle => vehicle.vehicle_category)) ].length > 1

      let vehicles_content = ''
  
      vehicles.forEach(vehicle => {
        vehicles_content += `<option ${vehicle.id === vehicle_id ? 'selected' : ''} value="${vehicle.id}">${vehicle.manufacturer} ${vehicle.name} ${hasDifferentCategories ? `(${vehicle.vehicle_category})` : ''}</option>`
      })
  
      return vehicles_content;
    }    
  }
  
  export default EditTeamModal
import { Division, OnlineRacingLeague, UniqueEntity } from "../../../types"
import PermissionUtils from "../../../utils/permission-utils"
import EventUtils from "../../utils/event-utils"
import LoaderUtils from "../../utils/loader-utils"

const ID = 'league-details-card'
const SELECTOR = '#league-details-card'

class LeagueDetailsComponent {

  league: OnlineRacingLeague;
  
  container_selector: string;

  constructor(container_selector: string, league: OnlineRacingLeague) {
    this.league = league;
    this.container_selector = container_selector;
  }

  render(): void {    
    // 1. Generate global card html and attach to container.  
    const card = this.getCard()
    $(this.container_selector).html(card)
    
    // 3. Register save click event
    
  }

  reload(): void {
    this.render()
  }

  private getCard() {
    return `
      <div class="ofr-card col-lg-12">
        <div class="card card-default">
          <div class="card-header justify-content-between align-items-center card-header-border-bottom"
            style="padding-left: 2.5em; padding-right: 2.5em">
            <h2 style="flex: 4 0 0;">${this.league.name}</h2>
          </div>
          <div id="${ID}" class="card-body slim-scroll"
            style="max-height:350px; overflow: scroll; padding: 0 2.5em;">  
            <div class="card-content loaded-content">
              Some details of the league will be shown here, in like... 2024-ish.
            </div>
          </div>
        </div>
      </div>
    `
  }
}

export default LeagueDetailsComponent
import Store from '../../store/store'
import { OnlineRacingLeague, Pages } from '../../types'
import { LeagueActions } from '../actions'
import LeagueListComponent from '../components/card/league-list-component'
import EventUtils from '../utils/event-utils'


const ON_PAGE_LOAD = (my_leagues: Array<OnlineRacingLeague>, my_joined_leagues: Array<OnlineRacingLeague>): void => {
  if(my_leagues.length === 0) {
    $("#create-league-btn span.title").html('CREATE YOUR OWN LEAGUE')
    $("#create-league-btn span.subtitle").html('All users can create up to one league of their own.')

    EventUtils.REGISTER_CLICK_EVENT(
      `#create-league-btn`, 
      () => {
        window.location.pathname = Pages.CREATE_LEAGUE.path
      }
    )
  } else {
    $("#create-league-btn span.title").html(`${my_leagues[0].name} (${my_leagues[0].code})`)
    $("#create-league-btn span.subtitle").html('Go to your league.')

    EventUtils.REGISTER_CLICK_EVENT(
      `#create-league-btn`, 
      () => {
        Store.SET_ACTIVE_LEAGUE(my_leagues[0].id)
        Store.UNSET_ACTIVE_DIVISION()
        window.location.pathname = "/"
      }
    )
  }

  new LeagueListComponent(
    'league-list-container',
    LeagueActions.GET_ALL,
    LeagueActions.SEARCH_BY_NAME,
    my_leagues,
    my_joined_leagues
  ).render()
}

export { ON_PAGE_LOAD }
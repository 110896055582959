require('../css/sleek.scss');

import "sizzle";
import "simplebar";

import { Pages } from "./types";

import ParamUtils from "./utils/param-utils";
import Store from "./store/store";

import { LoginHandler, RegistrationHandler, ResetPasswordHandler } from './authentication/event-handlers'

import Navigation from "./menu/navigation";

const PUBLIC_PAGES = [
  Pages.LOGIN.path,
  Pages.REGISTER.path,
  Pages.REQUEST_PASSWORD_RESET.path,
  Pages.RESET_PASSWORD.path
]

if(ParamUtils.EXISTS('logout')) {
  localStorage.clear()

  if(window.location.pathname !== Pages.LOGIN.path) {
    window.location.pathname = '/login.html'
  }
}

if(! Store.IS_AUTHENTICATED() && ! PUBLIC_PAGES.includes(window.location.pathname)) {
  console.log(`Redirecting to login, since '${window.location.pathname}' is not in ${PUBLIC_PAGES}`)
  window.location.pathname = '/login.html'
}

switch (window.location.pathname) {
  case Pages.LOGIN.path: {
    const is_successfully_registered = ParamUtils.EXISTS('registrationSuccess')
    const has_successfully_reset_password = ParamUtils.EXISTS('passwordResetSuccess')
    LoginHandler.ON_PAGE_LOAD(is_successfully_registered, has_successfully_reset_password);
    break;
  }
  case Pages.REGISTER.path: {
    RegistrationHandler.ON_PAGE_LOAD();
    break;
  }
  case Pages.REQUEST_PASSWORD_RESET.path: {
    ResetPasswordHandler.ON_REQUEST_PAGE_LOAD();
    break;
  }
  case Pages.RESET_PASSWORD.path: {
    const token = ParamUtils.GET_STRING("token")

    if(token === '') {
      window.location.pathname = '/login.html'
    }

    ResetPasswordHandler.ON_RESET_PAGE_LOAD(token);
    break;
  }
  default:
    break;   
}

if(Store.IS_AUTHENTICATED()) {

  if((Store.ACTIVE_LEAGUE() === null) && ! [Pages.LANDING.path, Pages.CREATE_LEAGUE.path].includes(window.location.pathname) ) {
    console.log(`Redirecting to landing page, since active_league is not in ${Store.ACTIVE_LEAGUE()}`)
    window.location.pathname = Pages.LANDING.path;
  }
  
  Navigation.ON_PAGE_LOAD()
}
import { Report } from "../../../types";
import { ReportActions } from "../../actions";
import CardComponent from "../../components/card/card-component";
import EventUtils from "../../utils/event-utils";
import LoaderUtils from "../../utils/loader-utils";
import ReportDetailsModal, { ReportDetailPanel } from "./report-details-modal";

class SubmitAdditionalInfoModal extends ReportDetailsModal {

  id = 'submit-additional-info'
  title = 'Submit Additional Info'
  origin_card?: CardComponent<any>

  constructor(report: Report, origin_card?: CardComponent<any>) {
      super(report, [ ReportDetailPanel.ADDITIONAL_INFO ])
      this.origin_card = origin_card;
  }

  hasExtraPanels(): boolean {
    return true;
  }

  getExtraPanels(report: Report): string {
    return `
      ${ super.getExtraPanels(report) }

      <div id="${this.id}" class="ofr-modal modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="report-details-modal">${this.title}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">                
          <div class="form-group">
            <label for="description">Description</label>
            <textarea class="form-control" id="description" rows="3"></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button id="submit-additional-info" type="button" class="btn btn-primary btn-pill">
            <div style="display:none; height: 20px;" class="loader sk-three-bounce">
              <div style="background-color:white;" class="bounce1"></div>
              <div style="background-color:white;" class="bounce2"></div>
              <div style="background-color:white;" class="bounce3"></div>
            </div>
            <span class="loaded-content">Submit</span>
          </button>
        </div>
      </div>
    `
  }

  getModalActions(report: Report): void {
    EventUtils.REMOVE_ALL_EVENTS(`#${this.id} button#submit-additional-info`)
    
    EventUtils.REGISTER_CLICK_EVENT(
      `#${this.id} button#submit-additional-info`,
      () => {
        const description = <string> $(`#${this.id} #description`).val()

        ReportActions.SUBMIT_ADDITIONAL_INFO(
          {
            report_id: report.id,
            description: description
          },
          () => { LoaderUtils.LOADING(`#${this.id} button#submit-additional-info`) },
          () => {
             $(`#${this.id}`).modal('hide') 
             if(this.origin_card) this.origin_card.reload()
          },
          () => { 
            LoaderUtils.LOADED(`#${this.id} button#submit-additional-info`, 'Submit')
          }
        )
      }
  )
  }
}

export default SubmitAdditionalInfoModal
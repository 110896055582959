
import { Division, Message, OnlineRacingLeague } from "../../types"
import { UserActions } from "../actions"
import WordUtils from "../../utils/word-utils"
import TimeUtils from "../utils/time-utils"
import EventUtils from "../utils/event-utils"

const ON_PAGE_LOAD = (active_league: OnlineRacingLeague, active_division: Division, active_message_id: number = null): void => {
  UserActions.GET_MESSAGES(
    () => { },
    (messages) => {
      LOAD_INBOX(active_message_id, messages.filter(message => message.league.id === active_league.id))
    }
  )
}

const LOAD_INBOX = (active_message_id: number, messages: Array<Message>) => {
  const active_message = active_message_id === null
    ? messages[0]
    : messages.find(message => message.id === active_message_id)

  UserActions.MARK_MESSAGE_READ(active_message.id);
  active_message.read = true;
    
  const page_content = `
    <div class="col-md-12">
      <div class="card card-default mb-0">
        <div class="row bg-white no-gutters chat">
          <div class="col-lg-5 col-xl-4">
            <!-- Chat Left Side -->
            <div class="chat-left-side">
              <form class="chat-search">
                <input type="text" class="form-control" placeholder="Search...">
              </form>
              <ul class="list-unstyled border-top mb-0" id="chat-left-content" data-simplebar>
                ${ getMessagesContent(messages, active_message) }
              </ul>
            </div>
          </div>
          <div class="col-lg-7 col-xl-8">
            <!-- Chats -->
            <div class="chat-right-side">
              ${ getMessageContent(active_message) }
            </div>
          </div>
        </div>
      </div>
    </div>
  `

  $('#index-first-row').html(page_content)

  EventUtils.REGISTER_CLICK_EVENT(`#chat-left-content li a`, (event) => {
    const clicked_message = messages.find(message => message.id === parseInt(event.currentTarget.id));
    
    messages.forEach(message => $(`#list-item-${message.id}`).removeAttr('style'));

    $(`#list-item-${clicked_message.id}`).attr('style', `border-left: 3px ${clicked_message.read ? '#8a909d' : '#FF1801'} solid;`)

    SET_MESSAGE_CONTENT(clicked_message)
  })
}

const SET_MESSAGE_CONTENT = (message: Message): void => {
  const content = getMessageContent(message);

  $(`.chat-right-side`).html(content)
}

const getMessageContent = (message: Message): string => {
  const message_parsed_date = TimeUtils.PARSE_EPOCH(message.date);
  const active_message_content = message.message.replace(/\n/g, '<br/>')

  return `
    <div style="height: 91px;" class="media media-chat align-items-center mb-0 media-chat-header" href="#">
      <i class="mdi mdi-message text-secondary mr-3"></i>
      <div class="media-body w-100">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="heading-title mb-0">${WordUtils.FIRST_UPPER_CASE(message.subject)}</h3>
        </div>
      </div>
    </div>

    <div class="chat-right-content" id="chat-right-content" data-simplebar>
      <div class="media media-chat media-left">
        <div class="d-flex rounded-circle align-items-center justify-content-center mr-3 media-icon iconbox-45 bg-primary text-white">
          <i class="mdi mdi-message"></i>
        </div>
        <div class="media-body">
          <p class="message">${active_message_content}</p>
          <div class="date-time">${message_parsed_date.day.number}-${message_parsed_date.month.number}-${message_parsed_date.year} ${message_parsed_date.hour}:${message_parsed_date.minute}</div>
        </div>
      </div>
    </div>
  `
}

const getMessagesContent = (messages: Array<Message>, active_message: Message): string => {
  let messages_content = ''

  messages.forEach(message => {
    const parsed = TimeUtils.PARSE_EPOCH(message.date);

    messages_content += `
      <li id="list-item-${message.id}" ${active_message.id === message.id ? `style="border-left: 3px ${message.read ? '#8a909d' : '#FF1801'} solid;"` : ''}>
        <a id="${message.id}" class="media media-message">
          <div class="position-relative mr-3">
            <div
              class="d-flex rounded-circle align-items-center justify-content-center mr-3 media-icon iconbox-45 ${message.read ? 'bg-secondary': 'bg-primary'} text-white">
              <i class="mdi mdi-message"></i>
            </div>
          </div>
          <div class="media-body d-flex justify-content-between">
            <div class="message-contents">
              <h4 class="title">${WordUtils.FIRST_UPPER_CASE(message.subject)}</h4>
              <p class="last-msg">${message.message}</p>
            </div>

            <span class="date">${parsed.day.number} ${parsed.month.name}</span>

          </div>
        </a>
      </li>
    `
  })

  return messages_content;
}

export { ON_PAGE_LOAD }
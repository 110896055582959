const GET_INTEGER = (name: string): number => {
    const param = new URLSearchParams(window.location.search).get(name)
    return param === null ? -1 : parseInt(param)
}

const GET_STRING = (name: string): string => {
    const param = new URLSearchParams(window.location.search).get(name)
    return param === null ? "" : param
}

const EXISTS = (name: string): boolean => {
    return new URLSearchParams(window.location.search).has(name);
}

export default { GET_INTEGER, GET_STRING, EXISTS }
import ApiUtils from '../../utils/api-utils'
import Store from '../../store/store'
import { api } from '../../config'
import { Privilege, SubmitDivisionPrivilegeRequest, SubmitLeaguePrivilegeRequest, UserPrivileges } from '../../types'

const GET_ALL_DIVISION_PRIVILEGES = (loading_callback = () => {}, success_callback: (divisions: Array<Privilege>) => void = () => {}, error_callback = () => {}): void => {
  loading_callback()
  
  $.ajax({
    type: "GET",
    url: `${api}/division/privilege?token=${Store.ACCESS_TOKEN()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const GET_ALL_LEAGUE_PRIVILEGES = (loading_callback = () => {}, success_callback: (divisions: Array<Privilege>) => void = () => {}, error_callback = () => {}): void => {
  loading_callback()
  
  $.ajax({
    type: "GET",
    url: `${api}/league/privilege?token=${Store.ACCESS_TOKEN()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const GET_DIVISION_USERS_PRIVILEGES = (division_id: number, loading_callback = () => {}, success_callback: (divisions: Array<UserPrivileges>) => void = () => {}, error_callback = () => {}): void => {
  loading_callback()
  
  $.ajax({
    type: "GET",
    url: `${api}/division/users/privileges?division_id=${division_id}&token=${Store.ACCESS_TOKEN()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const GET_LEAGUE_USERS_PRIVILEGES = (league_id: number, loading_callback = () => {}, success_callback: (privileges: Array<UserPrivileges>) => void = () => {}, error_callback = () => {}): void => {
  loading_callback()
  
  $.ajax({
    type: "GET",
    url: `${api}/league/users/privileges?league_id=${league_id}&token=${Store.ACCESS_TOKEN()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const DIVISION_UPDATE = (request: SubmitDivisionPrivilegeRequest, loading_callback = () => {}, success_callback = () => {}, error_callback = () => {}): void => {
  loading_callback();

  $.ajax({
    type: "POST",
    url: `${api}/division/privileges`,
    data: JSON.stringify({
      token: Store.ACCESS_TOKEN(),
      ...request
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const LEAGUE_UPDATE = (request: SubmitLeaguePrivilegeRequest, loading_callback = () => {}, success_callback = () => {}, error_callback = () => {}): void => {
  loading_callback();

  $.ajax({
    type: "POST",
    url: `${api}/league/privileges`,
    data: JSON.stringify({
      token: Store.ACCESS_TOKEN(),
      ...request
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

export { GET_ALL_LEAGUE_PRIVILEGES, GET_ALL_DIVISION_PRIVILEGES, GET_LEAGUE_USERS_PRIVILEGES, GET_DIVISION_USERS_PRIVILEGES, LEAGUE_UPDATE, DIVISION_UPDATE }
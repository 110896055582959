import { Division, Report, User } from "../../../types";
import Modal from '../modal'
import YoutubeUtils from "../../../utils/youtube-utils";
import HtmlUtils from "../../../utils/html-utils";

abstract class ReportModal extends Modal<Report> {

  division: Division

  constructor(id: string, title: string, report: Report) {
    super(id, title, report)
  }

  getTemplate(report: Report): string {
    const has_extra_panels = this.hasExtraPanels(report);
    const extra_panels = this.getExtraPanels(report);

    return `
      <div class="modal fade" id="${this.id}" tabindex="-1" role="dialog" aria-labelledby="report-details-modals" aria-hidden="true">
        <div class="modal-dialog ${has_extra_panels ? 'modal-lg' : '' }" role="document">
          <div class="row">
            <div class="${has_extra_panels ? 'col-lg-6' : 'col-lg-12' }">
              <div id="${this.id}" style="margin-right: 20px; ${has_extra_panels ? '' : 'width: 100%;' }" class="ofr-modal modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">${this.title}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <input id="report-details-id" type="hidden" />
      
                  <div class="form-group">
                    <label for="report-details-event">Event</label>
                    <select style="-webkit-appearance: none;" id="report-details-event" class="form-control" disabled>
                      <option>${report.track.name}</option>
                    </select>
                  </div>
      
                  <div class="form-group">
                    <label for="report-details-users">Drivers involved</label><br />
                    <span
                      class="select2 select2-container select2-container--default select2-container--below select2-container--focus"
                      dir="ltr" data-select2-id="1" style="width: 100%;">
                      <span class="selection">
                        <span style="border: 0px; padding: 0px;" class="select2-selection select2-selection--multiple"
                          role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="-1">
                          <ul id="report-details-users" class="select2-selection__rendered">
                            ${ this.getDriversInvolved(report.reported_users) }
                          </ul>
                        </span>
                      </span>
                      <span class="dropdown-wrapper" aria-hidden="true"></span>
                    </span>
                  </div>
      
                  <div class="form-group">
                    <label for="report-details-link">Video</label><br />
                    ${ YoutubeUtils.GET_PREVIEW(report.media_link) }
                  </div>
      
                  <div class="form-group">
                    <label for="report-details-description">Description</label>
                    <p style="font-size: 0.875rem;" id="report-details-description">
                      ${ HtmlUtils.SAFE(report.description) }
                    </p>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              ${ extra_panels }
            </div>
          </div>
  
          
  
        </div>
      </div>
    `
  }

  abstract hasExtraPanels(report: Report): boolean

  abstract getExtraPanels(report: Report): string

  private getDriversInvolved(users: Array<User>): string {
    let drivers_involved_content = ''
  
    users.forEach(user => {
      drivers_involved_content += `
        <li style="margin: 10px 10px 5px 0px;" class="select2-selection__choice" title="AlexP89tm" data-select2-id="88">
          ${user.username}
        </li>
      `
    })
  
    return drivers_involved_content;
  }
}

export default ReportModal
import { Division, Event, QualifyingResultEntry, ResultType, TyreCompound } from "../../../types";
import { TyreCompoundActions } from "../../actions";
import QualifyingResultCardComponent from "../../components/card/qualifying-result-card-component";
import EventUtils from "../../utils/event-utils";
import LoaderUtils from "../../utils/loader-utils";
import TimeUtils from "../../utils/time-utils";
import SubmitResultEntryModal from "./submit-result-entry-modal";

class SubmitQualifyingResultEntryModal extends SubmitResultEntryModal<QualifyingResultEntry> {
    
  event: Event
  origin_card: QualifyingResultCardComponent

  constructor(division: Division, event: Event, entry: QualifyingResultEntry, result_types: (loading_callback: () => void) => JQuery.jqXHR<any>, origin_card?: QualifyingResultCardComponent) {
    super(division, result_types, entry, origin_card)
    this.event = event;
  }

  getResultSpecifics(): string {
    return `
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group tyre-compounds">
            <label>Tire</label><br/>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="mdi mdi-alpha-s-circle"></i>
                </span>
              </div>
              <div class="sk-three-bounce select-loader loader" style="z-index: 99;">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
              </div>
              <select class="form-control loaded-content" name="tyre-compounds" required>
  
              </select>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group lap-time">
            <label>Lap Time</label><br/>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="mdi mdi-timetable"></i>
                </span>
              </div>
              <input autocomplete="off" type="text" placeholder="01:12.901" class="form-control" 
                ${ this.local_entry?.fastest_time ? `value="${TimeUtils.GET_FORMATTED_TIME(this.local_entry.fastest_time)}"` : '' }/>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group points">
            <label>Points</label><br/>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="mdi mdi-timetable"></i>
                </span>
              </div>
              <input autocomplete="off" type="text" placeholder="0" class="form-control" 
                ${ this.local_entry?.points ? `value="${this.local_entry.points}"` : '0' }/>
            </div>
          </div>
        </div>
      </div>
    `
  }

  onSubmit(): void {
    this.entry.finish_position = this.entry ? this.origin_card.items.indexOf(this.entry) + 1 : this.origin_card.items.length 
    this.entry.tyre_compound = this.local_entry.tyre_compound
    this.entry.fastest_time = this.local_entry.fastest_time
    this.entry.points = this.local_entry.points | 0
    this.entry.team = this.local_entry.team
    this.entry.vehicle = this.local_entry.vehicle
  }

  registerSpecificChangeListeners(): void {
    TyreCompoundActions.GET_ALL_TYRE_COMPOUNDS(
      this.division.game.id,
      () => LoaderUtils.LOADING(`#${this.id} .tyre-compounds`),
      (tyre_compound_response) => {
        const active_tyre_compound = this.getActiveItem(this.entry?.tyre_compound, tyre_compound_response)
        const tyre_compound_content = this.getTyreCompoundContent(tyre_compound_response, active_tyre_compound)
      
        this.local_entry.tyre_compound = active_tyre_compound;

        LoaderUtils.LOADED(`#${this.id} .tyre-compounds`, tyre_compound_content)

        EventUtils.REGISTER_CHANGE_EVENT(`#${this.id} .tyre-compounds select`, (selector, event) => {
          const active_tyre_compound = tyre_compound_response.find(compound => compound.id === parseInt(event.target.value))
          this.local_entry.tyre_compound = active_tyre_compound;
        })
      }
    )

    EventUtils.REGISTER_CHANGE_EVENT(`#${this.id} .lap-time input`, (selector, event) => {
      this.local_entry.fastest_time = TimeUtils.PARSE_FORMATTED_TIME(
        event.target.value
      )
    })

    EventUtils.REGISTER_CHANGE_EVENT(`#${this.id} .points input`, (selector, event) => {
      this.local_entry.points = parseInt(event.target.value)
    })
  }

  private getTyreCompoundContent(tyre_compounds: Array<TyreCompound>, active: TyreCompound) {
    let tyre_compound_content = ''

    tyre_compounds.forEach(tyre_compound => {
      tyre_compound_content += `<option ${active.id === tyre_compound.id ? 'selected' : ''} value="${tyre_compound.id}">${tyre_compound.code} (${tyre_compound.name})</option>`
    })

    return tyre_compound_content;
  }
}

export default SubmitQualifyingResultEntryModal
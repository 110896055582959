import ApiUtils from '../../utils/api-utils'
import Store from '../../store/store'
import { api } from '../../config'
import { Message, User } from '../../types'

const GET_USER = (loading_callback = () => {}, success_callback: (response: User) => void = () => {}, error_callback = () => {}): void => {
  loading_callback()

  $.ajax({
    type: "GET",
    url: `${api}/user?token=${Store.ACCESS_TOKEN()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback),
  });
}

const GET_ALL_USERS = (loading_callback = () => {}, success_callback: (response: Array<User>) => void = () => {}, error_callback = () => {}) => {
  loading_callback()

  $.ajax({
    type: "GET",
    url: `${api}/users?token=${Store.ACCESS_TOKEN()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response.users, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback),
  });
}

const GET_LEAGUE_USERS = (league_id: number, loading_callback = () => {}, success_callback: (response: Array<User>) => void = () => {}, error_callback = () => {}): void => {
  loading_callback();

  $.ajax({
    type: "GET",
    url: `${api}/league/users?league_id=${league_id}&token=${Store.ACCESS_TOKEN()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const GET_DIVISION_USERS = (division_id: number, loading_callback = () => {}, success_callback: (response: Array<User>) => void = () => {}, error_callback = () => {}):  JQuery.jqXHR<any> => {
  loading_callback();

  return $.ajax({
    type: "GET",
    url: `${api}/division/users?division_id=${division_id}&token=${Store.ACCESS_TOKEN()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const GET_MESSAGES = (loading_callback = () => {}, success_callback: (response: Array<Message>) => void = () => {}, error_callback = () => {}):  void => {
  loading_callback();

  $.ajax({
    type: "GET",
    url: `${api}/user/messages?token=${Store.ACCESS_TOKEN()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const MARK_MESSAGE_READ = (message_id: number): void => {
  $.ajax({
    type: "POST",
    url: `${api}/user/message/read`,
    data: JSON.stringify({
      message_id: message_id,
      token: Store.ACCESS_TOKEN()
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, () => { }),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, () => {})
  });
}

export { GET_USER, GET_ALL_USERS, GET_LEAGUE_USERS, GET_DIVISION_USERS, GET_MESSAGES, MARK_MESSAGE_READ }
import Store from "../../../store/store";
import { Division, Event } from "../../../types"
import AlertUtils from "../../../utils/alert-utils";
import WordUtils from "../../../utils/word-utils";
import { EventActions } from "../../actions";
import EditEventModal from "../../modals/event/edit-event-modal";
import DateUtils from "../../utils/date-utils";
import TimeUtils from "../../utils/time-utils";
import ListItemComponent, { ListCardAction } from "../list/list-item-component";

export type EventCardActions = {
  EDIT: ListCardAction
  VIEW: ListCardAction
  POSITIVE_SIGN_UP: ListCardAction
  NEGATIVE_SIGN_UP: ListCardAction
  ENTERED_SIGN_UP: ListCardAction
  CANCELLED_SIGN_UP: ListCardAction
  REFRESH_SIGN_UP: ListCardAction
}
class EventCardComponent extends ListItemComponent<Event> {

  static is_refreshed = false;

  constructor(container_selector: string, event: Event, division: Division, card_actions: Array<ListCardAction>, refresh_list_function: () => void, refresh_item_function: () => void) {
    super(container_selector, event, division, card_actions, refresh_list_function, refresh_item_function);
  }

  getCard(event: Event): string {
    let upcoming_in = ''

    const remaining = DateUtils.REMAINING(event.date);

    if(DateUtils.HAS_PASSED(event.date)) {
      upcoming_in = `Finished.`
    } else {
      if(remaining.months > 1) {
        upcoming_in = `${remaining.months} month${remaining.months > 1 ? 's' : ''} and ${remaining.days} day${remaining.days !== 1 ? 's' : ''}.`
      } else if(remaining.days > 0 || remaining.months === 1) {
        if(remaining.months === 1) {
          upcoming_in = `${remaining.months} month and ${remaining.days} day${remaining.days !== 1 ? 's' : ''}.`
        } else {
          upcoming_in = `${remaining.days} day${remaining.days > 1 ? 's' : ''} and ${remaining.hours} hour${remaining.hours > 1 ? 's' : ''}.`
        }
      } else if(remaining.hours > 0 || remaining.days === 1) {
          upcoming_in = `${remaining.hours} hour${remaining.hours > 1 ? 's' : ''} and ${remaining.minutes} minute${remaining.minutes > 1 ? 's' : ''}.`
      } else if(remaining.minutes > 0 || remaining.hours == 1) {
          upcoming_in = `${remaining.minutes} minute${remaining.minutes > 1 ? 's' : ''}.`
      }
    }

    const parsed_event_date = TimeUtils.PARSE_EPOCH(event.date)
    
    return `
      <div id="${this.selector}" class="col-md-6 col-lg-4 col-xl-4 col-xxl-3">
        <div class="course">
          <div class="course-preview">
            <h6>Date</h6>
            <h3>${parsed_event_date.day.number}${this.nth(parsed_event_date.day.number)} ${parsed_event_date.month.name}</h3>
            <a href="#"><i class="mdi mdi-clock-outline"></i> ${DateUtils.HAS_PASSED(event.date) ? '' : 'Starts in '}${upcoming_in}</a>
          </div>
          <div class="course-info">
            <div class="row">
              <div class="col-md-6">
                <h6>Event</h6>
              </div>
              <div class="progress-container col-md-6">
                <div class="progress"></div>
                <span class="progress-text">
                  ${event.event_signups ? event.event_signups.filter(signup => signup.response).length : 0}/30 Signed up
                </span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <h3>${WordUtils.FIRST_UPPER_CASE(event.track.name)}</h3>
                ${ this.getActionsContent() }
              </div>
            </div>
          </div>
        </div>
      </div>
    `
  }

  onPostRender(event: Event): void {
    const entered_percentage = ((event.event_signups ? event.event_signups.filter(signup => signup.response).length : 0) / 30) * 100;
    const cancelled_percentage = ((event.event_signups ? event.event_signups.filter(signup => ! signup.response).length : 0) / 30) * 100;

    $(`#${this.selector}`).append(`<style>#${this.selector} .progress:after{ width: calc(${Math.round(entered_percentage)}% - 15px); }</style>`);
    $(`#${this.selector}`).append(`<style>#${this.selector} .progress:before{ width: calc(${Math.round(cancelled_percentage)}% - 15px); }</style>`);
  }

  onCardAction(action: ListCardAction, event: Event): void {
    console.log(action)

    if(action === EventListItemActions.EDIT) {
      new EditEventModal(this.division, event)
        .onClose(() => { this.refresh_item_function() })
        .render()
    }

    if(action === EventListItemActions.VIEW) {
      new EditEventModal(this.division, event, true)
        .onClose(() => { })
        .render()
    }

    if(action === EventListItemActions.POSITIVE_SIGN_UP) {
      EventActions.SUBMIT_SIGN_UP({
        accepted: true,
        event_id: event.id,
        user_id: Store.USER_ID()
      },
      () => {

      },
      () => {
        AlertUtils.NOTIFY('Succesfully submitted sign-up!', 'Success!')
        EventCardComponent.is_refreshed = false
        this.refresh_item_function()
      })
    }

    if(action === EventListItemActions.NEGATIVE_SIGN_UP) {
      EventActions.SUBMIT_SIGN_UP({
        accepted: false,
        event_id: event.id,
        user_id: Store.USER_ID()
      },
      () => {
      
      },
      () => {
        AlertUtils.NOTIFY('Succesfully submitted sign-up!', 'Success!')
        EventCardComponent.is_refreshed = false
        this.refresh_item_function()
      })
    }

    if(action === EventListItemActions.REFRESH_SIGN_UP) {
      EventCardComponent.is_refreshed = true
      this.refresh_item_function()
    }
  }

  private nth(d: number) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:  return "st";
      case 2:  return "nd";
      case 3:  return "rd";
      default: return "th";
    }
  }
}

export const EventListItemActions: EventCardActions = {
  EDIT: {
    name: "Edit",
    style: null,
    class: null,
    icon_class: null,
    show_fn: () => true
  },
  VIEW: {
    name: "View",
    style: null,
    class: 'view-btn',
    icon_class: null,
    show_fn: () => true
  },
  POSITIVE_SIGN_UP: {
    name: null,
    style: null,
    class: 'bg-success',
    icon_class: 'mdi mdi-check',
    show_fn: (event: Event): boolean => {
      const sign_up = event.event_signups?.find(signup => signup.user.id === Store.USER_ID())
      const remaining = DateUtils.REMAINING(event.date)
      return ( 
                (! sign_up || EventCardComponent.is_refreshed) 
                  && ! DateUtils.HAS_PASSED(event.date) && remaining.days < 7 && remaining.months < 1 && remaining.years < 1
              )
                
    }
  },
  NEGATIVE_SIGN_UP: {
    name: null,
    style: "margin-right: 60px;",
    class: 'bg-danger',
    icon_class: 'mdi mdi-close',
    show_fn: (event: Event): boolean => {
      const sign_up = event.event_signups?.find(signup => signup.user.id === Store.USER_ID())
      const remaining = DateUtils.REMAINING(event.date)
      return ( 
                (! sign_up || EventCardComponent.is_refreshed) 
                  && ! DateUtils.HAS_PASSED(event.date) && remaining.days < 7 && remaining.months < 1 && remaining.years < 1
              )
    }
  },
  CANCELLED_SIGN_UP: {
    name: "Absent",
    style: "margin-right: 60px;",
    class: 'bg-red disabled',
    icon_class: 'mdi mdi-close mr-1',
    show_fn: (event: Event): boolean => {
      const sign_up = event.event_signups?.find(signup => signup.user.id === Store.USER_ID())
      return sign_up && sign_up.response === false && ! EventCardComponent.is_refreshed
    }
  },
  ENTERED_SIGN_UP: {
    name: "Present",
    style: "margin-right: 60px;",
    class: 'bg-green disabled',
    icon_class: 'mdi mdi-check mr-1',
    show_fn: (event: Event): boolean => {
      const sign_up = event.event_signups?.find(signup => signup.user.id === Store.USER_ID())
      return sign_up && sign_up.response === true && ! EventCardComponent.is_refreshed
    }
  },
  REFRESH_SIGN_UP: {
    name: null,
    style: null,
    class: 'bg-warning',
    icon_class: 'mdi mdi-refresh',
    show_fn: (event: Event): boolean => {
      return event.event_signups?.find(signup => signup.user.id === Store.USER_ID()) !== undefined
              && ! EventCardComponent.is_refreshed
    }
  }
}

export default EventCardComponent
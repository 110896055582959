import ApiUtils from '../../utils/api-utils'
import Store from '../../store/store'
import { api } from '../../config'
import { PenaltyDefinition, Report, ReportPenalty, RetrievalResponse, ServePenaltyRequest, SubmitPenaltyRequest } from '../../types';

const GET_POSSIBLE_PENALTIES = (loading_callback = () => {}, success_callback: (response: Array<PenaltyDefinition>) => void = () => {}, error_callback = () => {}): void => {
  loading_callback();

  $.ajax({
    type: "GET",
    url: `${api}/reports_penalties/penalties?token=${Store.ACCESS_TOKEN()}&division_id=${Store.ACTIVE_DIVISION()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const GET_MY_UNSERVED_PENALTIES = (division_id: number, success_callback: (response: Array<ReportPenalty>) => void = () => {}, error_callback = () => {}): void => {
  $.ajax({
    type: "GET",
    url: `${api}/penalties/unserved/driver?token=${Store.ACCESS_TOKEN()}&division_id=${Store.ACTIVE_DIVISION()}`,
    success: (response: RetrievalResponse<ReportPenalty>) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const GET_MY_SERVED_PENALTIES = (division_id: number, success_callback: (response: Array<ReportPenalty>) => void = () => {}, error_callback = () => {}): void => {
  $.ajax({
    type: "GET",
    url: `${api}/penalties/served/driver?token=${Store.ACCESS_TOKEN()}&division_id=${Store.ACTIVE_DIVISION()}`,
    success: (response: RetrievalResponse<ReportPenalty>) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const GET_UNSERVED_PENALTIES = (division_id: number, success_callback: (response: Array<Report>) => void = () => {}, error_callback = () => {}): void =>  {
  $.ajax({
    type: "GET",
    url: `${api}/reports_penalties/head_steward/unserved?token=${Store.ACCESS_TOKEN()}&division_id=${Store.ACTIVE_DIVISION()}`,
    success: (response: RetrievalResponse<ReportPenalty>) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const SUBMIT_PENALTY = (penalty: SubmitPenaltyRequest, loading_callback = () => {}, success_callback = () => {}, error_callback = () => {}): void => {
  loading_callback();

  $.ajax({
    type: "POST",
    url: `${api}/reports_penalties/penalty`,
    data: JSON.stringify({
      token: Store.ACCESS_TOKEN(),
      ...penalty
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const SUBMIT_APPEAL = (appeal: any, loading_callback = () => {}, success_callback = () => { }, error_callback = () => {}): void => {
  loading_callback()

  $.ajax({
    type: "POST",
    url: `${api}/penalties/penalty/appeal`,
    data: JSON.stringify({
      token: Store.ACCESS_TOKEN(),
      ...appeal
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const SERVE_PENALTY = (request: ServePenaltyRequest, loading_callback = () => {}, success_callback = () => { }, error_callback = () => {}): void => {
  loading_callback()

  $.ajax({
    type: "POST",
    url: `${api}/reports_penalties/serve_penalty`,
    data: JSON.stringify({
      token: Store.ACCESS_TOKEN(),
      ...request
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

export { GET_POSSIBLE_PENALTIES, GET_MY_UNSERVED_PENALTIES, GET_UNSERVED_PENALTIES, GET_MY_SERVED_PENALTIES, SUBMIT_PENALTY, SUBMIT_APPEAL, SERVE_PENALTY }
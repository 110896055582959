import { Duration, LocalDate } from '../../types';
import { PARSED_TIME } from './time-utils';


const PARSE_MS = (miliseconds: number): PARSED_TIME => {
  const date = new Date(miliseconds);

  const milliseconds_string = (date.getMilliseconds() < 100 && date.getMilliseconds() < 10) ? "00" + date.getMilliseconds() : (date.getMilliseconds() < 100 && date.getMilliseconds() > 10) ? "0" + date.getMilliseconds() : date.getMilliseconds();
  const seconds_string = (date.getSeconds() < 10) ? "0" + date.getSeconds() : date.getSeconds();
  const minutes_string = (date.getMinutes() < 10) ? "0" + date.getMinutes() : date.getMinutes();
  const hours_string = (date.getHours() < 10) ? "0" + date.getHours() : date.getHours();

  return {
    hours: hours_string.toString(),
    minutes: minutes_string.toString(),
    seconds: seconds_string.toString(),
    milliseconds: milliseconds_string.toString()
  };
}

const HAS_PASSED = (epoch_ms: number): boolean => {
  const now = new Date();
  const target_date = new Date(epoch_ms);

  return now.getTime() > target_date.getTime();
}

const REMAINING = (epoch_ms: number): Duration => {
  const now = new Date();
  const target_date = new Date(epoch_ms);

  const years_remaning = Math.abs(target_date.getFullYear() - now.getFullYear())

  const monthFurtherThanEvent = now.getMonth() < target_date.getMonth() 
  const offset = monthFurtherThanEvent ? 1 : 0

  const months_remaining = years_remaning > 0
    ? 12 - Math.abs(target_date.getMonth() - now.getMonth()) - offset
    : Math.abs(target_date.getMonth() - now.getMonth()) - offset

  const days_remaining = monthFurtherThanEvent
    ? GET_DAYS_IN_MONTH(now) - Math.abs(target_date.getDate() - now.getDate())
    : Math.abs(target_date.getDate() - now.getDate())

  const hours_remaining = days_remaining > 0 && target_date.getHours() < now.getHours()
    ? 24 - Math.abs(target_date.getHours() - now.getHours())
    : Math.abs(target_date.getHours() - now.getHours())

  const minutes_remaining = hours_remaining > 0 && target_date.getMinutes() < now.getMinutes()
    ? 60 - Math.abs(target_date.getMinutes() - now.getMinutes())
    : Math.abs(target_date.getMinutes() - now.getMinutes())

  return {
    years: years_remaning,
    months: months_remaining,
    days: days_remaining,
    hours: hours_remaining,
    minutes: minutes_remaining
  }
}

const GET_DAYS_IN_MONTH = (date: Date): number => {
  const month = date.getMonth();
  const year = date.getFullYear();

  return new Date(year, month + 1, 0).getDate();
}

export default {
  PARSE_MS, HAS_PASSED, REMAINING, GET_DAYS_IN_MONTH
}
import { Division, OnlineRacingLeague } from "../../types"
import { StandingActions } from "../actions"
import DriverStandingBannerComponent from "../components/banner/driver-standing-banner-component"
import DriverStandingComponent from "../components/driver-standing-component"
import TeamStandingComponent from "../components/team-standing-component"

const ON_PAGE_LOAD = (active_league: OnlineRacingLeague, active_division: Division): void => {

  new DriverStandingBannerComponent(
    '#index-first-row',
    active_division,
    StandingActions.GET_DRIVER_STANDINGS,
  ).render()

  $("#index-second-row").css('flex-direction', 'row-reverse')

  new DriverStandingComponent(
    '#index-second-row',
    active_division,
    StandingActions.GET_DRIVER_STANDINGS,
  ).render()

  new TeamStandingComponent(
    '#index-second-row',
    active_division,
    StandingActions.GET_TEAM_STANDINGS,
  ).render()
}

export { ON_PAGE_LOAD }
import { DivisionActions, LeagueActions } from "../dashboards/actions";
import { CreateLeagueHandler, DivisionsHandler, EventHandler, InboxHandler, IndexHandler, LandingsPageHandler, LeagueDetailsHandler, LeagueUserHandler, ProfileHandler, QualifyingResultHandler, RaceResultHandler, ResultsHandler, SignUpEventHandler, StandingsHandler, StewardingHandler, TeamHandler, TeamsRosterHandler, TimeTrialHandler, UserHandler, WelcomeHandler } from "../dashboards/event-handlers";
import HeaderHandler from "../header/event-handlers.ts/header-handler";
import Store from "../store/store";
import { Division, OnlineRacingLeague, Pages } from "../types"
import PermissionUtils from "../utils/permission-utils";
import MenuHandler from "./event-handlers/menu-handler";

const LEAGUE_ADMIN_PAGES = [Pages.LEAGUE_USERS.path, Pages.DIVISIONS.path]
const DIVISION_MANAGER_PAGES = [Pages.USERS.path, Pages.TEAMS.path, Pages.EVENTS.path, Pages.QUALI_RESULTS.path, Pages.RACE_RESULTS.path]
const DIVISION_MEMBER_PAGES = [Pages.REPORTING.path, Pages.TEAMS_ROSTER.path, Pages.EVENTS_SIGNUP.path, Pages.RESULTS.path, Pages.STANDINGS.path, Pages.STEWARDING.path]

let league_created_by_me: Array<OnlineRacingLeague> = []
let my_joined_leagues: Array<OnlineRacingLeague> = []

let active_league: OnlineRacingLeague = null;
let active_division: Division = null;

let divisions: Array<Division> = []

const ON_PAGE_LOAD = (): void => {
  Promise.all([
    LeagueActions.GET_CREATED_LEAGUES(),
    LeagueActions.GET_JOINED_LEAGUES()
  ]).then(responses => {
    console.log('Loaded leagues.')

    league_created_by_me = responses[0].values
    my_joined_leagues = responses[1].values
  
    if(window.location.pathname === Pages.LANDING.path) {
      LandingsPageHandler.ON_PAGE_LOAD(league_created_by_me, my_joined_leagues)
      return;
    }

    if(window.location.pathname === Pages.CREATE_LEAGUE.path) {
      CreateLeagueHandler.ON_PAGE_LOAD()
      return;
    }

    const active_league_id = Store.ACTIVE_LEAGUE()
    active_league = league_created_by_me.concat(my_joined_leagues).find(league => league.id === active_league_id)

    const isLeagueOwner = active_league.owner.id === Store.USER_ID()
    const isLeagueAdministrator = active_league.administrators.find(adminstrator => adminstrator.id === Store.USER_ID()) !== undefined

    divisions = isLeagueOwner || isLeagueAdministrator ? DivisionActions.GET_DIVISIONS_SYNC() : DivisionActions.GET_MY_DIVISIONS_SYNC()

    const stored_active_division_id = Store.ACTIVE_DIVISION()

    active_division;

    if(stored_active_division_id == null) {
      active_division = divisions[0]
    } else {
      active_division = divisions.find(division => division.id === stored_active_division_id) === undefined
        ? divisions[0]
        : divisions.find(division => division.id === stored_active_division_id)
    }

    // Appareantly this league does not have any divisions yet, or the user has non assigned to it.
    if(active_division === undefined) {
      NAVIGATE(Pages.WELCOME.path)
    } else if(active_division.id !== stored_active_division_id) {
      Store.SET_ACTIVE_DIVISION(active_division.id)
    }

    HeaderHandler.ON_PAGE_LOAD(active_league)
    
    if(window.location.hash !== undefined && window.location.hash !== '') {
      NAVIGATE(window.location.hash)
    } else {
      NAVIGATE(Pages.REPORTING.path)
    }

    console.log('End navigation on load.')
  })
}

const NAVIGATE = (path: string): void => {

  if([Pages.CREATE_LEAGUE.path, Pages.LANDING.path].includes(path)) {
    if([Pages.CREATE_LEAGUE.path, Pages.LANDING.path].includes(window.location.pathname)) {
      return
    }

    window.location.pathname = path;
    return;
  }

  const isLeagueOwner = active_league.owner.id === Store.USER_ID()
  const isLeagueAdministrator = active_league.administrators.find(adminstrator => adminstrator.id === Store.USER_ID()) !== undefined
  const isDivisionManager = PermissionUtils.HAS_PERMISSION(9, true)
  const isDivisionMember = PermissionUtils.HAS_PERMISSION(21, false)

  if(! isLeagueOwner) {
    if(isDivisionMember && ! isLeagueAdministrator && ! isDivisionManager && ! DIVISION_MEMBER_PAGES.includes(path)) {
      NAVIGATE(Pages.REPORTING.path)
      return;
    }

    if(! isDivisionMember && isLeagueAdministrator && DIVISION_MEMBER_PAGES.includes(path)) {
      // Redirect to management page, as the user is not actually a racing member of this division
      NAVIGATE(Pages.LEAGUE_USERS.path)
      return;
    }

    if(! isDivisionMember && ! isLeagueAdministrator && ! DIVISION_MANAGER_PAGES.includes(path)) {
      NAVIGATE(Pages.USERS.path)
      return;
    }
  }

  MenuHandler.ON_PAGE_LOAD(active_league, divisions, active_division)

  window.location.hash = path;
  RESET_PAGE()

  switch(path) {
    case Pages.DEFAULT.path:
    case Pages.INDEX.path: {
      if(isDivisionMember) {
        NAVIGATE(Pages.REPORTING.path)
      } else {
        NAVIGATE(Pages.USERS.path)
      }
      
      break;
    }
    case Pages.WELCOME.path: {
      WelcomeHandler.ON_PAGE_LOAD(active_league)
      break;
    }
    case Pages.PROFILE.path: {
      ProfileHandler.ON_PAGE_LOAD(active_league, active_division)
      break;
    }
    case Pages.INBOX.path: {
      InboxHandler.ON_PAGE_LOAD(active_league, active_division)
      break;
    }
    case Pages.REPORTING.path: {
      IndexHandler.ON_PAGE_LOAD(active_league, active_division);
      break;
    }
    case Pages.TEAMS_ROSTER.path: {
      TeamsRosterHandler.ON_PAGE_LOAD(active_division);
      break;
    }
    case Pages.RESULTS.path: {
      ResultsHandler.ON_PAGE_LOAD(active_league, active_division);
      break;
    }
    case Pages.STANDINGS.path: {
      StandingsHandler.ON_PAGE_LOAD(active_league, active_division);
      break;
    }
    case Pages.LEAGUE_DETAILS.path: {
      LeagueDetailsHandler.ON_PAGE_LOAD(active_league);
      break;
    }
    case Pages.LEAGUE_USERS.path: {
      LeagueUserHandler.ON_PAGE_LOAD(active_league);
      break;
    }
    case Pages.DIVISIONS.path: {
      DivisionsHandler.ON_PAGE_LOAD(active_league, divisions);
      break;
    }
    case Pages.TIME_TRIAL.path: {
      TimeTrialHandler.ON_PAGE_LOAD();
      break;
    }
    case Pages.STEWARDING.path: {
      StewardingHandler.ON_PAGE_LOAD(active_league, active_division);
      break;
    }
    case Pages.USERS.path: {
      UserHandler.ON_PAGE_LOAD(active_league, active_division);
      break;
    }
    case Pages.EVENTS.path: {
      EventHandler.ON_PAGE_LOAD(active_division);
      break;
    }
    case Pages.EVENTS_SIGNUP.path: {
      SignUpEventHandler.ON_PAGE_LOAD(active_division);
      break;
    }
    case Pages.TEAMS.path: {
      TeamHandler.ON_PAGE_LOAD(active_division);
      break;
    }
    case Pages.RACE_RESULTS.path: {
      RaceResultHandler.ON_PAGE_LOAD(active_division);
      break;
    }
    case Pages.QUALI_RESULTS.path: {
      QualifyingResultHandler.ON_PAGE_LOAD(active_division);
      break;
    }
  }
}

const RESET_PAGE = () => {
  $(".content").html(
    `
    <div class="row">
      <div class="col-lg-6">
        <div class="row">
          <div class="col-lg-6 page-buttons">
           
          </div>
        </div>
      </div>
    </div>
    <div id="index-first-row" class="row"></div>
    <div id="index-second-row" class="row"></div>
    <div id="index-third-row" class="row"></div>
    `
  )
}

export default { ON_PAGE_LOAD, NAVIGATE }
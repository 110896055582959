import { Division, UniqueEntity } from "../../../types"
import PermissionUtils from "../../../utils/permission-utils"
import EventUtils from "../../utils/event-utils"
import LoaderUtils from "../../utils/loader-utils"
import { RowAction, CardAction } from "./card-types"

abstract class CardComponent<T extends UniqueEntity> {

  id: string
  title: string
  color: string
  selector: string
  container_selector: string

  content_function: (id: number, callback: (content: Array<T>) => void) => void
  alt_content_function: (id: number, callback: (content: Array<T>) => void) => void

  items: Array<T>

  switcher_values: Array<string>

  row_actions: Array<RowAction>
  alt_row_actions: Array<RowAction>

  card_actions: Array<CardAction>

  full_width: boolean
  scrollable: boolean

  constructor(container_selector: string, id: string, title: string, color: string, full_width = false, scrollable = true,
                content_function: (division_id: number, callback: (content: Array<T>) => void) => void = () => {}, 
                alt_content_function: (division_id: number, callback: (content: Array<T>) => void) => void = () => {},
                switcher_values: Array<string> = [], row_actions: Array<RowAction> = [], alt_row_actions: Array<RowAction> = [],
                card_actions: Array<CardAction> = []) {

      this.id = id;
      this.title = title;
      this.color = color;
      this.selector = `#${id}`
      this.container_selector = container_selector;

      this.content_function = content_function;
      this.alt_content_function = alt_content_function;

      this.switcher_values = switcher_values;
      this.row_actions = row_actions;
      this.alt_row_actions = alt_row_actions;   
      
      this.card_actions = card_actions;

      this.full_width = full_width;
      this.scrollable = scrollable;
  }

  render(): void {    
    this.load_card()
    this.load_content()
  }

  reload(): void {
    this.render()
  }

  onReload(items: Array<T>): void { }

  load_card(): void {
     // 1. Generate global card html and attach to container. 
    const card = this.getCard(this.id, this.title, this.color, this.switcher_values)
    const existing_element = $(`${this.container_selector} #${this.id}-card`);

    if(existing_element.length > 0) {
      existing_element.replaceWith(card)
    } else {
      $(`${this.container_selector}`).append(card)
    }

    // 2. Enable loading  
    this.setLoading()

    // 3. Register switcher change event
    EventUtils.REGISTER_CHANGE_EVENT(`${this.selector}-state-switcher`, (element) => {
      this.setLoading()
      const is_checked = $(element).is(':checked')

      if(is_checked) {
        this.content_function(this.getEntityId(), (items) =>  { 
          this.items = items
          this.getContent(this.selector, this.color, items, this.row_actions) 
        })
      } else {
        this.alt_content_function(this.getEntityId(), (items) => {
          this.items = items
          this.getContent(this.selector, 'secondary', items, this.alt_row_actions)
        })
      }
    })

    // 4. Register card action click event
    if(this.card_actions.length > 0) {
      this.card_actions.forEach((action, index) => {
        if(PermissionUtils.HAS_PERMISSION(action.service)) {
          EventUtils.REGISTER_CLICK_EVENT(`${this.selector}-card-action-${index}`, () => {
            this.onCardAction(action)
          })
        }
      })
    }
  }

  load_content(): void {
    this.content_function(this.getEntityId(), (items) => {
      this.items = items
      this.refresh()
      this.onReload(items)
    })
  }

  refresh(): void  {
    this.setLoading()
    this.getContent(this.selector, this.color, this.items, this.row_actions)
  }

  getEntityId(): number { return null; }

  getDivisions(): Array<Division> {
    return []
  }

  abstract getCardContent(items: Array<T>, color: string, row_actions: Array<RowAction>): string

  abstract setRowEvents(selector: string, item: T, row_actions: Array<RowAction>): void

  protected getRowActionContent(item: T, row_actions: Array<RowAction>): string {
    let row_action_content = ''
  
    row_actions
      .filter(action => PermissionUtils.HAS_PERMISSION(action.service))
      .forEach(action => {
        row_action_content += `
          <i data-id="${item.id}" class="mdi ${action.class}"></i> 
        `
      })
  
    return row_action_content;
  }

  protected getContent(selector: string, color: string, items: Array<T>, row_actions: Array<RowAction>): void {
    const card_content = this.getCardContent(items, color, row_actions)
    
    LoaderUtils.LOADED(selector, card_content)

    items.forEach(item => this.setRowEvents(selector, item, row_actions.filter(action => PermissionUtils.HAS_PERMISSION(action.service))))
  }

  private getCard(id_prefix: string, title: string, color: string, switcher_labels: Array<string>) {
    return `
      <div id="${id_prefix}-card" class="ofr-card ${this.full_width ? 'col-lg-12' : 'col-lg-6' }">
        <div class="card card-default">
          <div class="card-header justify-content-between align-items-center card-header-border-bottom"
            style="padding-left: 2.5em; padding-right: 2.5em">
            <h2 style="flex: 4 0 0;">${title}</h2>
            <div>
              ${ switcher_labels.length == 2 
                ? `<label class="switch switch-text switch-${color} switch-pill form-control-label mr-2 mb-0">
                    <input id="${id_prefix}-state-switcher" type="checkbox" class="switch-input form-check-input"
                      value="on" checked="">
                    <span class="switch-label ofr" data-on="${switcher_labels[0]}" data-off="${switcher_labels[1]}"></span>
                    <span class="switch-handle ofr"></span>
                  </label>`
                : ''
              }
              ${ this.getDivisions().length > 0
                ? `<div class="division-filter-dropdown dropdown d-inline-block mb-0">
                    <button
                      style="z-index: 1001; border-radius: 100px; position: relative; min-width: 10rem;"
                      class="btn btn-secondary dropdown-toggle" type="button" id=${id_prefix}-divisions-dropdown-btn"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
                      All Divisions
                    </button>
                    <div
                      style="border-radius: 20px; padding-top: 40px; margin-top: -35px; max-height: 225px; overflow: scroll;"
                      id="${id_prefix}-divisions-dropdown" class="dropdown-menu"
                      aria-labelledby="divisions-dropdown-btn">
                      <div id="${id_prefix}-divisions-dropdown-loader" class="sk-three-bounce"
                        style="margin: 20px auto; display: none;">
                        <div class="bounce1"></div>
                        <div class="bounce2"></div>
                        <div class="bounce3"></div>
                      </div>
      
                      <div class="api-content">

                      </div>
                    </div>
                  </div>`
                : ''
              }
              ${ this.card_actions.length > 0
                ? this.getCardActions(id_prefix, color, this.card_actions)
                : ''
              }
            </div>
          </div>
          <div id="${id_prefix}" class="card-body slim-scroll"
            style=" ${this.scrollable ? 'max-height:350px; overflow: scroll;' : ''} padding: 0 2.5em;">  
            <div class="card-content loaded-content">
              ${ this.getLoadingRows() }
            </div>
          </div>
        </div>
      </div>
    `
  }

  setLoading(): void {
    $(`${this.selector} .loaded-content`).html(
      this.getLoadingRows()
    )
  }

  getLoadingRows(): string {
    return `
      <div class="media py-3 align-items-center justify-content-between">
        <div class="is-loading d-flex rounded-circle align-items-center justify-content-center mr-3 media-icon iconbox-45 text-white">
        </div>
        <div style="overflow: hidden; white-space: nowrap;" class="media-body pr-3 ">
          <a class="is-loading mt-0 mb-1 font-size-15 text-dark" href="#"></a>
          <p class="is-loading" style="text-overflow: ellipsis; overflow: hidden;"></p>
        </div>
      </div>
      <div class="media py-3 align-items-center justify-content-between">
        <div class="is-loading d-flex rounded-circle align-items-center justify-content-center mr-3 media-icon iconbox-45 text-white">
        </div>
        <div style="overflow: hidden; white-space: nowrap;" class="media-body pr-3 ">
          <a class="is-loading mt-0 mb-1 font-size-15 text-dark" href="#"></a>
          <p class="is-loading" style="text-overflow: ellipsis; overflow: hidden;"></p>
        </div>
      </div>
      <div class="media py-3 align-items-center justify-content-between">
        <div class="is-loading d-flex rounded-circle align-items-center justify-content-center mr-3 media-icon iconbox-45 text-white">
        </div>
        <div style="overflow: hidden; white-space: nowrap;" class="media-body pr-3 ">
          <a class="is-loading mt-0 mb-1 font-size-15 text-dark" href="#"></a>
          <p class="is-loading" style="text-overflow: ellipsis; overflow: hidden;"></p>
        </div>
      </div>
    `
  }

  onCardAction(action: CardAction): void { }

  getCardActions(id_prefix: string, color: string, actions: Array<CardAction>): string {
    let actions_content = ''

    actions.forEach((action, index) => {
      actions_content += `
        <button id="${id_prefix}-card-action-${index}" type="button" class="btn btn-${color} btn-pill rounded-circle ml-2 ${PermissionUtils.HAS_PERMISSION(action.service) ? '"' : 'disabled" disabled'}>
          <i class="mdi ${action.icon} font-size-20"></i>
        </button>
      `
    })
    
    return actions_content;
  }
}

export default CardComponent
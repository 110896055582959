import { TimeTrialEvent, TimeTrialEventEntry } from "../../../types";
import TimeTrialEventEntryDetailsModal from "../../modals/time-trial/time-trial-event-details-modal";
import EventUtils from "../../utils/event-utils";
import TimeUtils from "../../utils/time-utils";

import CardComponent from "./card-component";

class TimeTrialCardComponent extends CardComponent<TimeTrialEventEntry> {

  event: TimeTrialEvent

  constructor(container_selector: string, id: string, title: string, 
    content_function: (event_id: number, callback: (content: Array<TimeTrialEventEntry>) => void) => void = () => {}, 
    alt_content_function: (event_id: number, callback: (content: Array<TimeTrialEventEntry>) => void) => void = () => {}, event: TimeTrialEvent) {
    super(container_selector, id, title, 'success', false, true, content_function, alt_content_function, ["VERIFIED", "ALL"], [], [])
    this.event = event;
  }

  getEntityId(): number {
    return this.event.id;
  }

  getCardContent(entries: Array<TimeTrialEventEntry>): string {
    let card_content = ''

    entries.forEach((entry, index) => {
      card_content += `
        <div data-id="${entry.id}" class="time-trial-result-entry media py-3 align-items-center justify-content-between" data-toggle="modal" data-target="#time-trial-entry-modal">
          <div class="driver-position d-flex rounded-circle align-items-center justify-content-center media-icon iconbox-45 bg-primary text-white">
            ${index + 1}
          </div>
          <div class="driver-details media-body pr-3 ">
            <a class="mt-0 mb-1 font-size-15 text-dark" href="#">${entry.user.username}</a>
            <p style="text-overflow: ellipsis; overflow: hidden;">${this.event.vehicle.name}</p>
          </div>
          <div style="border-radius: 30px;" class="d-flex align-items-center justify-content-center iconbox-45 mr-3  ${entry.verified ? `bg-success` : `bg-warning` } text-white">
            ${entry.verified 
              ? `<i class="mdi mdi-shield-check"></i>` 
              : `<i class="mdi mdi-help"></i>`}
          </div>
          <div class="specific-detail d-flex align-items-center justify-content-center mr-2 bg-secondary text-white">
            ${ TimeUtils.GET_FORMATTED_TIME(entry.time) }
            <br/>
            <span style="font-size: 10px; margin-top: 10px;" class="sub-title">time</span>
          </div>
        </div>
      `
    })

    if(entries.length === 0) {
      card_content = `
        <div style="text-align: center;">
            NO ENTRIES FOUND
        </div>
      `
    }

    return card_content;
  }

  setRowEvents(selector: string, entry: TimeTrialEventEntry): void {
    EventUtils.REGISTER_CLICK_EVENT(`${selector} .time-trial-result-entry[data-id="${entry.id}"]`, () => new TimeTrialEventEntryDetailsModal(this.event, entry).render())  
  }
}

export default TimeTrialCardComponent
import { Division, RosterEntry, Team, User, Vehicle } from "../../../types";
import { DivisionActions, RosterActions, TeamActions, UserActions, VehicleActions } from "../../actions";

import AlertUtils from "../../../utils/alert-utils";
import EventUtils from "../../utils/event-utils";
import LoaderUtils from "../../utils/loader-utils";

import Modal from "../modal";
import CardComponent from "../../components/card/card-component";

const id = 'submit-roster-entry-modal'
const title = 'Submit Roster Entry'

class SubmitTeamEntryModal extends Modal<void> {

  division: Division
  team: Team
  users: Array<User>
  origin_card?: CardComponent<User>

  constructor(division: Division, team: Team, users: Array<User>, origin_card?: CardComponent<User>) {
    super(id, title, undefined)
    this.division = division;
    this.team = team;
    this.users = users;
    this.origin_card = origin_card;
  }

  getTemplate(): string {
    return `
      <div class="modal fade" id="${this.id}" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">${this.title}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">         
              <div class="form-group users">
                <label>User</label><br/>
                <div class="sk-three-bounce select-loader loader" style="z-index: 99;">
                  <div class="bounce1"></div>
                  <div class="bounce2"></div>
                  <div class="bounce3"></div>
                </div>
              
                <select style="width: 100%;" class="form-control loaded-content" name="users" required>
                
                </select>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">Close</button>
              <button id="submit-team-entry" type="button" class="btn btn-primary btn-pill">
                <div style="display:none; height: 20px;" class="sk-three-bounce loader">
                  <div style="background-color:white;" class="bounce1"></div>
                  <div style="background-color:white;" class="bounce2"></div>
                  <div style="background-color:white;" class="bounce3"></div>
                </div>
                <span class="loaded-content">Submit</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    `
  }

  getModalActions(): void  {
    this.getFormData();

    EventUtils.REMOVE_ALL_EVENTS(`#${this.id} button#submit-team-entry`)
    EventUtils.REGISTER_CLICK_EVENT(`#${this.id} button#submit-team-entry`, () => {
      const user_id = <string> $(`#${this.id} .users select`).val()
      const username = <string> $(`#${this.id} .users select option:selected`).text()
      
      const request = {
       user_id: parseInt(user_id),
       division_team_id: this.team.id
      }

      TeamActions.ADD_USER(
        request,
        () => { LoaderUtils.LOADING(`#${this.id} button#submit-team-entry`) },
        () => {
          this.close()
          LoaderUtils.LOADED(`#${this.id} button#submit-team-entry`, 'Submit')
          AlertUtils.NOTIFY(`${username} was succesfully added to ${this.team.name}.`, 'Success!')

          if(this.origin_card) this.origin_card.reload()
        },
        () => { LoaderUtils.LOADED(`#${this.id} button#submit-team-entry`, 'Submit') }
      )
    })
  }

  private getFormData() {
    UserActions.GET_DIVISION_USERS(
      this.division.id,
      () => LoaderUtils.LOADING(`#${this.id} .users`),
      (users_response) => {
        const users_content = this.getUsersContent(users_response)
        LoaderUtils.LOADED(`#${this.id} .users`, users_content)
      }
    )
  }

  private getUsersContent(users: Array<User>) {
    let users_content = ''

    users.forEach(user => {
      users_content += `<option value="${user.id}">${user.username}</option>`
    })

    return users_content;
  }
}

export default SubmitTeamEntryModal
import ApiUtils from '../../utils/api-utils'
import Store from '../../store/store'
import { api } from '../../config'
import { TyreCompound } from '../../types';

const GET_ALL_TYRE_COMPOUNDS = (game_id: number, loading_callback = () => {}, success_callback: (response: Array<TyreCompound>) => void = () => {}, error_callback = () => {}): void => {
  loading_callback();

  $.ajax({
    type: "GET",
    url: `${api}/tyre_compounds?game_id=${game_id}&token=${Store.ACCESS_TOKEN()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

export { GET_ALL_TYRE_COMPOUNDS }
import ValidationUtils from "../../dashboards/utils/validation-utils";
import { RegistrationRequest } from "../types";

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ON_SUBMIT = (...actions: Array<(request: RegistrationRequest) => Promise<void>>): void => {
  $("#registration-btn").on('click', function() {   
    const registration_request: RegistrationRequest = {
      username: <string> $("#username").val(),
      email: <string> $("#email").val(),
      flag: (<string>$("#flags").val()).replace(/.*\s/, ""),
      password: <string> $("#password").val()
    }

    const agreed_terms_conditions = $("#terms-conditions-input").is(":checked")

    let is_valid = true;

    if(agreed_terms_conditions) {
      ValidationUtils.RESET(`label.form-check-label`)
    } else {
      ValidationUtils.INVALIDATE(`label.form-check-label`, 'You must agree to our terms and conditions.')
      is_valid = false;
    }

    if(registration_request.password && registration_request.password.length > 8) {
      ValidationUtils.RESET(`input#password`)
    } else {
      ValidationUtils.INVALIDATE(`input#password`, 'Password must be more than 8 characters.')
      is_valid = false;
    }

    if(registration_request.username && registration_request.username.length > 5) {
      ValidationUtils.RESET(`input#username`)
    } else {
      ValidationUtils.INVALIDATE(`input#username`, 'Username must be more than 4 characters.')
      is_valid = false;
    }

    if(registration_request.email && EMAIL_REGEX.test(registration_request.email.toLocaleLowerCase())) {
      ValidationUtils.RESET(`input#email`)
    } else {
      ValidationUtils.INVALIDATE(`input#email`, 'Email must be a valid address.')
      is_valid = false;
    }

    if(! is_valid) {
      return;
    }
        
    actions.forEach(handler => {
      handler(registration_request)
    })
  })
}

export { ON_SUBMIT }
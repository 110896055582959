import { Division } from "../../types"
import { TeamActions } from "../actions"
import TeamBannerComponent from "../components/banner/team-banner-component"

const ON_PAGE_LOAD = (active_division: Division): void => {
    $('#index-first-row').html('')

    TeamActions.GET_TEAMS(
        active_division.id,
        () => { },
        (teams) => {
            teams.forEach((team, index) => {
                new TeamBannerComponent(
                    '#index-first-row',
                    team,
                    index + 1
                ).render()
            })
        }
    )
}

export { ON_PAGE_LOAD }
import ApiUtils from '../../utils/api-utils'
import Store from '../../store/store'
import { api } from '../../config'
import {  Division, DivisionRequest, UserDivisionSubmission } from '../../types'

const GET_DIVISIONS = (loading_callback = () => {}, success_callback: (divisions: Array<Division>) => void = () => {}, error_callback = () => {}): void => {
  loading_callback()
  
  $.ajax({
    type: "GET",
    url: `${api}/division?league_id=${Store.ACTIVE_LEAGUE()}&m=true&token=${Store.ACCESS_TOKEN()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const GET_DIVISIONS_SYNC = (error_callback = () => {}): Array<Division> => { 
  let result: Array<Division> = []

  $.ajax({
    type: "GET",
    url: `${api}/division?league_id=${Store.ACTIVE_LEAGUE()}&m=true&token=${Store.ACCESS_TOKEN()}`,
    success: (response) => {
      result = response.values;
    },
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback),
    async: false
  });

  return result;
}

const GET_MY_DIVISIONS = (loading_callback = () => {}, success_callback: (divisions: Array<Division>) => void = () => {}, error_callback = () => {}): void => {
  loading_callback()
  
  $.ajax({
    type: "GET",
    url: `${api}/division/member_of?league_id=${Store.ACTIVE_LEAGUE()}&m=true&token=${Store.ACCESS_TOKEN()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const GET_MY_DIVISIONS_SYNC = (error_callback = () => {}): Array<Division> => { 
  let result: Array<Division> = []

  $.ajax({
    type: "GET",
    url: `${api}/division/member_of?league_id=${Store.ACTIVE_LEAGUE()}&m=true&token=${Store.ACCESS_TOKEN()}`,
    success: (response) => {
      result = response.values;
    },
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback),
    async: false
  });

  return result;
}

const GET_MANAGER_DIVISIONS = (loading_callback = () => {}, success_callback: (divisions: Array<Division>) => void = () => {}, error_callback = () => {}): void => {
  loading_callback()
  
  $.ajax({
    type: "GET",
    url: `${api}/divisions/manager?token=${Store.ACCESS_TOKEN()}&m=true`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const CREATE_DIVISION = (division: DivisionRequest, loading_callback = () => {}, success_callback = () => {}, error_callback = () => {}): void => {
  loading_callback();

  $.ajax({
    type: "POST",
    url: `${api}/division`,
    data: JSON.stringify({
      token: Store.ACCESS_TOKEN(),
      league_id: Store.ACTIVE_LEAGUE(),
      ...division
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const EDIT_DIVISION = (division_id: number, division: DivisionRequest, loading_callback = () => {}, success_callback = () => {}, error_callback = () => {}): void => {
  loading_callback();

  $.ajax({
    type: "POST",
    url: `${api}/division/edit`,
    data: JSON.stringify({
      token: Store.ACCESS_TOKEN(),
      division_id: division_id,
      ...division
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const ADD_USER = (request: UserDivisionSubmission, loading_callback = () => {}, success_callback = () => {}, error_callback = () => {}): void  => {
  loading_callback();

  $.ajax({
    type: "POST",
    url: `${api}/division/user/add`,
    data: JSON.stringify({
      token: Store.ACCESS_TOKEN(),
      ...request
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const REMOVE_USER = (request: UserDivisionSubmission, loading_callback = () => {}, success_callback = () => {}, error_callback = () => {}): void  => {
  loading_callback();
  
  $.ajax({
    type: "DELETE",
    url: `${api}/division/user/delete`,
    data: JSON.stringify({
      token: Store.ACCESS_TOKEN(),
      ...request
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const REMOVE = (division_id: number, loading_callback = () => {}, success_callback = () => {}, error_callback = () => {}): void  => {
  loading_callback();
  
  $.ajax({
    type: "DELETE",
    url: `${api}/division/delete?division_id=${division_id}&token=${Store.ACCESS_TOKEN()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

export { GET_DIVISIONS, GET_DIVISIONS_SYNC, GET_MY_DIVISIONS, GET_MY_DIVISIONS_SYNC, GET_MANAGER_DIVISIONS, CREATE_DIVISION, EDIT_DIVISION, ADD_USER, REMOVE_USER, REMOVE }
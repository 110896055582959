abstract class Modal<T> {

  id: string
  title: string
  entity: T
  container: string

  onCloseCallback: () => void = () => { };

  constructor(id: string, title: string, entity: T, container = "#modals") {
    this.id = id;
    this.title = title;
    this.entity = entity;
    this.container = container;
  }

  render(): void {
    $(this.container).html(
      this.getTemplate(this.entity)
    );
    
    this.getModalActions(this.entity)
    
    $(`#${this.id}`).modal('show')
  }

  close(): void {
    $(`#${this.id}`).modal('hide')
    this.onCloseCallback()
  }

  onClose(callback: () => void = () => { }): Modal<T> {
    this.onCloseCallback = callback;
    return this;
  }

  abstract getTemplate(entity: T): string

  getModalActions(entity: T): void {
    
  }
}

export default Modal
import ApiUtils from '../../utils/api-utils'
import Store from '../../store/store'
import { api } from '../../config'
import { Track } from '../../types';

const GET_TRACKS = (game_id: number, loading_callback = () => {}, success_callback: (response: Array<Track>) => void = () => {}, error_callback = () => {}): void => {
    loading_callback();

    $.ajax({
      type: "GET",
      url: `${api}/track?game_id=${game_id}&token=${Store.ACCESS_TOKEN()}`,
      success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
      error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
    });
}

export { GET_TRACKS }
import { QualifyingResultEntry } from "../../../types";
import { ResultActions } from "../../actions";
import SubmitQualifyingResultEntryModal from "../../modals/result/submit-qualifying-result-entry";
import TimeUtils from "../../utils/time-utils";
import ResultCardComponent, { ResultDetail } from "./result-card-component";

const FINISHED = 3;

export interface QualifyingResultDetails {
  POINTS: ResultDetail
  LAP_TIME: ResultDetail
  TYRE_COMPOUND: ResultDetail
  RESULT_TYPE: ResultDetail
}

class QualifyingResultCardComponent extends ResultCardComponent<QualifyingResultEntry> {

  getResultDetailContent(detail: ResultDetail, leader_entry: QualifyingResultEntry, entry: QualifyingResultEntry): string {
    if(QualifyingResultDetail.LAP_TIME === detail && entry.fastest_time > 0) {
      return this.getQualifyingDetailContent(detail, TimeUtils.GET_FORMATTED_TIME(entry.fastest_time))
    }

    if(QualifyingResultDetail.LAP_TIME === detail && entry.result_type.id !== FINISHED) {
      return this.getQualifyingDetailContent(QualifyingResultDetail.RESULT_TYPE, entry.result_type.code)
    }
  
    if(QualifyingResultDetail.POINTS === detail) {
      return this.getQualifyingDetailContent(detail, `${entry.points}`)
    }

    if(QualifyingResultDetail.TYRE_COMPOUND === detail && entry.result_type.id === FINISHED) {
      detail.background_color = this.getTyreColor(entry.tyre_compound.code)
      return this.getQualifyingDetailContent(detail, `${entry.tyre_compound.code}`)
    }

    return '';
  }

  getQualifyingDetailContent(detail: ResultDetail, value: string): string {
    return `<div class="specific-detail total-race-time d-flex align-items-right justify-content-center mr-2 bg-${detail.background_color} text-white">
              ${ value }
              <br>
              <span style="font-size: 10px; margin-top: 10px;" class="sub-title">${detail.name.toLowerCase()}</span>
            </div>`
  }

  onCardAction(): void {
    new SubmitQualifyingResultEntryModal(this.division, this.event, undefined, ResultActions.GET_QUALIFYING_RESULT_TYPES, this).render()
  }

  onOrderChange(): void {
    this.refresh()
  }

  onEditAction(entry: QualifyingResultEntry): void {
    new SubmitQualifyingResultEntryModal(this.division, this.event, entry, ResultActions.GET_QUALIFYING_RESULT_TYPES, this).render()
  }

  onRemoveAction(entry: QualifyingResultEntry): void {
    super.onRemoveAction(entry) 
    this.refresh()
  }

  getTyreColor(code: string) : string {
    switch(code) {
      case 'S':
      case 'RS':
        return 'primary'
      case 'SS':
      case 'RSS':
        return 'purple'
      case 'M':
      case 'RM':
        return 'warning'
      case 'H':
      case 'RH':
      default:
        return 'secondary'
    }
  }
}

export const QualifyingResultDetail: QualifyingResultDetails = {
  POINTS: {
    name: 'Points',
    background_color: 'primary'
  },
  LAP_TIME: {
    name: 'Time',
    background_color: 'secondary'
  },
  TYRE_COMPOUND: {
    name: 'Tyres',
    background_color: 'secondary'
  },
  RESULT_TYPE: {
    name: '',
    background_color: 'secondary'
  }
}

export default QualifyingResultCardComponent
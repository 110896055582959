import TermsConditionsModal from '../../dashboards/modals/terms-conditions-modal'
import EventUtils from '../../dashboards/utils/event-utils'
import { RegistrationActions } from '../actions'
import { RegistrationEvents } from '../events'
import { RegistrationRequest } from '../types'

const ON_PAGE_LOAD = (): void => {
  RegistrationEvents.ON_SUBMIT(
    REGISTER
  )

  EventUtils.REGISTER_CLICK_EVENT(`#open-terms-and-condition-modal`, () => {
    new TermsConditionsModal().render();
  })
}

const REGISTER = async (registration_request: RegistrationRequest) => {
  RegistrationActions.REGISTER(
    registration_request, 
    REGISTRATION_LOADING, 
    REGISTRATION_SUCCEEDED, 
    REGISTRATION_FAILED
  )
}

const REGISTRATION_SUCCEEDED = () => {
  window.location.href = "/login.html?registrationSuccess"
}

const REGISTRATION_FAILED = () => {
  $("button").prop('disabled', false);
  $("#registration-loader").hide();
  $("button span").show();
}

const REGISTRATION_LOADING = () => {
  $("button").prop('disabled', true);
  $("#registration-loader").show();
  $("button span").hide();
}

export { ON_PAGE_LOAD }
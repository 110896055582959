import ApiUtils from '../../utils/api-utils'
import Store from '../../store/store'
import { api } from '../../config'
import { RetrievalResponse, SubmitTimeTrialEventEntryRequest, TimeTrialEvent, TimeTrialEventEntry } from '../../types'

const GET_EVENTS = (loading_callback = () => {}, success_callback: (response: Array<TimeTrialEvent>) => void = () => {}, error_callback = () => {}): void => {
  loading_callback()

  $.ajax({
    type: "GET",
    url: `${api}/timetrials/events`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  })
}

const GET_VERIFIED_EVENT_ENTRIES = (event_id: number, success_callback: (response: Array<TimeTrialEventEntry>) => void = () => {}, error_callback = () => {}): void => {
  $.ajax({
    type: "GET",
    url: `${api}/timetrials/events/entries${event_id === undefined ? ``: `?time_trial_event_id=${event_id}`}`,
    success: (response: RetrievalResponse<TimeTrialEventEntry>) => 
      ApiUtils.DEFAULT_SUCCESS_HANDLER(
        { values: response.values.filter(entry => entry.verified), ...response },
        success_callback
      ),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  })
}

const GET_EVENT_ENTRIES = (event_id: number, success_callback: (response: Array<TimeTrialEventEntry>) => void = () => {}, error_callback = () => {}): void => {
  $.ajax({
    type: "GET",
    url: `${api}/timetrials/events/entries${event_id === undefined ? ``: `?time_trial_event_id=${event_id}`}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  })
}

const SUBMIT_EVENT_ENTRY = (request: SubmitTimeTrialEventEntryRequest, loading_callback = () => {}, success_callback: () => void = () => {}, error_callback = () => {}):void => {
  loading_callback()

  $.ajax({
    type: "POST",
    url: `${api}/timetrials/events/entry`,
    data: JSON.stringify({
      ...request,
      token: Store.ACCESS_TOKEN()
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  })
}

export { GET_EVENTS, GET_VERIFIED_EVENT_ENTRIES, GET_EVENT_ENTRIES, SUBMIT_EVENT_ENTRY }
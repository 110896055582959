import { Division, PenaltyDefinition, Report, User } from "../../../types";
import { PenaltyActions, UserActions } from "../../actions";
import CardComponent from "../../components/card/card-component";

import AlertUtils from "../../../utils/alert-utils";
import EventUtils from "../../utils/event-utils";
import LoaderUtils from "../../utils/loader-utils";
import ValidationUtils from "../../utils/validation-utils";

import ReportDetailsModal, { ReportDetailPanel } from "./report-details-modal";

class SubmitPenaltyModal extends ReportDetailsModal {

  id = 'submit-penalty'
  title = 'Submit Penalty'
  origin_card?: CardComponent<any>

  constructor(division: Division, report: Report, origin_card?: CardComponent<any>) {
    super(report, [ ReportDetailPanel.VOTES ])
    this.division = division;
    this.origin_card = origin_card;
  }

  hasExtraPanels(): boolean {
    return true;
  }

  getExtraPanels(report: Report): string {
    PenaltyActions.GET_POSSIBLE_PENALTIES(
      () => LoaderUtils.LOADING(`#${this.id} .penalties`),
      (response) => {
        const penalties_content = this.getPenaltiesContent(response)
        LoaderUtils.LOADED(`#${this.id} .penalties`, penalties_content)
      }
    )

    UserActions.GET_DIVISION_USERS(
      this.division.id,
      () => LoaderUtils.LOADING(`#${this.id} .drivers`),
      (response) => {
        const drivers_content = this.getDriversContent(response)
        LoaderUtils.LOADED(`#${this.id} .drivers`, drivers_content)
      }
    )

    return `
      ${ super.getExtraPanels(report) }

      <div id="${this.id}" class="ofr-modal modal-content">
        <div class="modal-header">
            <h5 class="modal-title">${this.title}</h5>
        </div>
        <div class="modal-body">       
            <div class="form-group penalties">
                <label>Penalty</label>
                <div class="sk-three-bounce select-loader loader">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div>

                <select class="form-control loaded-content" required>
                    
                </select>
            </div>   

            <div class="form-group drivers">
                <label>Accused</label>
                <div class="sk-three-bounce select-loader loader">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div>
                
                <select class="form-control loaded-content" required>
                    
                </select>
            </div> 

            <div class="form-group justification">
                <label>Justification</label>
                <textarea class="form-control" rows="3"></textarea>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">Close</button>
            <button id="submit-penalty" type="button" class="btn btn-primary btn-pill">
                <div style="display:none; height: 20px;" class="sk-three-bounce loader">
                    <div style="background-color:white;" class="bounce1"></div>
                    <div style="background-color:white;" class="bounce2"></div>
                    <div style="background-color:white;" class="bounce3"></div>
                </div>
                <span class="loaded-content">Submit</span>
            </button>
        </div>
      </div>
    `
  }

  getModalActions(report: Report): void {
    EventUtils.REMOVE_ALL_EVENTS(`#${this.id} button#submit-penalty`)
    EventUtils.REGISTER_CLICK_EVENT(
      `#${this.id} button#submit-penalty`,
      () => {
        const penalty_id = <number> $(`#${this.id} .penalties select`).val()
        const user_id = <number> $(`#${this.id} .drivers select`).val()
        const justification = <string> $(`#${this.id} .justification textarea`).val()

        let is_valid = true;

        if(justification && justification.length >= 25 && justification.length <= 500) {
          ValidationUtils.RESET(`#${this.id} .justification textarea`)
        } else {
          ValidationUtils.INVALIDATE(`#${this.id} .justification textarea`, 'Justification must be between 25 and 500 characters.')
          is_valid = false;
        }
  
        if(! is_valid) {
          return;
        }

        const penalty = {
          report_id: report.id,
          penalty_id: penalty_id,
          penalty_user_id: user_id,
          received_event_id: this.entity.event_id,
          description: justification
        }

        PenaltyActions.SUBMIT_PENALTY(
          penalty,
          () => LoaderUtils.LOADING(`#${this.id} button#submit-penalty`),
          () => {
            this.close()
            AlertUtils.NOTIFY('Penalty was succesfully submitted.', 'Success!')
            LoaderUtils.LOADED(`#${this.id} button#submit-penalty`, 'Submit')

            if(this.origin_card) this.origin_card.reload()
          },
          () => LoaderUtils.LOADED(`#${this.id} button#submit-penalty`, 'Submit')
        )
      }
    )
  }

  getPenaltiesContent(penalties: Array<PenaltyDefinition>): string {
    let penalties_content = ''

    penalties.forEach(penalty => {
      penalties_content += `<option value="${penalty.id}">${penalty.name}</option>`
    })

    return penalties_content;
  }

  getDriversContent(users: Array<User>): string {
    let users_content = ''

    users.forEach(user => {
      users_content += `<option value="${user.id}">${user.username}</option>`
    })

    return users_content;
  }
} 

export default SubmitPenaltyModal
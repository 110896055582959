import ApiUtils from '../../utils/api-utils'

import { api } from '../../config'
import { RequestPasswordResetRequest, ResetPasswordRequest } from '../types';
import { Response } from '../../types';

const REQUEST_RESET = (request_reset_password_request: RequestPasswordResetRequest, loading_callback = () => {}, success_callback: (response: Response) => void = () => {}, error_callback = () => {}): void => {
  loading_callback();
  
  $.ajax({
    type: "POST",
    url: `${api}/reset_password_request`,
    data: JSON.stringify(request_reset_password_request),
    dataType: 'json',
    contentType: 'application/json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const RESET = (reset_password_request: ResetPasswordRequest, loading_callback = () => {}, success_callback: (response: Response) => void = () => {}, error_callback = () => {}): void => {
    loading_callback();
    
    $.ajax({
      type: "POST",
      url: `${api}/reset_password`,
      data: JSON.stringify(reset_password_request),
      dataType: 'json',
      contentType: 'application/json',
      success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
      error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
    });
  }



export { REQUEST_RESET, RESET }
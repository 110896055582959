import { Division, Team, User } from "../../types"
import { TeamActions } from "../actions"
import TeamCardComponent from "../components/card/team-card-component"
import CreateTeamModal from "../modals/team/create-team-modal"
import EventUtils from "../utils/event-utils"

const ON_PAGE_LOAD = (active_division: Division): void => {
  $('#index-first-row').css("margin-top", "2.5rem")
  $('.page-buttons').html(
    `<button type="button" id="open-create-team-modal-btn" class="btn btn-primary btn-pill">
      Create team
    </button>`
  )

  LOAD_TEAMS(active_division);

  EventUtils.REGISTER_CLICK_EVENT(`#open-create-team-modal-btn`, () => {
    new CreateTeamModal(active_division)
      .onClose(() => LOAD_TEAMS(active_division))
      .render()
  })
}

const LOAD_TEAMS = (division: Division): void => {
  TeamActions.GET_TEAMS(
    division.id,
    () => { },
    (teams_response) => { TEAMS_LOADED(division, teams_response) }
  )
}

const TEAMS_LOADED = (division: Division, teams: Array<Team>): void => {
  $('#index-first-row').html('')
  teams.forEach(team => {
    new TeamCardComponent(
      '#index-first-row', 
      team,
      division,
      (team_id: number, callback: (content: Array<User>) => void ) => {
        TeamActions.GET_TEAMS(
          division.id,
          () => { },
          (teams_response) => { 
            const lookup = teams_response.find(team => team.id === team_id) 
            team = lookup === undefined ? team : lookup

            callback(team.users)
          }
        )
      },
      () => { LOAD_TEAMS(division) }
    ).render()
  })
}

export { ON_PAGE_LOAD }
import { OnlineRacingLeague, Privilege, User, UserPrivileges } from "../../../types";
import AlertUtils from "../../../utils/alert-utils";
import { PrivilegeActions } from "../../actions";
import CardComponent from "../../components/card/card-component";
import EventUtils from "../../utils/event-utils";
import LoaderUtils from "../../utils/loader-utils";
import Modal from "../modal";

const id = 'edit-league-user-privilege-modal'
const title = "Edit privileges"

class EditLeagueUserPrivilegeModal extends Modal<UserPrivileges> {

  league: OnlineRacingLeague
  origin_card: CardComponent<any>;

  constructor(league: OnlineRacingLeague, user: UserPrivileges, origin_card: CardComponent<any>) {
    super(id, title, user)

    this.league = league;
    this.origin_card = origin_card;
  }
  
  getTemplate(user: UserPrivileges): string {
    return `
      <div class="modal fade" id="${this.id}" tabindex="-1" role="dialog" aria-labelledby="submit-user-privilege-modals" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="submit-report-modals">${this.title}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">    
              <div class="form-group users">
                <label>User</label>
                <div class="sk-three-bounce select-loader loader">
                  <div class="bounce1"></div>
                  <div class="bounce2"></div>
                  <div class="bounce3"></div>
                </div>

                <select class="loaded-content form-control" disabled required>
                    <option>${user.username}</option>
                </select>
              </div>      

              <div class="form-group privileges">
                <label>Privileges</label>
                <div class="sk-three-bounce select-loader loader">
                  <div class="bounce1"></div>
                  <div class="bounce2"></div>
                  <div class="bounce3"></div>
                </div>

                <select style="width: 100%;" class="loaded-content js-example-basic-multiple form-control" name="priviliges[]" multiple="multiple" required>
                  
                </select>
              </div> 
            </div>
            <div class="modal-footer">
              <button id="save-privileges-btn" type="button" class="btn btn-primary btn-pill">
                <div style="display:none; height: 20px;" class="sk-three-bounce loader">
                  <div style="background-color:white;" class="bounce1"></div>
                  <div style="background-color:white;" class="bounce2"></div>
                  <div style="background-color:white;" class="bounce3"></div>
                </div>
                <span class="loaded-content">Save</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    `
  }

  getModalActions(user: UserPrivileges): void {
    this.getFormData(user)

    EventUtils.REGISTER_CLICK_EVENT(`#${this.id} button#save-privileges-btn`, () => {
      const selected_privileges = <string[]> $(`#${this.id} .privileges .loaded-content`).val();

      PrivilegeActions.LEAGUE_UPDATE({
        league_id: this.league.id,
        league_privilege_ids: selected_privileges.map(selected_privilege => parseInt(selected_privilege)),
        user_id: user.id
      },
      () => { LoaderUtils.LOADING(`#${this.id} button#save-privileges-btn`) },
      () => { 
          this.close()
          AlertUtils.NOTIFY('Privileges were succesfully saved.', 'Success!')
          LoaderUtils.LOADED(`#${this.id} button#save-privileges-btn`, 'Save') 
  
          if(this.origin_card) this.origin_card.reload()
      },
      () => { LoaderUtils.LOADED(`#${this.id} button#save-privileges-btn`, 'Save')  })
    })

    $(`#${id} .privileges select`).select2();
  }

  private getFormData(user: UserPrivileges): void {
    PrivilegeActions.GET_ALL_LEAGUE_PRIVILEGES(
      () => { LoaderUtils.LOADING(`#${this.id} .privileges`) },
      (privilege_response) => { 
        PrivilegeActions.GET_LEAGUE_USERS_PRIVILEGES(
          this.league.id,
          () => { LoaderUtils.LOADING(`#${this.id} .privileges`) },
          (my_privilege_response) => { 
            const my_privileges = my_privilege_response.find(user_privilege => user_privilege.id === user.id)
            const my_league_privileges = my_privileges 
              ? my_privileges.league_privileges
              : []

            const privileges_content = this.privileges_content(privilege_response, my_league_privileges);
            LoaderUtils.LOADED(`#${this.id} .privileges`, privileges_content)            
          }
        )
      }
    )
  }

  private privileges_content(privileges: Array<Privilege>, selected_privileges: Array<Privilege>): string {
    let privilege_content = ''

    privileges.forEach(privilege => {
      const isSelected = selected_privileges.find(selected => privilege.id === selected.id) !== undefined;

      privilege_content += `<option value="${privilege.id}" ${isSelected ? 'selected' : ''}>${privilege.name}</option>`
    })

    return privilege_content;
  }

}

export default EditLeagueUserPrivilegeModal
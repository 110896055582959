
import { OnlineRacingLeague } from "../../types";
import { PrivilegeActions } from "../actions";
import LeagueUserCardComponent from "../components/card/league-user-card-component";

const ON_PAGE_LOAD = (active_league: OnlineRacingLeague): void => {
  new LeagueUserCardComponent(
      '#index-first-row',
      'primary',
      active_league,
      PrivilegeActions.GET_LEAGUE_USERS_PRIVILEGES
  ).render()
}

export { ON_PAGE_LOAD }
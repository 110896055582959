import ApiUtils from '../../utils/api-utils'

import { LoginRequest } from '../types'
import { api } from '../../config'
import { Response } from '../../types';

const LOGIN = (login_request: LoginRequest, loading_callback = () => {}, success_callback: (response: Response) => void = () => {}, error_callback = () => {}): void => {
  loading_callback();
  
  $.ajax({
    type: "POST",
    url: `${api}/login`,
    data: JSON.stringify(login_request),
    dataType: 'json',
    contentType: 'application/json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

export { LOGIN }
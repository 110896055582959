import { RaceResultEntry, RaceResultEntryModel, SubmitRaceResultEntryRequest } from "../../types";

const MAP = (models: Array<RaceResultEntryModel>): Array<RaceResultEntry> => {
    return models.map(model => {
        const { race_result_type, ...entry } = model
        return {
            ...entry,
            result_type: model.race_result_type
        };
    })
}

const MAP_TO_REQUEST = (entries: Array<RaceResultEntry>): Array<SubmitRaceResultEntryRequest> => {
    return entries.map(entry => {
        return {
            user_id: entry.user.id,
            vehicle_id: entry.vehicle.id,
            division_team_id: entry.team?.id,
            race_result_type_id: entry.result_type.id,
            finish_position: entry.finish_position,
            start_position: entry.start_position,
            laps: entry.laps,
            total_race_time: entry.total_race_time,
            fastest_lap_time: entry.fastest_lap_time,
            fastest_lap: entry.fastest_lap,
            stops: entry.stops,
            points: entry.points
        }
    })
}

export default { MAP, MAP_TO_REQUEST }
import { Division, StandingEntry } from "../../types";
import StandingComponent from "./standing-component";

class TeamStandingComponent extends StandingComponent<StandingEntry> {
  
  constructor(container_selector: string, division: Division, standings_fn: (division_id: number, loading_callback: () => void, callback: (standings: Array<StandingEntry>) => void, error_callback: () => void) => void) {
    super(container_selector, 'col-xl-5', 'Team Standings', division, standings_fn)
  }
    
  getDetail(entry: StandingEntry): string {
    return `
        <strong>${entry.team.name}</strong>
    `
  }
}

export default TeamStandingComponent
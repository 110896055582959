import { Division, OnlineRacingLeague } from "../../types"
import { UserActions } from "../actions"
import ProfileBannerComponent from "../components/banner/profile-banner-component"

const ON_PAGE_LOAD = (active_league: OnlineRacingLeague, active_division: Division): void => {
  
  new ProfileBannerComponent(
    `#index-first-row`,
    UserActions.GET_USER
  ).render()  
}

export { ON_PAGE_LOAD }
import { Service } from "../../authentication/types"
import { DivisionActions, LeagueActions } from "../../dashboards/actions"
import ServiceActions from "../../dashboards/actions/service-actions"
import ConfirmationModal from "../../dashboards/modals/confirmation-modal"
import DivisionModal from "../../dashboards/modals/division/division-modal"
import EventUtils from "../../dashboards/utils/event-utils"
import Store from "../../store/store"
import { Division, OnlineRacingLeague, Pages } from "../../types"
import PermissionUtils from "../../utils/permission-utils"
import { menu, MenuItem, MenuService } from "../menu"
import Navigation from "../navigation"

const CREATE_DIVISION_PERMISSION = 5

const ON_PAGE_LOAD = (active_league: OnlineRacingLeague, league_divisions: Array<Division>, active_division: Division): void => {
  EventUtils.REMOVE_ALL_EVENTS('.league-menu a#league-settings')
  EventUtils.REGISTER_CLICK_EVENT('.league-menu a#league-settings', () => {
    Navigation.NAVIGATE(Pages.LEAGUE_DETAILS.path)
  })

  EventUtils.REMOVE_ALL_EVENTS('.league-menu a#leave-league')
  EventUtils.REGISTER_CLICK_EVENT('.league-menu a#leave-league', () => {
    new ConfirmationModal(`Are you sure you want to leave <b>'${active_league.name}'</b> ?`, () => {
      LeagueActions.LEAVE(
        active_league.id,
        () => { },
        () => {
          Navigation.NAVIGATE(Pages.LANDING.path)
        }
      )
    }).render()
  })

  if(active_division === undefined) {
    return;
  }

  ServiceActions.LOAD_AUTHORIZED_SERVICES(
    active_division.id,
    (services_response) => {
      const isMemberOf = PermissionUtils.HAS_PERMISSION(21)
      SERVICES_LOADED(services_response, isMemberOf)

      if(PermissionUtils.HAS_PERMISSION(CREATE_DIVISION_PERMISSION)) {
        RENDER_CREATE_DIVISION_BUTTON(active_division)
      } else {
        $(".sidebar-buttons").html('')
      }
    
      if(league_divisions.length > 0) {
        $(".division-switcher").css("border", "1px solid #3E404D");
        RENDER_DIVISIONS(league_divisions, active_division)
      } else {
        $(".division-switcher").css("border", "none");
      }
    }
  )
}

const SERVICES_LOADED = (authorized_services: Array<Service>, is_member_of_division: boolean) => {
  let menu_content = ''

  menu.forEach(item => {
    if(isAuthorizedMenuItem(item, authorized_services) && item.isDivisionMemberRequired === false
      || (isAuthorizedMenuItem(item, authorized_services) && item.isDivisionMemberRequired === true && is_member_of_division === true)) {
      let childContent = ''

      let hasChildActive = false;

      item.childs.forEach(child => {
        let currnetChildActive = false;

        if(child.page.path === window.location.hash) {
          hasChildActive = true
          currnetChildActive = true
        }

        const service = authorized_services.find(s => child.service !== undefined && s.id === child.service.id)

        if(service !== undefined || child.service.id === -1) {
          childContent += 
            `<li ${currnetChildActive ? 'class="active"' : ''}>
                <a class="sidenav-item-link" href="${child.page.path}" data-path="${child.page.path}">
                  <span class="nav-text">${child.label}</span>
                </a>
            </li>`
        }
      })

      menu_content +=
        `<li class="has-sub ${hasChildActive ? 'active expand': ''}">
          <a class="sidenav-item-link" href="javascript:void(0)" data-toggle="collapse" data-target="#${item.name}"
              aria-expanded="false" aria-controls="${item.name}">
              <i class="${item.iconClass}"></i>
              <span class="nav-text">${item.label}</span> <b class="caret"></b>
          </a>
          <ul class="collapse ${hasChildActive ? 'show': ''}" id="${item.name}" data-parent="#sidebar-menu">
              <div class="sub-menu">
              ${childContent}
              </div>
          </ul>
        </li>`
    
    }
  })

  $("#sidebar-menu").html(menu_content)

  EventUtils.REGISTER_CLICK_EVENT(`.sub-menu li a`, (event) => {
    const path = event.currentTarget.getAttribute("data-path");
    Navigation.NAVIGATE(path);
  })
}

const RENDER_CREATE_DIVISION_BUTTON = (active_division: Division) => {
  $(".sidebar-buttons").html(
    `<div class="division-create-container mt-4 mx-3">
      <button type="button" id="open-create-division-modal-btn" class="btn btn-pill">
        <i class="mdi mdi-plus"></i> <span class="text-truncate">Create Division</span>
      </button>
    </div>`
  )

  EventUtils.REGISTER_CLICK_EVENT(
    "#open-create-division-modal-btn",
    () => {
      new DivisionModal()
        .onClose(() => LOAD_DIVISIONS(active_division))
        .render();
    }
)
}

const LOAD_DIVISIONS = (active_division: Division) => {
  DivisionActions.GET_DIVISIONS(
    () => {  },
    (division_response) => {
      RENDER_DIVISIONS(division_response, active_division)
    }
  )  
}

const RENDER_DIVISIONS = (league_divisions: Array<Division>, active_division: Division) => {
  $(".division-switcher").html(
    getDivisionSwitcher(league_divisions, active_division.id)
  )

  EventUtils.REMOVE_ALL_EVENTS('.division-switcher div')
  EventUtils.REGISTER_CLICK_EVENT('.division-switcher div', (event) => {
    const selected = event.target.getAttribute("data-id");

    if(Store.ACTIVE_DIVISION() === parseInt(selected)) {
      return;
    }

    Store.SET_ACTIVE_DIVISION(event.target.getAttribute("data-id"))
    window.location.reload()
  })
}

function getDivisionSwitcher(divisions: Array<Division>, active_division_id: number): string {
  let division_switcher_content = ''

  const active_division = divisions.find(division => division.id === active_division_id)
  divisions = divisions.filter(division => division.id !== active_division_id)

  divisions.unshift(active_division);

  divisions
    .forEach(division => {
      const isActive = division.id === active_division_id

      division_switcher_content += `
        <div data-id="${division.id}" ${isActive ? 'class="active"' : '' }>
          <span class="text-truncate" data-id="${division.id}">${division.code}</span>
          <span class="subtitle text-truncate" data-id="${division.id}">${division.name}</span>
        </div>
      `  
    })

  return division_switcher_content;
}

function isAuthorizedMenuItem(item: MenuItem, authorized_services: Array<Service>) {
  return item.childs.filter(child => isAuthorizedService(child.service, authorized_services)).length > 0
}

function isAuthorizedService(service: MenuService, authorized_services: Array<Service>) {
  return authorized_services.filter(
    authorized_service => service.id === -1 
      || service !== undefined && service.id === authorized_service.id
  ).length > 0
}

export default { ON_PAGE_LOAD, RENDER_DIVISIONS }
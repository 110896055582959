import { Division, UniqueEntity } from "../../../types"
import ListItemComponent, { ListCardAction } from "./list-item-component"

class ListComponent<C extends ListItemComponent<T>, T extends UniqueEntity> {

    list_card_actions: Array<ListCardAction>
    list_item_component: new (selector: string, type: T, division: Division, list_card_actions: Array<ListCardAction>, refresh_list_function: () => void, refresh_item_function: () => void) => C

    division: Division
    container_selector: string

    content_function: (id: number, loading_callback: () => void, callback: (content: Array<T>) => void) => void

    constructor(container_selector: string, division: Division, 
                list_item_component: new (selector: string, type: T, division: Division, list_card_actions: Array<ListCardAction>, refresh_list_function: () => void, refresh_item_function: () => void) => C,
                list_item_actions: Array<ListCardAction>,
                content_function: (division_id: number, loading_callback: () => void, callback: (content: Array<T>) => void) => void = () => {}) {
        this.division = division;
        this.container_selector = container_selector;
        this.list_card_actions = list_item_actions;
        this.list_item_component = list_item_component;
        this.content_function = content_function;
    }

    render(): void {
        this.content_function(this.division.id, 
            () => { },
            (items) => {
                items.forEach(item => 
                    new this.list_item_component(this.container_selector, item, this.division, this.list_card_actions, 
                            () => { this.refresh() },
                            () => { this.refresh_item(item.id) })
                        .render()
                )
            }
        )
    }

    refresh(): void {
        $(this.container_selector).html('')
        this.render()
    }

    refresh_item(id: number): void {
        this.content_function(this.division.id,
            () => { },
            (items) => {
                const item = items.find(i => i.id == id)

                if(item) {
                    const component = new this.list_item_component(this.container_selector, item, this.division, this.list_card_actions, 
                        () => { this.refresh() },
                        () => { this.refresh_item(item.id) })

                    const replacement = component.getCard(item)                        

                    $(`${this.container_selector} #list-item-${item.id}`).replaceWith(replacement)

                    component.refresh_card_actions()
                    component.onPostRender(item)
                }
            })
    }
}

export default ListComponent
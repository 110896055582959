const LOADING = (selector: string): void => {
  $(`${selector} .loaded-content`).html('');
  $(`${selector} .loader`).show();

  $(`${selector}`).prop('disabled', true)
}

const LOADED = (selector: string, content: string): void => {
  $(`${selector} .loaded-content`).html(content);
  $(`${selector} .loader`).hide();

  $(`${selector}`).prop('disabled', false)
}

export default { LOADED, LOADING }
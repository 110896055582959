import { Division, Event, PointDistribution, ResultEntry } from "../../../types";
import ConfirmationModal from "../../modals/confirmation-modal";
import EventUtils from "../../utils/event-utils";

import CardComponent from "./card-component";
import { CardAction } from "./card-types";

export interface ResultDetail {
    name: string,
    background_color: string
}
abstract class ResultCardComponent<T extends ResultEntry> extends CardComponent<T> {

  static CARD_ACTIONS: Array<CardAction> = [{
    icon: 'mdi-account-multiple-plus-outline',
    service: -1
  }]

  division: Division
  event: Event
  point_distribution: PointDistribution
  result_details: Array<ResultDetail>

  constructor(container_selector: string, id: string, title: string, color: string, 
                content_function: (division_id: number, callback: (content: Array<T>) => void) => void = () => {}, 
                division: Division, event: Event, point_distribution: PointDistribution, result_details: Array<ResultDetail> = []) {
    super(container_selector, id, title, color, true, true, content_function, () => { }, [], [], [], ResultCardComponent.CARD_ACTIONS)
    this.division = division
    this.event = event
    this.point_distribution = point_distribution;
    this.result_details = result_details;
  }

  abstract onCardAction(): void

  abstract getResultDetailContent(detail: ResultDetail, leader_entry: T, entry: T): string
  
  getEntityId(): number {
    return this.event?.id;
  }

  getCardContent(entries: Array<T>): string {
    if(entries.length === 0) {
      return `
        <div style="text-align: center;">
          NO RESULTS ADDED YET
        </div>
      `
    }

    let card_content = ''

    const leader_entry = entries.find(entry => entry.finish_position === 1);

    entries.forEach((entry, index) => {
      card_content += `
        <div data-id="${entry.finish_position}" class="result-entry media py-3 align-items-center justify-content-between">
          <div style="display: flex; flex-direction: column; flex: 0 0 45px;" class="driver-position rounded-circle align-items-center justify-content-center media-icon iconbox-45 bg-primary text-white">
            ${index + 1}
          </div>
          <div class="driver-details media-body pr-3 mr-3">
            <a class="mt-0 mb-1 font-size-15 text-dark" href="#">${entry.user.username}</a>
            <p style="text-overflow: ellipsis; overflow: hidden;">${entry.team && entry.team.id >= 0 ? entry.team.name : entry.vehicle.manufacturer} (${entry.vehicle.name})</p>
          </div>

          <div class="vehicle-preview media-body pr-3 mr-3 align-items-center">
            ${
                entry.vehicle
                  ? `<img src="https://images.onlineformularacing.co.uk/Vehicles/${entry.vehicle.id}.webp">`
                  : `<img src="https://images.onlineformularacing.co.uk/Vehicles/unknown-dark.webp">`
            }
            
          </div>      
          
          <div class="specific-detail specific-details-container">
            ${ this.getResultDetailsContent(this.result_details, leader_entry, entry) }
          </div>
            
          <div class="static-result-actions media-body pr-3 report-actions">
            <i data-index="0" class="mdi mdi-pencil" data-toggle="modal" data-target="#submit-race-result-entry-modal"></i>  
            <i data-index="0" class="mdi mdi-close"></i>
          </div>
        </div>
      `
    })

    $( ".card-content" ).sortable({
      update: (event, details) => {
        const old_finish_position = parseInt(details.item.attr("data-id"))
        const new_finish_position = details.item.index() + 1
        const item = this.items.find(item => item.finish_position === old_finish_position);

        this.items.splice(old_finish_position - 1, 1)
        this.items.splice(details.item.index(), 0, item);

        this.resetFinishPositions()

        this.onOrderChange(item, old_finish_position, new_finish_position)
      }
    });
    $( ".card-content" ).disableSelection();

    return card_content;
  }

  private getResultDetailsContent(details: Array<ResultDetail>, leader_entry: T, entry: T) {
    let result_details_content = ''

    details.forEach(detail => {
      result_details_content += this.getResultDetailContent(detail, leader_entry, entry)
    })

    return result_details_content;
  }

  setRowEvents(selector: string, entry: T): void {
    EventUtils.REMOVE_ALL_EVENTS(`${selector} .result-entry[data-id="${entry.finish_position}"] .mdi-pencil`)
    EventUtils.REGISTER_CLICK_EVENT(`${selector} .result-entry[data-id="${entry.finish_position}"] .mdi-pencil`, () => this.onEditAction(entry))

    EventUtils.REMOVE_ALL_EVENTS(`${selector} .result-entry[data-id="${entry.finish_position}"] .mdi-close`)
    EventUtils.REGISTER_CLICK_EVENT(`${selector} .result-entry[data-id="${entry.finish_position}"] .mdi-close`, () => {
      new ConfirmationModal(
        `Are you sure you want to remove <b>${entry.user.username}</b> from the results?`,
        () => {
          this.onRemoveAction(entry)
        }
      ).render()
    })
  }

  abstract onOrderChange(entry: T, old_finish_position: number, new_finish_position: number): void

  abstract onEditAction(entry: T): void

  onRemoveAction(entry: T): void {
    this.setLoading()
    this.items = this.items.filter(item => item.finish_position !== entry.finish_position)
  }

  public resetFinishPositions(): void {
    this.items.forEach((item, index) => {
      item.finish_position = index + 1;
    })
  }
}

export default ResultCardComponent
import SimplePicker = require('simplepicker');

const simplepicker = new SimplePicker({
  zIndex: 1051,
  disableTimeSection: true,
  compactMode: true
});

const GET_PICKER = (): SimplePicker =>  {
  return simplepicker;
}

export default { GET_PICKER }
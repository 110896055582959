import { Division, DriverStandingEntry } from "../../types";
import StandingUtils from "../utils/standing-utils";
import StandingComponent from "./standing-component";

class DriverStandingComponent extends StandingComponent<DriverStandingEntry> {
  
  constructor(container_selector: string, division: Division, standings_fn: (division_id: number, loading_callback: () => void, callback: (standings: Array<DriverStandingEntry>) => void, error_callback: () => void) => void) {
    super(container_selector, 'col-xl-7', 'Driver Standings', division, standings_fn)
  }
    
  getDetail(entry: DriverStandingEntry): string {
    return `
      <a href="#/standings" class="clickable-username color-dark">${entry.user.username}</a>
      <span>${ StandingUtils.GET_CONSTRUCTOR(entry) }</span>
    `
  }
}

export default DriverStandingComponent
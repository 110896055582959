import { RaceResultEntry } from "../../../types";
import TimeUtils from "../../utils/time-utils";
import ResultBannerComponent from "./result-banner-component";

class RaceResultBannerComponent extends ResultBannerComponent<RaceResultEntry> {
    
  getResultGap(winner: RaceResultEntry, entry: RaceResultEntry): string {
    if(entry.finish_position === 1) {
      return TimeUtils.GET_FORMATTED_TIME(entry.total_race_time);
    }

    if(entry.result_type.code !== 'FIN') {
      return entry.result_type.code;
    }

    if(entry.laps < winner.laps) {
      return `+ ${winner.laps - entry.laps} LAPS`
    }

    return `+ ${TimeUtils.GET_FORMATTED_TIME(entry.total_race_time - winner.total_race_time)}`
  }
}

export default RaceResultBannerComponent;
import { TimeTrialEvent } from "../../types"
import { TimeTrialActions } from "../actions"
import TimeTrialCardComponent from "../components/card/time-trial-card-component"
import SubmitTimeTrialEventEntryModal from "../modals/time-trial/submit-time-trial-event-entry-modal"
import EventUtils from "../utils/event-utils"

const ON_PAGE_LOAD = (): void => {
  TimeTrialActions.GET_EVENTS(
    () => {},
    (events_response) => events_response.forEach(event => EVENT_LOADED(event))
  )

  EventUtils.REGISTER_CLICK_EVENT("#open-submit-time-trial-event-entry-modal-btn", () => new SubmitTimeTrialEventEntryModal().render())
}

const EVENT_LOADED = (event: TimeTrialEvent): void => {
  new TimeTrialCardComponent(
    '#index-first-row', 
    `time-trial-event-${event.id}`, 
    event.title, 
    TimeTrialActions.GET_VERIFIED_EVENT_ENTRIES, 
    TimeTrialActions.GET_EVENT_ENTRIES,
    event
  ).render()
}

export { ON_PAGE_LOAD }
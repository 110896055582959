import { RaceResultEntry } from "../../../types";
import { ResultActions } from "../../actions";
import SubmitRaceResultEntryModal from "../../modals/result/submit-race-result-entry-modal";
import TimeUtils from "../../utils/time-utils";
import ResultCardComponent, { ResultDetail } from "./result-card-component";

export interface RaceResultDetails {
  FASTEST_LAP_POINTS: ResultDetail,
  POINTS: ResultDetail,
  TOTAL_RACE_TIME: ResultDetail,
  RESULT_TYPE: ResultDetail,
  LAPS_DIFFERENCE: ResultDetail
}

const FINISHED_RESULT_TYPE = 1;

class RaceResultCardComponent extends ResultCardComponent<RaceResultEntry> {

  getResultDetailContent(detail: ResultDetail, leader_entry: RaceResultEntry, entry: RaceResultEntry): string {
    if(RaceResultDetail.TOTAL_RACE_TIME === detail) {
      if(entry.result_type.id === FINISHED_RESULT_TYPE && entry.total_race_time > 0) {
        return this.getRaceDetailContent(detail, TimeUtils.GET_FORMATTED_TIME(entry.total_race_time))
      } else if(entry.result_type.id === FINISHED_RESULT_TYPE && leader_entry?.laps > entry.laps) {
        return this.getRaceDetailContent(RaceResultDetail.LAPS_DIFFERENCE, `+ ${leader_entry.laps - entry.laps}`)
      } else {
        return this.getRaceDetailContent(RaceResultDetail.RESULT_TYPE, entry.result_type.code)
      }
    }

    if(RaceResultDetail.FASTEST_LAP_POINTS === detail && entry.fastest_lap) {
      return this.getRaceDetailContent(detail, `+1 `)
    }

    if(RaceResultDetail.POINTS === detail) {
      return this.getRaceDetailContent(detail, `${entry.points}`)
    }

    return '';
  }

  getRaceDetailContent(detail: ResultDetail, value: string): string {
    return `<div class="specific-detail total-race-time d-flex align-items-right justify-content-center mr-2 bg-${detail.background_color} text-white">
              ${ value }
              <br>
              <span style="font-size: 10px; margin-top: 10px;" class="sub-title">${detail.name.toLowerCase()}</span>
            </div>`
  }

  onCardAction(): void {
    new SubmitRaceResultEntryModal(this.division, this.event, this.point_distribution, undefined, ResultActions.GET_RACE_RESULT_TYPES, this).render()
  }

  onOrderChange(): void {
    this.resetPoints()
    this.resetFinishPositions()
    this.refresh()
  }

  onEditAction(entry: RaceResultEntry): void {
    new SubmitRaceResultEntryModal(this.division, this.event, this.point_distribution, entry, ResultActions.GET_RACE_RESULT_TYPES, this).render()
  }

  onRemoveAction(entry: RaceResultEntry): void {
    super.onRemoveAction(entry)

    this.resetPoints()
    this.refresh()
  }

  public resetPoints(): void {
    if(this.items.length === 0) return

    // Reset
    this.items = this.items
          .map(item => {
            if(item.fastest_lap && item.points > 0) {
              item.points = item.points - 1;
            }

            item.fastest_lap = false
            return item;
          }) 

    const fastest_lap_winner = this.items
          .reduce((previous, current) => 
                    previous.fastest_lap_time <= 0 
                      || (current.fastest_lap_time < previous.fastest_lap_time
                            && current.fastest_lap_time > 0 
                            && current.result_type.id === FINISHED_RESULT_TYPE)
                    ? current 
                    : previous);

    fastest_lap_winner.fastest_lap = true;
    fastest_lap_winner.points += 1
  }
}

export const RaceResultDetail: RaceResultDetails = {
  FASTEST_LAP_POINTS: {
    name: 'Fastest-lap',
    background_color: 'purple'
  },
  POINTS: {
    name: 'Points',
    background_color: 'primary'
  },
  TOTAL_RACE_TIME: {
    name: 'Time',
    background_color: 'secondary'
  },
  RESULT_TYPE: {
    name: '',
    background_color: 'secondary'
  },
  LAPS_DIFFERENCE: {
    name: 'Laps',
    background_color: 'secondary'
  }
}

export default RaceResultCardComponent
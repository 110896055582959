import { Division, OnlineRacingLeague, User, UserPrivileges } from "../../../types";
import WordUtils from "../../../utils/word-utils";
import { DivisionActions, PrivilegeActions } from "../../actions";
import ConfirmationModal from "../../modals/confirmation-modal";
import EditDivisionUserPrivilegeModal from "../../modals/division/edit-division-user-privilege-modal";
import PutDivisionUserModal from "../../modals/division/put-division-user-modal";
import EventUtils from "../../utils/event-utils";
import CardComponent from "./card-component";
import { RowAction } from "./card-types";

export type UserRowActions = {
  EDIT: RowAction
  DELETE: RowAction
}
class DivisionUserCardComponent extends CardComponent<UserPrivileges> {

  static CARD_ACTIONS = [{
    icon: 'mdi-account-multiple-plus-outline',
    service: 9
  }]

  league: OnlineRacingLeague
  division: Division

  constructor(container_selector: string, color: string, league: OnlineRacingLeague, division: Division, content_function: (league_id: number, loading_callback: () => void, callback: (content: Array<UserPrivileges>) => void) => void = () => {}) {
    super(container_selector, 'league-users', 'Users', color, true, false,
      (league_id: number, callback: (content: Array<UserPrivileges>) => void) => { content_function(league_id, () => {}, callback) }, 
      () => {}, [], [RowActions.EDIT, RowActions.DELETE], [], DivisionUserCardComponent.CARD_ACTIONS)

    this.league = league;
    this.division = division;
  }

  getEntityId(): number { return this.division.id; }

  getCardContent(users: Array<UserPrivileges>, color: string, row_actions: RowAction[]): string {
    if(users.length === 0) {
      return `
        <div style="text-align: center;">
          NO USERS IN THIS DIVISION YET.
        </div>
      `
    }

    let users_content = '';

    users.forEach(user => {
      users_content += `
        <div data-id="${user.id}" class="user-entry media py-3 align-items-center justify-content-between">
          <div style="display: flex; flex-direction: column; flex: 0 0 45px;" class="driver-position rounded-circle align-items-center justify-content-center media-icon iconbox-45 bg-primary text-white">
            ${user.username.substr(0, 3).toLocaleUpperCase()}
          </div>
          
          <div class="details media-body pr-3 mr-3">
            <a class="mt-0 mb-1 font-size-15 text-dark" href="#">${user.username}</a>
            <p style="text-overflow: ellipsis; overflow: hidden;"></p>
          </div>    
          
          <div class="tags media-body pr-3 mr-3">
            ${ this.getUserServices(user) }            
          </div>  
            
          <div class="static-result-actions media-body pr-3 report-actions">
            ${this.getRowActionContent(user, row_actions)}
          </div>
        </div>
      `   
    })

    return users_content;
  }

  setRowEvents(selector: string, user: UserPrivileges, row_actions: Array<RowAction>): void {
    if(row_actions.includes(RowActions.EDIT)) {
      EventUtils.REGISTER_CLICK_EVENT(`${selector} .${RowActions.EDIT.class}[data-id="${user.id}"]`, () => { this.onEditAction(user) })
    }

    if(row_actions.includes(RowActions.DELETE)) {
      EventUtils.REGISTER_CLICK_EVENT(`${selector} .${RowActions.DELETE.class}[data-id="${user.id}"]`, () => { this.onRemoveAction(user) })
    }
  }

  onCardAction(): void { 
    new PutDivisionUserModal(this.league, this.division)
      .onClose(() => this.reload())
      .render();
  }

  private onEditAction(user: UserPrivileges) {
    new EditDivisionUserPrivilegeModal(this.division, user, this)
      .onClose(() => this.reload())
      .render()
  }

  private onRemoveAction(user: UserPrivileges) {
    new ConfirmationModal(
      `Are you sure you want to remove <b>${user.username}</b> from <b>${this.division.name}</b>?`,
      () => {
        DivisionActions.REMOVE_USER(
          { user_id: user.id, division_id: this.division.id },
          () => { this.setLoading() },
          () => {
            this.reload()
          }
        )
      }
    ).render()
  }

  private getUserServices(user: UserPrivileges): string {
    let content = ''
    const privileges = user.division_privileges;

    privileges.forEach(privilege => {
      content += `<span class="text-truncate text-white" data-id="5">${ WordUtils.FIRST_UPPER_CASE(privilege.name) }</span>`
    })

    return content;
  }
}

export const RowActions: UserRowActions = {
  EDIT: {
    name: 'EDIT',
    class: 'mdi-pencil',
    service: 8
  },
  DELETE: {
    name: 'DELETE',
    class: 'mdi-close',
    service: 9,
  }
}

export default DivisionUserCardComponent;
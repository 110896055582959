import { QualifyingResultEntry } from "../../types";
import TimeUtils from "../utils/time-utils";
import ResultComponent from "./result-component";

class QualifyingResultComponent extends ResultComponent<QualifyingResultEntry> {
    
  constructor(container_selector: string, event_id: number, result_content_function: (event_id: number, allback: (results: Array<QualifyingResultEntry>) => void) => void = () => {}) {
    super(container_selector, 'Qualifying Results', event_id, result_content_function);
  }

  getResultGap(winner: QualifyingResultEntry, entry: QualifyingResultEntry): string {
    if(entry.result_type.code !== 'FIN') {
      return entry.result_type.code;
    }

    return TimeUtils.GET_FORMATTED_TIME(entry.fastest_time);
  }

  hasFastestLap(entry: QualifyingResultEntry): boolean {
    return false;
  }
}

export default QualifyingResultComponent;
import { User } from "../../../types";

class ProfileBannerComponent {

    container_selector: string
    get_user_fn: (loading_callback: () => void, callback: (user: User) => void, error_callback: () => void) => void
  
    constructor(container_selector: string, get_user_fn: (loading_callback: () => void, callback: (user: User) => void, error_callback: () => void) => void) {
      this.container_selector = container_selector;
      this.get_user_fn = get_user_fn;
    }
  
    render(): void {
      this.get_user_fn(
        () => { },
        (user) => {
          const content = this.getContent(user);
          $(this.container_selector).html(content);
        },
        () => { }
      )
    }
  
    getContent(user: User): string {  
      return `
        <div class="col-md-12">
          <div class="profile-banner-background" style="background-image: url('/images/profile-header.jpeg');"></div>
          <div class="profile-banner-picture" style="background-position: center; background-size: cover; background-image: url('https://images.onlineformularacing.co.uk/UserImages/${user.user_image_id}.png');"></div>
          <div class="profile-banner-details">
            <h3>${user.username}</h3>
            <span>Joined at 12 July 2019</span>
          </div>
        </div>
      `
    }
}

export default ProfileBannerComponent
import { AdditionalInfo, Report, ReportPenalty, ReportVote } from "../../../types";
import HtmlUtils from "../../../utils/html-utils";
import ReportModal from "./report-modal";

export enum ReportDetailPanel {
  VOTES,
  PENALTIES,
  ADDITIONAL_INFO  
}

class ReportDetailsModal extends ReportModal {

  panels_enabled: Array<ReportDetailPanel>

  constructor(report: Report, panels: Array<ReportDetailPanel> = [ ReportDetailPanel.VOTES, ReportDetailPanel.PENALTIES, ReportDetailPanel.ADDITIONAL_INFO ]) {
      super('report-details-modal', 'Report Details', report)
      this.panels_enabled = panels
  }

  hasExtraPanels(report: Report): boolean {
    const has_votes = report.report_votes.length > 0
    const has_additional_info = report.report_additional_info.length > 0

    return has_votes || has_additional_info
  }

  getExtraPanels(report: Report): string {
    const has_votes = report.report_votes.length > 0
    const has_penalties = report.report_penalties.length > 0
    const has_additional_info = report.report_additional_info.length > 0
  
    return `
      ${ this.panels_enabled.includes(ReportDetailPanel.VOTES) && has_votes 
        ? this.getReportVotes(report.report_votes)
        : '' }  

      ${ this.panels_enabled.includes(ReportDetailPanel.PENALTIES) && has_penalties
        ? this.getPenalties(report.report_penalties)
        : '' }  

      ${ this.panels_enabled.includes(ReportDetailPanel.ADDITIONAL_INFO) && has_additional_info 
        ? this.getAdditionalInfo(report.report_additional_info)
        : '' }   
      `
  }
      
  private getAdditionalInfo(additional_info: Array<AdditionalInfo>) {
    let additional_info_content = ''
  
    additional_info.forEach(info => {
      additional_info_content += `
        <div id="report-details-additional-info" class="ofr-modal modal-content mb-4">
            <div class="modal-header">
                <h5 class="modal-title" id="report-details-modal">Additional Info</h5>
            </div>
            <div class="modal-body">
              <blockquote>
                ${ HtmlUtils.SAFE(info.description) }
                <cite>~ ${info.user.username}</cite>
              </blockquote>  
            </div>
        </div>
      `
    })
  
    return additional_info_content;
  }
    
  private getReportVotes(votes: Array<ReportVote>) {
    let report_vote_content = ''
    const user_votes: Map<string, Array<string>> = new Map();
  
    // Group by penalty_user
    votes.forEach(vote => {
      const existing = user_votes.get(vote.penalty_user.username);
      
      if(existing) {
        existing.push(vote.penalties[0].name)
      } else {
        user_votes.set(vote.penalty_user.username, [ vote.penalties[0].name ]);
      }
    })
  
    user_votes.forEach((votes: Array<string>, psn_id: string) => {
      const penalty_counts: Map<string, number> = new Map();
  
      votes.forEach(vote => {
        const existing = penalty_counts.get(vote) || 0;
        penalty_counts.set(vote, existing + 1)
      })
  
      report_vote_content += `
        <div id="votes">
          <div class="ofr-modal modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="report-details-modal">Steward votes - ${psn_id}</h5>
            </div>
            <div id="report-details-votes" class="modal-footer">
              ${ this.getPenaltyCounts(penalty_counts) }
            </div>
          </div>
        </div>
      `
    })
  
    return report_vote_content;
  }

  private getPenalties(penalties: Array<ReportPenalty>) {
    let report_penalty_content = ''
    
    penalties.forEach(penalty => {
      report_penalty_content += `
        <div class="ofr-modal modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="report-details-modal">Penalty - ${penalty.penalty_user.username}</h5>
          </div>
          <div class="modal-body"> 
            <blockquote>
              ${ HtmlUtils.SAFE(penalty.description) }
              <cite>~ OFR Stewards</cite>
            </blockquote> 
          </div>
          <div id="report-details-votes" class="modal-footer">
            <button style="opacity: 1!important;" type="button" class="btn btn-primary btn-pill" disabled>${penalty.penalty.name}</button>
          </div>
        </div>
      `
    })
  
    return report_penalty_content;
  }
    
  private getPenaltyCounts(penalty_counts: Map<string, number>) {
    let penalty_count_content = ''
  
    penalty_counts.forEach((count: number, penalty: string) => {
      penalty_count_content += `<button style="opacity: 1!important;" type="button" class="btn btn-primary btn-pill" data-id="5" disabled="">${penalty} (${count})</button>`
    })
  
    return penalty_count_content;
  }
}

export default ReportDetailsModal
import EventUtils from '../../dashboards/utils/event-utils'
import LoaderUtils from '../../dashboards/utils/loader-utils'
import ValidationUtils from '../../dashboards/utils/validation-utils'
import AlertUtils from '../../utils/alert-utils'
import { ResetPasswordActions } from '../actions'

const ON_REQUEST_PAGE_LOAD = (): void => {
  EventUtils.REGISTER_CLICK_EVENT(
    '#request-password-reset-btn',
    () => {
      const username = <string> $("input#username").val()

      ResetPasswordActions.REQUEST_RESET(
        { username: username },
        () => LoaderUtils.LOADING('#request-password-reset-btn'),
        () => {
            AlertUtils.NOTIFY('Your reset password link is sent to your mail box.', 'Success!')
            LoaderUtils.LOADED('#request-password-reset-btn', "Request password reset")
        },
        () => LoaderUtils.LOADED('#request-password-reset-btn', "Request password reset")
      )
    }
  )
}

const ON_RESET_PAGE_LOAD = (token: string): void => {
    EventUtils.REGISTER_CLICK_EVENT(
      '#reset-password-btn',
      () => {
        const username = <string> $("input#username").val()
        const password = <string> $("input#password").val()
        const password_verification = <string> $("input#password-verification").val()

        let is_valid = true;

        if(password === password_verification) {
          ValidationUtils.RESET(`input#password-verification`)
        } else {
          ValidationUtils.INVALIDATE(`input#password-verification`, 'Password verification does not match.')
          is_valid = false;
        }

        if(password && password.length > 8) {
          ValidationUtils.RESET(`input#password`)
        } else {
          ValidationUtils.INVALIDATE(`input#password`, 'Password must be more than 8 characters.')
          is_valid = false;
        }

        if(! is_valid) {
          return;
        }
  
        ResetPasswordActions.RESET(
          { username: username, password: password, token: token },
          () => LoaderUtils.LOADING('#reset-password-btn'),
          () => {
            LoaderUtils.LOADED('#reset-password-btn', "Reset password")
            window.location.href = '/login.html?passwordResetSuccess'
          },
          () => LoaderUtils.LOADED('#reset-password-btn', "Reset password")
        )
      }
    )
  }

export { ON_REQUEST_PAGE_LOAD, ON_RESET_PAGE_LOAD }

import ApiUtils from '../../utils/api-utils'
import Store from '../../store/store'
import { api } from '../../config'
import { CreateOnineRacingLeagueRequest, OnlineRacingLeague, RetrievalResponse } from '../../types';

const GET_ALL = (success_callback: (response: Array<OnlineRacingLeague>) => void = () => {}, error_callback = () => {}): void => {
  $.ajax({
    type: "GET",
    url: `${api}/league?token=${Store.ACCESS_TOKEN()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback),
  });
}

const SEARCH_BY_NAME = (pattern: string, success_callback: (response: Array<OnlineRacingLeague>) => void = () => {}, error_callback = () => {}): void => {
  $.ajax({
    type: "GET",
    url: `${api}/league?name=${pattern}&token=${Store.ACCESS_TOKEN()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback),
  });
}

const GET_JOINED_LEAGUES = (success_callback: (response: Array<OnlineRacingLeague>) => void = () => {}, error_callback = () => {}): JQuery.jqXHR<RetrievalResponse<OnlineRacingLeague>> => {
  return $.ajax({
    type: "GET",
    url: `${api}/league/member_of?token=${Store.ACCESS_TOKEN()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const GET_CREATED_LEAGUES = (success_callback: (response: Array<OnlineRacingLeague>) => void = () => {}, error_callback = () => {}): JQuery.jqXHR<RetrievalResponse<OnlineRacingLeague>> => {
  return $.ajax({
    type: "GET",
    url: `${api}/league/own?token=${Store.ACCESS_TOKEN()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback),
  });
}

const CREATE = (create_league_request: CreateOnineRacingLeagueRequest, loading_callback = () => {}, success_callback = () => {}, error_callback = () => {}): void => {
  loading_callback();

  $.ajax({
    type: "POST",
    url: `${api}/league/create`,
    data: JSON.stringify({
      ...create_league_request,
      token: Store.ACCESS_TOKEN()
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const JOIN = (league_id: number, loading_callback = () => {}, success_callback = () => {}, error_callback = () => {}): void => {
  loading_callback();

  $.ajax({
    type: "POST",
    url: `${api}/league/join`,
    data: JSON.stringify({
      league_id: league_id,
      token: Store.ACCESS_TOKEN()
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const LEAVE = (league_id: number, loading_callback = () => {}, success_callback = () => {}, error_callback = () => {}): void => {
  loading_callback();

  $.ajax({
    type: "DELETE",
    url: `${api}/league/leave`,
    data: JSON.stringify({
      league_id: league_id,
      token: Store.ACCESS_TOKEN()
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

export { GET_ALL, SEARCH_BY_NAME, GET_JOINED_LEAGUES, GET_CREATED_LEAGUES, CREATE, JOIN, LEAVE }
import { Division, UniqueEntity } from "../../../types";
import EventUtils from "../../utils/event-utils";

export interface ListCardAction {
  name: string
  style: string
  class: string
  icon_class: string
  show_fn: (entity: UniqueEntity) => boolean
}
abstract class ListItemComponent<T extends UniqueEntity> { 

  selector: string
  container_selector: string

  type: T
  division: Division
  card_actions: Array<ListCardAction>

  refresh_list_function: () => void
  refresh_item_function: () => void

  constructor(container_selector: string, type: T, division: Division, card_actions: Array<ListCardAction>, refresh_list_function: () => void, refresh_item_function: () => void) { 
    this.selector = `list-item-${type.id}`
    this.container_selector = container_selector;
    this.type = type;
    this.division = division;
    this.card_actions = card_actions;

    this.refresh_list_function = refresh_list_function;
    this.refresh_item_function = refresh_item_function;
  }

  render(): void {    
    const card = this.getCard(this.type);
    $(this.container_selector).append(card);

    this.card_actions.forEach(action => {
      EventUtils.REMOVE_ALL_EVENTS(`#${this.selector} .${action.class}.action`)
      EventUtils.REGISTER_CLICK_EVENT(
        `#${this.selector} .${action.class}.action`, 
        () => this.onCardAction(action, this.type)
      )
    })
    

    this.onPostRender(this.type)
  }

  getActionsContent(): string {
    let content = ''

    this.card_actions.forEach((action, index) => {
      if(action.show_fn(this.type)) {
        content += `
          <button ${action.class === null ? '' : `style="border: none; ${action.style}"`} class="btn ${action.class} action">
            ${action.icon_class === null ? '' : `<i class="${action.icon_class}"></i> `}${action.name === null ? '' : action.name}
          </button>
        `
      }
    })

    return content;
  }

  refresh(): void {
    this.refresh_item_function()
  }

  refresh_card_actions(): void {
    this.card_actions.forEach(action => {
      EventUtils.REMOVE_ALL_EVENTS(`#${this.selector} .${action.class}.action`)
      EventUtils.REGISTER_CLICK_EVENT(
        `#${this.selector} .${action.class}.action`, 
        () => this.onCardAction(action, this.type)
      )
    })
  }

  onPostRender(type: T): void { }

  abstract getCard(type: T): string;

  abstract onCardAction(action: ListCardAction, type: T): void;
}

export default ListItemComponent
import { RaceResultEntry } from "../../types";
import TimeUtils from "../utils/time-utils";
import ResultComponent from "./result-component";

class RaceResultComponent extends ResultComponent<RaceResultEntry> {

  constructor(container_selector: string, event_id: number, result_content_function: (event_id: number, allback: (results: Array<RaceResultEntry>) => void) => void = () => {}) {
    super(container_selector, 'Race Results', event_id, result_content_function);
  }
    
  getResultGap(winner: RaceResultEntry, entry: RaceResultEntry): string {
    if(entry.finish_position === 1) {
      return TimeUtils.GET_FORMATTED_TIME(entry.total_race_time);
    }

    if(entry.result_type.code !== 'FIN') {
      return entry.result_type.code;
    }

    if(entry.laps < winner.laps) {
      return `+ ${winner.laps - entry.laps} LAPS`
    }

    return `+ ${TimeUtils.GET_FORMATTED_TIME(entry.total_race_time - winner.total_race_time)}`
  }

  hasFastestLap(entry: RaceResultEntry): boolean {
    return entry.fastest_lap
  }
}

export default RaceResultComponent;
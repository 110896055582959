import { Division, OnlineRacingLeague, User, Vehicle } from "../../../types";
import { DivisionActions, RosterActions, TeamActions, UserActions, VehicleActions } from "../../actions";
import LoaderUtils from "../../utils/loader-utils";
import EventUtils from "../../utils/event-utils";
import Modal from "../modal";

const id = 'put-division-user-modal'
const title = 'Add user to division'

class PutDivisionUserModal extends Modal<void> {

    league: OnlineRacingLeague
    division: Division
  
    constructor(league: OnlineRacingLeague, division: Division) {
      super(id, title, undefined)
      this.league = league;
      this.division = division;
    }
  
    getTemplate(): string {
      return `
        <div class="modal fade" id="${this.id}" tabindex="-1" role="dialog" aria-labelledby="submit-report-modals" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="submit-report-modals">${title}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">    
                <div class="form-group users">
                  <label>User</label>
                  <div class="sk-three-bounce select-loader loader">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                  </div>
  
                  <select class="loaded-content form-control" required>
                      
                  </select>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">Close</button>
                <button id="add-user-btn" type="button" class="btn btn-primary btn-pill">
                  <div style="display:none; height: 20px;" class="sk-three-bounce loader">
                    <div style="background-color:white;" class="bounce1"></div>
                    <div style="background-color:white;" class="bounce2"></div>
                    <div style="background-color:white;" class="bounce3"></div>
                  </div>
                  <span class="loaded-content">Save</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      `
    }
  
    getModalActions(): void {
      this.getFormData()

      EventUtils.REMOVE_ALL_EVENTS(`#${this.id} button#add-user-btn`)
      EventUtils.REGISTER_CLICK_EVENT(`#${this.id} button#add-user-btn`, () => {
        const user_id = <string> $(`#${this.id} .users select`).val()

        const request = {
          user_id: parseInt(user_id),
          division_id: this.division.id
        }

        DivisionActions.ADD_USER(
          request,
          () => { LoaderUtils.LOADING(`#${this.id} button#add-user-btn`) },
          () => {
            this.close();
            LoaderUtils.LOADED(`#${this.id} button#add-user-btn`, 'Save')
          },
          () => {
            LoaderUtils.LOADED(`#${this.id} button#add-user-btn`, 'Save')
          }
        )
      })
    }

    private getFormData(): void {
      UserActions.GET_LEAGUE_USERS(
        this.league.id,
        () => { LoaderUtils.LOADING(`#${this.id} .users`) },
        (users_response) => { 
          const users_content = this.getUsersContent(users_response);
          LoaderUtils.LOADED(`#${this.id} .users`, users_content) 
        },
      )
    }
  
    private getUsersContent(users: Array<User>): string {
      let users_content = ''
  
      users.forEach(user => {
        users_content += `<option value="${user.id}">${user.username}</option>`
      })
  
      return users_content;
    }    
  }
  
  export default PutDivisionUserModal
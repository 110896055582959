const REGISTER_CLICK_EVENT = (selector: string, action: (event: JQuery.ClickEvent<HTMLElement>) => void) : void => {
  $(selector).on('click', function(event) {
    action(event)
  })
}

const REGISTER_CHANGE_EVENT = (selector: string, action: (element: HTMLElement, event: JQuery.ChangeEvent) => void) : void => {
  $(selector).on('change', function(event) {
    action(this, event)
  })
}

const REGISTER_ON_KEY_UP_EVENT = (selector: string, action: (element: HTMLElement, event: JQuery.KeyUpEvent) => void) : void => {
  $(selector).on('keyup', function(event) {
    action(this, event)
  })
}

const REMOVE_ALL_EVENTS = (selector: string) : void => {
  $(selector).off()
}

export default { REGISTER_CLICK_EVENT, REGISTER_CHANGE_EVENT, REGISTER_ON_KEY_UP_EVENT, REMOVE_ALL_EVENTS }
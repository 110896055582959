import { Game, ResultEntry } from "../../../types";
import ResultUtils from "../../utils/result-utils";

abstract class ResultBannerComponent<R extends ResultEntry> {

  container_selector: string
  event_id: number
  game: Game

  result_content_function: (event_id: number, callback: (results: Array<R>) => void) => void = () => {}

  constructor(container_selector: string, event_id: number, game: Game, result_content_function: (event_id: number, allback: (results: Array<R>) => void) => void = () => {}) {
    this.container_selector = container_selector;
    this.event_id = event_id;
    this.game = game;

    this.result_content_function = result_content_function;
  }

  render(): void {
    this.result_content_function(
      this.event_id,
      (results) => {
        const content = this.getContent(results);
        $(this.container_selector).html(content);
      }
    )
  }

  getContent(results: Array<R>): string {
    if(results.length == 0) {
      return '';
    }

    const winner = results.find(result => result.finish_position === 1)

    return `
      <div class="col-md-12 mb-9">
      <div class="results-header" style="border-top:4px solid ${ResultUtils.GET_COLOR(winner)};">
          <div class="position">
              <img src="images/first-ribbon.png" />
          </div>
          <div class="driver-details">
              <div class="username">${winner.user.username}</div>
              <div class="constructor">${ResultUtils.GET_CONSTRUCTOR(winner)}</div>
          </div>
          <div class="top-3">
              <table class="race-leaderboard leaderboard" id="race-leaderboard">
                  ${ this.getRemainingTop3(results, winner) }
              </table>
          </div>
          <div class="car-details">
              ${ ResultUtils.GET_VEHICLE(winner) 
                ? `<img ${this.game.code === 'ACC-PS4' ? 'class="acc"' : ''} src="https://images.onlineformularacing.co.uk/Vehicles/${ResultUtils.GET_VEHICLE(winner).id}${this.game.code === 'ACC-PS4' ? '-featured' : ''}.webp" />`
                : ``
              }
              
          </div>
        </div>
      </div>
    `
  }

  getRemainingTop3(results: Array<R>, winner: R): string {
    let content = ''

    results
      .filter(result => result.finish_position === 2 || result.finish_position === 3)
      .forEach(result => {
        content += `
          <tr class="driver">
            <td style="padding-right: 0px;" class="position"><span>${result.finish_position}</span></td>
            <td class="driver" style="border-left: 4px solid ${ResultUtils.GET_COLOR(result)};">
                <a href="#/results" class="clickable-username color-white">${result.user.username}</a>
            </td>
            <td class="gap">
                <span>${ this.getResultGap(winner, result) }</span>
            </td>
          </tr>
        `
      })

    return content;
  }

  abstract getResultGap(winner: R, entry: R): string
}

export default ResultBannerComponent
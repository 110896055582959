
import { Response, RetrievalResponse } from '../types';
import AlertUtils from './alert-utils'

const DUMB = 'dumb';

const DEFAULT_SUCCESS_HANDLER = <E extends any, T extends Response | RetrievalResponse<E>> (response: T, success_callback: (response: Array<E> | E | Response) => void = () => { }, error_callback = () => { }): void => {
  if(! response.success && response.code == -1) {
    localStorage.clear()
    window.location.href = `/login.html`
  } 

  if(response.success) {
    if('values' in response) {
      success_callback((<RetrievalResponse<E>> response).values)
    } else if('object' in response) {
      success_callback((<RetrievalResponse<E>> response).object)
    } else {
      success_callback(response);
    }
  } else {
    const message = response.message;
    AlertUtils.NOTIFY(message, "Something went wrong!", "error");

    if(message.includes(DUMB)) {
      AlertUtils.NOTIFY(`<img src="https://media.giphy.com/media/5xtDarDewDfNyPrYSbe/giphy.gif" width="200px" style="margin-top:5px;"/>`, "Stupidity alert!", "error");
    }
    
    error_callback();
  }
}

const DEFAULT_ERROR_HANDLER = (xhr: JQuery.jqXHR, status: JQuery.Ajax.ErrorTextStatus, error: string, error_callback = () => { }): void => {    
  AlertUtils.NOTIFY(`${xhr.statusText} - ${error}`, "Something went wrong!", "error");
  error_callback();
}
  
export default { DEFAULT_SUCCESS_HANDLER, DEFAULT_ERROR_HANDLER }
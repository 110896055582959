import { Page, Pages } from "../types"

export type MenuItem = {
  name: string
  label: string
  isDivisionMemberRequired: boolean
  iconClass: string
  childs: Array<SubMenuItem>
}

export type SubMenuItem = {
  name: string
  label: string
  page: Page
  service: MenuService,
  tags: string
}

export type MenuService = {
  id: number
}

export const menu: Array<MenuItem> = [
  {
    name: "racing-manager",
    label: "Racing",
    isDivisionMemberRequired: true,
    iconClass: "mdi mdi-flag-checkered",
    childs: [
      {
        name: "reporting",
        label: "Reporting",
        page: Pages.REPORTING,
        service: {
            id: 21
        },
        tags: "my, reporting, reports, dashboard, overview"
      },
      {
        name: "teams",
        label: "Teams",
        page: Pages.TEAMS_ROSTER,
        service: {
            id: 21
        },
        tags: "my, teams, dashboard, overview"
      },
      {
        name: "events",
        label: "Events",
        page: Pages.EVENTS_SIGNUP,
        service: {
            id: 21
        },
      tags: "my, events, signup, dashboard, overview"
      },
      {
        name: "results",
        label: "Results",
        page: Pages.RESULTS,
        service: {
          id: -1
        },
        tags: "results, race results, quali results, event results, overview, dashboard"
      },
      {
        name: "standings",
        label: "Standings",
        page: Pages.STANDINGS,
        service: {
          id: -1
        },
        tags: "standings, driver standings, team standings, division standings, overview, dashboard"
      },
      {
        name: "stewarding",
        label: "Stewarding",
        page: Pages.STEWARDING,
        service: {
            id: 15
        },
        tags: "my, stewarding, votes, dashboard, overview"
      }
    ]
  },
  {
    name: "division-manager",
    label: "Manage Division",
    isDivisionMemberRequired: false,
    iconClass: "mdi mdi-pencil-box-multiple",
    childs: [
      {
        name: "users",
        label: "Users",
        page: Pages.USERS,
        service: {
          id: 9
        },
        tags: "users, overview"
      },
      {
        name: "teams",
        label: "Teams",
        page: Pages.TEAMS,
        service: {
          id: 12
        },
        tags: "rosters, overview"
      },
      {
        name: "events",
        label: "Events",
        page: Pages.EVENTS,
        service: {
          id: 25
        },
        tags: "events, overview"
      },
      {
        name: "quali-results",
        label: "Qualifying Results",
        page: Pages.QUALI_RESULTS,
        service: {
          id: 13
        },
        tags: "results, quali, qualifying, qualification, overview"
      },
      {
        name: "race-results",
        label: "Race Results",
        page: Pages.RACE_RESULTS,
        service: {
          id: 14
        },
        tags: "results, race, overview"
      }
    ]
  },
  {
    name: "league-manager",
    label: "Manage League",
    isDivisionMemberRequired: false,
    iconClass: "mdi mdi-pencil-box-multiple-outline",
    childs: [
      {
        name: "users",
        label: "Users",
        page: Pages.LEAGUE_USERS,
        service: {
          id: 4
        },
        tags: "league users, users, overview"
      },
      {
        name: "divisions",
        label: "Divisions",
        page: Pages.DIVISIONS,
        service: {
          id: 7
        },
        tags: "league users, users, overview"
      },
    ]
  }
]
import ApiUtils from '../../utils/api-utils'
import { api } from '../../config'
import { CreateTeamRequest, DeleteTeamRequest, EditTeamRequest, Team, TeamUserSubmission } from '../../types'
import Store from '../../store/store'

const GET_TEAMS = (division_id: number, loading_callback = () => {}, success_callback: (response: Array<Team>) => void = () => {}, error_callback = () => {}): JQuery.jqXHR<any> => {
  loading_callback()

  return $.ajax({
    type: "GET",
    url: `${api}/division/team?division_id=${division_id}&token=${Store.ACCESS_TOKEN()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  })
}

const CREATE = (request: CreateTeamRequest, loading_callback = () => {}, success_callback = () => {}, error_callback = () => {}): void => {
  loading_callback()

  $.ajax({
    type: "POST",
    url: `${api}/division/team`,
    data: JSON.stringify({
      token: Store.ACCESS_TOKEN(),
      ...request
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  })
}

const EDIT = (request: EditTeamRequest, loading_callback = () => {}, success_callback = () => {}, error_callback = () => {}): void => {
  loading_callback()

  $.ajax({
    type: "POST",
    url: `${api}/division/team/edit`,
    data: JSON.stringify({
      token: Store.ACCESS_TOKEN(),
      ...request
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  })
}

const DELETE = (request: DeleteTeamRequest, loading_callback = () => {}, success_callback = () => {}, error_callback = () => {}): void => {
  loading_callback()

  $.ajax({
    type: "DELETE",
    url: `${api}/division/team`,
    data: JSON.stringify({
      token: Store.ACCESS_TOKEN(),
      ...request
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  })
}

const ADD_USER = (request: TeamUserSubmission, loading_callback = () => {}, success_callback = () => {}, error_callback = () => {}): void => {
  loading_callback()

  $.ajax({
    type: "POST",
    url: `${api}/division/team/user`,
    data: JSON.stringify({
      token: Store.ACCESS_TOKEN(),
      ...request
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  })
}

const REMOVE_USER = (request: TeamUserSubmission, loading_callback = () => {}, success_callback = () => {}, error_callback = () => {}): void => {
  loading_callback()

  $.ajax({
    type: "DELETE",
    url: `${api}/division/team/user`,
    data: JSON.stringify({
      token: Store.ACCESS_TOKEN(),
      ...request
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  })
}

export { CREATE, EDIT, DELETE, GET_TEAMS, ADD_USER, REMOVE_USER }
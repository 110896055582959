const FIRST_UPPER_CASE = (text: string): string => {
    text = text.toLowerCase()

    if(text.length <= 1) {
        return text.toUpperCase()
    }
    
    return `${text.charAt(0).toUpperCase()}${text.substring(1)}`
  }
  
  export default { FIRST_UPPER_CASE }
import ValidationUtils from "../../dashboards/utils/validation-utils";
import { LoginRequest } from "../types";

const ON_SUBMIT = (...actions: Array<(request: LoginRequest) => Promise<void>>): void => {
  $("#sign-in-btn").on('click', function() {   
    const login_request: LoginRequest = {
      username: <string> $("#psn_id").val(),
      password: <string> $("#password").val()
    } 

    const agreed_terms_conditions = $("#terms-conditions-input").is(":checked")

    let is_valid = true;

    if(agreed_terms_conditions) {
      ValidationUtils.RESET(`label.form-check-label`)
    } else {
      ValidationUtils.INVALIDATE(`label.form-check-label`, 'You must agree to our terms and conditions.')
      is_valid = false;
    }

    if(! is_valid) {
      return;
    }
        
    actions.forEach(handler => {
      handler(login_request)
    })
  })
}

export { ON_SUBMIT }
import Ajv, { _ } from "ajv";

import { CreateEventRequest, Division, Track } from "../../../types"
import AlertUtils from "../../../utils/alert-utils"
import { EventActions, EventConfigurationActions, TrackActions } from "../../actions"
import EventUtils from "../../utils/event-utils"
import LoaderUtils from "../../utils/loader-utils"
import ValidationUtils from "../../utils/validation-utils";
import Modal from "../modal"
import DatePickerUtils from '../../utils/date-picker-utils';

const id = 'create-event-modal'
const title = 'Create Event'
class CreateEventModal extends Modal<void> {

  division: Division
  event_configuration_schemas: Array<any>

  event_configuration_data: any = {}

  constructor(division: Division) {
    super(id, title, undefined)
    this.division = division;
  }

  getTemplate(): string {
    return `
      <div class="modal fade" id="${this.id}" tabindex="-1" role="dialog" aria-labelledby="submit-report-modals" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="submit-report-modals">Create an event</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">         
              <div class="form-group tracks">
                <label>Track</label>
                <div class="sk-three-bounce select-loader loader">
                  <div class="bounce1"></div>
                  <div class="bounce2"></div>
                  <div class="bounce3"></div>
                </div>

                <select class="loaded-content form-control" required>
                    
                </select>
              </div>

              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group date">
                    <label for="users">Start date</label><br/>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="mdi mdi-calendar-month"></i>
                        </span>
                      </div>
                      <input type='text' class="form-control" id='date-picker' placeholder='1/1/2021' />
                    </div>
                    <p style="font-size: 90%">&nbsp;&nbsp;<b>Note:</b> Specify using <u>your</u> timezone.</p>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group time">
                    <label for="time">Start time</label><br/>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="mdi mdi-clock-outline"></i>
                        </span>
                      </div>
                      <input type='text' class="form-control" id='time-picker' placeholder='12:00' />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group description">
                <label for="description">Description</label>
                <textarea class="form-control" id="description" rows="3"></textarea>
              </div>

              <div class="form-group event-configuration-schemas">
                <label>Event Configuration Schema</label>
                <div class="sk-three-bounce select-loader loader">
                  <div class="bounce1"></div>
                  <div class="bounce2"></div>
                  <div class="bounce3"></div>
                </div>

                <select class="loaded-content form-control" required>
                    
                </select>
              </div>

              <div class="event-configuration">
                <div class="loaded-content">

                </div>
              </div>
    
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">Close</button>
              <button id="create-event-btn" type="button" class="btn btn-primary btn-pill">
                <div style="display:none; height: 20px;" class="sk-three-bounce loader">
                  <div style="background-color:white;" class="bounce1"></div>
                  <div style="background-color:white;" class="bounce2"></div>
                  <div style="background-color:white;" class="bounce3"></div>
                </div>
                <span class="loaded-content">Create</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    `
  }

  getModalActions(): void {
    this.getFormData();

    // --- DATE PICKER ---
    let event_date = new Date();

    $(`#${this.id} input#date-picker`).val(`${event_date.getDate()}/${event_date.getMonth() + 1}/${event_date.getFullYear()}`)

    const simplepicker = DatePickerUtils.GET_PICKER();

    simplepicker.reset(event_date)

    EventUtils.REGISTER_CLICK_EVENT(`#${this.id} input#date-picker`, () => { 
      simplepicker.open(); 
    })

    simplepicker.on('submit', (date: Date) => {
      console.log(date)
      $(`#${this.id} input#date-picker`).val(`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`)
      event_date = date;
    });
    // --- DATE PICKER ---

    EventUtils.REMOVE_ALL_EVENTS(`#${this.id} button#create-event-btn`)
    EventUtils.REGISTER_CLICK_EVENT(`#${this.id} button#create-event-btn`, () => {
      let is_valid = true;

      const time = <string> $(`#${this.id} input#time-picker`).val()

      if(time && time.length > 0 && time.match(/^\d{1,2}:\d{1,2}$/)) {
        ValidationUtils.RESET(`#${this.id} input#time-picker`)
      } else {
        ValidationUtils.INVALIDATE(`#${this.id} input#time-picker`, 'Specify hours and minutes. (12:00).')
        is_valid = false;
      }
      
      let hours = 0;
      let minutes = 0;

      if(time.includes(':')) {
        hours = parseInt(time.split(':')[0])
        minutes = parseInt(time.split(':')[1])
      }

      event_date.setHours(hours)
      event_date.setMinutes(minutes)

      const selected_schema_id = <string> $(`#${this.id} .event-configuration-schemas select`).val()
      const configuration_string = JSON.stringify(this.event_configuration_data)
      
      let data = ''

      try {          
        data = JSON.parse(configuration_string);

        ValidationUtils.RESET(`#${this.id} textarea#event-configuration`)
      } catch {
        ValidationUtils.RESET(`#${this.id} textarea#event-configuration`)
        ValidationUtils.INVALIDATE(`#${this.id} textarea#event-configuration`, 'Unable to parse the event configuration, did you miss a comma?')
        is_valid = false;
        return;
      }   

      if(this.event_configuration_schemas && this.event_configuration_schemas.length > 0) {
        
        const schema = this.event_configuration_schemas
          .filter(schema => schema.id === parseInt(selected_schema_id))
          .map(schema => schema.schema)[0]

        delete schema.$schema;
  
        const ajv = new Ajv();
        const validateJsonSchema = ajv.compile(schema, false)            
  
        const isValidSchemaData = validateJsonSchema(data)
  
        if (! isValidSchemaData) {
          const error = validateJsonSchema.errors[0]
          const prefix = error.instancePath === ''
            ? 'Configuration '
            : error.instancePath.replace('/', '') + ' '     

          ValidationUtils.INVALIDATE(`#${this.id} textarea#event-configuration`, `${prefix}${error.message}`)
          is_valid = false;
        } else {
          ValidationUtils.RESET(`#${this.id} textarea#event-configuration`)
        }
      }

      if(! is_valid) {
        return;
      }

      const event: CreateEventRequest = {
        date: event_date.getTime(),
        description: <string> $(`#${this.id} textarea#description`).val(),
        event_configuration: data,
        event_configuration_schema_id: parseInt(selected_schema_id),
        division_id: this.division.id,
        event_id: null,
        track_id: parseInt(<string> $(`#${this.id} .tracks select`).val()),
        stream_url: null
      }

      EventActions.CREATE_EVENT(
        event,  
        () => LoaderUtils.LOADING(`#${this.id} button#create-event-btn`),
        () => {
          // Call onClose to refresh the component behind, but not actually close the modal as a user might want to create another event.
          this.onCloseCallback()

          AlertUtils.NOTIFY('Event was succesfully created.', 'Success!')
          LoaderUtils.LOADED(`#${this.id} button#create-event-btn`, 'Create')
        },
        () => LoaderUtils.LOADED(`#${this.id} button#create-event-btn`, 'Create')
      )
    })
  }

  private getFormData(): void {
    TrackActions.GET_TRACKS(
      this.division.game.id,
      () => {
        LoaderUtils.LOADING(`#${this.id} .tracks`)
      },
      (tracks_response) => {
        const track_content = this.getTracksContent(tracks_response)
        LoaderUtils.LOADED(`#${this.id} .tracks`, track_content)
      }
    )

    EventConfigurationActions.GET_SCHEMA(
      this.division.game.id,
      () => {
        LoaderUtils.LOADING(`#${this.id} .event-configuration-schemas`)
      },
      (event_configuration_schemas_response) => {
        this.event_configuration_schemas = event_configuration_schemas_response;

        const event_configuration_schemas_content = this.getEventConfigurationSchemasContent(event_configuration_schemas_response)
        LoaderUtils.LOADED(`#${this.id} .event-configuration-schemas`, event_configuration_schemas_content)

        const event_configuration_content = this.getEventConfigurationContent(event_configuration_schemas_response[0])
        LoaderUtils.LOADED(`#${this.id} .event-configuration`, event_configuration_content)

        this.registerEventConfigurationHandler(event_configuration_schemas_response[0])
      }
    )
  }
  
  private registerEventConfigurationHandler(configuration_schema: any) {
    const properties = configuration_schema.schema.properties;

    Object.keys(properties).forEach((section: string) => {
      
      const section_definition = properties[section];
      const section_properties = section_definition.properties;

      Object.keys(section_properties).forEach((property_name) => {
        const property = section_properties[property_name];
        const isMultiSelect = property.enum ? true : false

        EventUtils.REMOVE_ALL_EVENTS(`.event-configuration ${isMultiSelect ? 'select' : 'input'}.${this.getSelector(`${section}-${property_name}`)}`)
        EventUtils.REGISTER_CHANGE_EVENT(`.event-configuration ${isMultiSelect ? 'select' : 'input'}.${this.getSelector(`${section}-${property_name}`)}`, (element, event) => {
          this.event_configuration_data[section][property_name] = this.getPropertyValue(property.type, event.target.value)
        })
      })
    })
  }

  private getPropertyValue(type: string, value: any): any {
    if(type === 'number') {
      return parseFloat(value);
    }

    if(type === 'integer') {
      return parseInt(value);
    }

    return <string> value;
  }

  private getEventConfigurationContent(configuration_schema: any): string {
    let content = '';
    const properties = configuration_schema.schema.properties;

    Object.keys(properties).forEach((section: string, index: number) => {
      this.event_configuration_data[section] = {}

      content += `<div class="row ${this.getSelector(section)}">
                    <div class="col-md-12">
                      <div class="modal-header p-1 mb-3">
                        <h6 class="modal-title">${section}</h6>
                      </div>
                    </div>
                  </div>`

      const section_definition = properties[section];
      const section_properties = section_definition.properties;

      Object.keys(section_properties).forEach((property_name, index) => {
        const property = section_properties[property_name];

        if(index % 2 === 0) {
          content += '<div class="row">'
        }

        content += `
          <div class="col-sm-6">
            <div class="form-group">
            <label title="${property_name}" class="event-dynamic-form-label">${property_name}</label><br>
              ${ this.getPropertyInputContent(section, property_name, property) }
            </div>
          </div>
        ` 

        if(index % 2 !== 0 || index === Object.keys(section_properties).length - 1) {
          content += '</div>'
        }
      })
    })


    return content;
  }
  
  private getPropertyInputContent(section: string, property_name: string, property: any): string {
    if(property.enum) {
      let property_options = ''

      property.enum.forEach((option: string) => {
        property_options += `<option value="${option}">${option}</option>`
      })

      // Set default value
      this.event_configuration_data[section][property_name] = property.enum[0]

      return `
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="mdi mdi-numeric"></i>
            </span>
          </div>
          <select class="form-control ${this.getSelector(`${section}-${property_name}`)}">
            ${property_options}
          </select>
        </div>
      `
    }

    if(property.type && property.type === 'integer') {
      
      // Set default value
      this.event_configuration_data[section][property_name] = property.minimum

      return `
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="mdi mdi-numeric"></i>
            </span>
          </div>
          <input min="${property.minimum}" max="${property.maximum}" type="number" placeholder="${property.minimum} - ${property.maximum}" class="form-control ${this.getSelector(`${section}-${property_name}`)}" />
        </div>
      `
    }

    if(property.type && property.type === 'number') {
      
      // Set default value
      this.event_configuration_data[section][property_name] = property.minimum

      const placeholder = `${property.minimum.toFixed(1)} - ${property.maximum.toFixed(1)}`.replace(/\./g, ',')

      return `
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="mdi mdi-numeric"></i>
            </span>
          </div>
          <input min="${property.minimum}" max="${property.maximum}" type="number" placeholder="${placeholder}" class="form-control ${this.getSelector(`${section}-${property_name}`)}" />
        </div>
      `
    }

    if(property.type && property.type === 'string') {
      
      // Set default value
      this.event_configuration_data[section][property_name] = ''

      return `
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="mdi mdi-alphabetical"></i>
            </span>
          </div>
          <input type="text" value="${this.event_configuration_data[section][property_name]}" class="form-control ${this.getSelector(`${section}-${property_name}`)}" />
        </div>
      `
    }
  }

  private getTracksContent(tracks: Array<Track>): string {
    let tracks_content = ''

    tracks.forEach(track => {
      tracks_content += `<option value="${track.id}">${track.flag} ${this.upperCaseWords(track.name)}</option>`
    })

    return tracks_content;
  }

  private getEventConfigurationSchemasContent(event_configuration_schemas: Array<any>): string {
    if(event_configuration_schemas === undefined || event_configuration_schemas.length === 0) {
      return `<option value="-1">None</option>`
    }

    let event_configuration_schemas_content = ''

    event_configuration_schemas.forEach(event_configuration_schema => {
      event_configuration_schemas_content += `<option value="${event_configuration_schema.id}">${event_configuration_schema.name}</option>`
    })

    return event_configuration_schemas_content;
  }

  private getSelector(text: string) {
    return text.toLowerCase().replace(/\s|\(|\)|\/|&/g, '_')
  }

  private upperCaseWords(text: string): string {
    const words = text.split(" ");

    return words.map((word) => { 
        return word[0].toUpperCase() + word.substring(1); 
    }).join(" ");
  }

  
}

export default CreateEventModal
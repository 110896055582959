import { LeagueActions } from '../actions'
import EventUtils from '../utils/event-utils'
import LoaderUtils from '../utils/loader-utils'


const ON_PAGE_LOAD = (): void => {
  EventUtils.REGISTER_CLICK_EVENT(
    `#create-league-btn`, 
    () => {
      const name = getInputValue('input#name')
      const code = getInputValue('input#code')
      const description = getInputValue('input#description')
      const discord = getInputValue('input#discord')
      const youtube = getInputValue('input#youtube')
      const twitch = getInputValue('input#twitch')
      const facebook = getInputValue('input#facebook')
      const instagram = getInputValue('input#instagram')
      const twitter = getInputValue('input#twitter')
      const slack = getInputValue('input#slack')

      LeagueActions.CREATE(
        {
          name: name,
          code: code,
          description: description,
          discord_url: discord,
          youtube_url: youtube,
          twitch_url: twitch,
          facebook_url: facebook,
          instagram_url: instagram,
          twitter_url: twitter,
          slack_url: slack
        },
        () => { LoaderUtils.LOADING("#create-league-btn") },
        () => { 
          LoaderUtils.LOADED("#create-league-btn", "Create") 
          // Set active league in local storage?
          window.location.href = "/"
        },
        () => { LoaderUtils.LOADED("#create-league-btn", "Create") }
      )
    }
  )
}

function getInputValue(selector: string) {
  const value = <string> $(selector).val();
  return isEmpty(value) ? null : value;
}

function isEmpty(value: string) {
  return (!value || value.length === 0 );
}


export { ON_PAGE_LOAD }
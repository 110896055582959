const IS_AUTHENTICATED = (): boolean => {
  return localStorage.getItem('is_authenticated') === 'true';
}

const SET_IS_AUTHENTICATED = (is_authenticated: boolean): void => {
  localStorage.setItem('is_authenticated', is_authenticated.toString());
}

const SET_ACCESS_TOKEN = (access_token: string): void => {
  localStorage.setItem('access_token', access_token);
}

const ACCESS_TOKEN = (): string => {
  return localStorage.getItem('access_token');
}

const ACTIVE_LEAGUE = (): number => {
  const value = localStorage.getItem('active_league_id')
  return value === null ? null : parseInt(value);
}

const SET_ACTIVE_LEAGUE = (league_id: number): void => {
  localStorage.setItem('active_league_id', league_id.toString());
}

const ACTIVE_DIVISION = (): number => {
  const value = localStorage.getItem('active_division_id')
  return value === null ? null : parseInt(value);
}

const SET_ACTIVE_DIVISION = (division_id: number): void => {
  localStorage.setItem('active_division_id', division_id.toString());
}

const UNSET_ACTIVE_DIVISION = (): void => {
  localStorage.removeItem('active_division_id')
}

const USER_AUTHORIZED_SERVICES = (): any => {
  return localStorage.getItem('authorized_services');
}

const SET_USER_ID = (user_id: number): void => {
  localStorage.setItem('user_id', user_id.toString());
}

const USER_ID = (): number => {
  const value = localStorage.getItem('user_id')
  return value === null ? null : parseInt(value);
}

const SET_USER_AUTHORIZED_SERVICES = (value: string): void => {
  return localStorage.setItem('authorized_services', value);
}

export default { SET_IS_AUTHENTICATED, IS_AUTHENTICATED, SET_ACCESS_TOKEN, ACCESS_TOKEN, ACTIVE_LEAGUE, SET_ACTIVE_LEAGUE, ACTIVE_DIVISION, SET_ACTIVE_DIVISION, UNSET_ACTIVE_DIVISION, USER_AUTHORIZED_SERVICES, SET_USER_ID, USER_ID, SET_USER_AUTHORIZED_SERVICES }
import { ResultEntry } from "../../types";
import ResultUtils from "../utils/result-utils";

abstract class ResultComponent<R extends ResultEntry> {
    
  container_selector: string

  title: string
  event_id: number

  result_content_function: (event_id: number, callback: (results: Array<R>) => void) => void = () => {}

  constructor(container_selector: string, title: string, event_id: number, result_content_function: (event_id: number, allback: (results: Array<R>) => void) => void = () => {}) {
    this.container_selector = container_selector;
    
    this.title = title
    this.event_id = event_id;

    this.result_content_function = result_content_function;
  }

  render(): void {
    this.result_content_function(
      this.event_id,
      (results) => {
        const content = this.getContent(results);
        $(this.container_selector).html(content);
      }
    )
  }

  getContent(results: Array<R>): string {
    return `
      <div class="heading-section ftco-animate fadeInUp ftco-animated">
        <span class="subheading">${this.title}</span>
        <h2 class="mb-4"></h2>
      </div>
      <div class="scoreboard mb-lg-3">
        <table class="race-leaderboard leaderboard" id="race-leaderboard">
          ${ this.getRowContent(results) }
        </table>
      </div>
    `
  }

  getRowContent(results: Array<R>): string {
    if(results.length === 0) {
      return `<tr><td style="color:#C8C8C8;">NOT AVAILABLE YET.</td></tr>`
    }

    let content = ''

    const winner = results.find(result => result.finish_position === 1);

    results.forEach((result) => {
      content += `
        <tr class="driver">
          <td style="padding-right: 5px;" class="position"><span class="color-dark">${result.finish_position}</span> </td>
          <td class="driver" style="border-left: 4px solid ${ ResultUtils.GET_COLOR_BY_TEAM(result) };">
            <a href="#/results" class="clickable-username color-dark">${result.user.username}</a>
            <span>${ ResultUtils.GET_CONSTRUCTOR(result) }</span>
          </td>
          <td class="gap">
            <span>${ this.getResultGap(winner, result) }</span>
          </td>
          <td class="pts ${this.hasFastestLap(result) ? 'fastest-lap' : ''}"><span>${result.points}</span></td>
        </tr>
      `
    })

    return content;
  }

  abstract getResultGap(winner: R, entry: R): string

  abstract hasFastestLap(entry: R) : boolean
}

export default ResultComponent;
import { Division, Event, PointDistribution, PositionPointDistribution, RaceResultEntry, ResultType, User } from "../../../types";
import RaceResultCardComponent from "../../components/card/race-result-card-component";
import EventUtils from "../../utils/event-utils";
import TimeUtils from "../../utils/time-utils";
import SubmitResultEntryModal from "./submit-result-entry-modal";

const FINISHED_RESULT_TYPE = 1;

class SubmitRaceResultEntryModal extends SubmitResultEntryModal<RaceResultEntry> {
    
  event: Event
  point_distribution: PointDistribution;
  origin_card: RaceResultCardComponent

  constructor(division: Division, event: Event, point_distribution: PointDistribution, entry: RaceResultEntry, result_types: (loading_callback: () => void) => JQuery.jqXHR<any>, origin_card?: RaceResultCardComponent) {
    super(division, result_types, entry, origin_card)
    this.event = event;
    this.point_distribution = point_distribution;
  }

  getResultSpecifics(result_type: ResultType): string {
    this.local_entry.points = this.local_entry?.points
      ? this.local_entry.points
      : this.getPointsFromDistribution(this.origin_card.items.length + 1)

    console.log(this.local_entry.points)

    return `
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group grid-starting-position">
            <label for="submit-race-result-entry-grid-starting-position">Grid Starting Position</label><br>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="mdi mdi-numeric"></i>
                </span>
              </div>
              <input min="1" type="number" class="form-control" placeholder="4"
                ${ this.getStartingPosition() }>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group total-laps-completed">
            <label>Total Laps Completed</label><br>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="mdi mdi-numeric"></i>
                </span>
              </div>
              <input min="0" type="number" class="form-control" placeholder="23"
                ${ this.local_entry ? `value="${this.local_entry.laps}"` : ''}>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="form-group total-race-time">
            <label>Total Race Time</label><br>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="mdi mdi-timer"></i>
                </span>
              </div>
              <input autocomplete="off" type="text" placeholder="35:12.901" class="form-control" 
                ${ this.getTotalRaceTimeValueState(result_type) }>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group total-race-time-offset">
            <label>&nbsp;</label><br>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="mdi mdi-plus"></i>
                </span>
              </div>
              <input autocomplete="off" type="text" placeholder="00:00.000" class="form-control"
                ${ this.getTotalRaceTimeOffsetValueState(result_type) }>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="form-group stops">
            <label>Stops</label><br>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="mdi mdi-car-brake-alert"></i>
                </span>
              </div>
              <input min="0" type="number" class="form-control" placeholder="2"
                ${ this.local_entry ? `value="${this.local_entry?.stops}"` : ''}>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group fastest-lap-time" >
            <label>Fastest Lap</label><br>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="mdi mdi-timetable"></i>
                </span>
              </div>
              <input autocomplete="off" type="text" placeholder="01:12.901" class="form-control"
                ${ this.hasFastestLapTime() ? `value="${TimeUtils.GET_FORMATTED_TIME(this.local_entry.fastest_lap_time)}"` : ''}>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="form-group points">
            <label>Points</label><br>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="mdi mdi-car-brake-alert"></i>
                </span>
              </div>
              <input min="0" type="number" class="form-control" placeholder="0"
                ${ this.local_entry?.points ? `value="${this.local_entry?.points}"` : '' }
            </div>
          </div>
        </div>
      </div>
    `
  }

  onSubmit(): void {
    if(this.isLeader()) {
      const previous_leader = { ...this.entry };
      const current_leader = { ...this.local_entry }

      this.origin_card.items.forEach(
        item => this.reCalculateTotalRaceTime(previous_leader, current_leader, item)
      )
    }

    if(! this.isLeader() && ! this.isLapped() && this.local_entry.result_type.id == FINISHED_RESULT_TYPE && this.local_entry.total_race_time === 0) {
      this.entry.total_race_time = this.getLeaderEntry().total_race_time
    } else {
      this.entry.total_race_time = this.local_entry.total_race_time
    }

    this.entry.finish_position = this.entry ? this.origin_card.items.indexOf(this.entry) + 1 : this.origin_card.items.length 
    this.entry.start_position = this.local_entry.start_position
    this.entry.laps = this.local_entry.laps
    this.entry.stops = this.local_entry.stops
    this.entry.fastest_lap_time = this.local_entry.fastest_lap_time
    this.entry.fastest_lap = false
    this.entry.points = this.local_entry.points | 0
    this.entry.team = this.local_entry.team
    this.entry.vehicle = this.local_entry.vehicle

    this.origin_card.resetPoints();
    this.origin_card?.refresh()
  }

  private reCalculateTotalRaceTime(previous_leader: RaceResultEntry, current_leader: RaceResultEntry, entry: RaceResultEntry): void {
    if(current_leader.user.id === entry.user.id) return;

    const previous_offset = entry.total_race_time - previous_leader.total_race_time;
    
    entry.total_race_time = current_leader.total_race_time + previous_offset;
  }

  private getTotalRaceTimeValueState(result_type: ResultType) {
    if(result_type.id !== FINISHED_RESULT_TYPE || this.isLapped()) {
      return 'value="00:00.000" disabled'
    }

    if(this.hasLeader()) {
      return `value="${TimeUtils.GET_FORMATTED_TIME(this.getLeaderEntry().total_race_time)}" ${ this.isLeader() ? '' : 'disabled' }`
    }

    return '';
  }

  private getTotalRaceTimeOffsetValueState(result_type: ResultType) {
    if(result_type.id !== FINISHED_RESULT_TYPE || this.isLeader() || this.isLapped()) {
      return 'value="00:00.000" disabled'
    }

    if(this.hasLeader() && ! this.isLeader() && this.hasLapsCompleted() && this.hasTotalRaceTime()) {
      return `value="${TimeUtils.GET_FORMATTED_TIME(this.local_entry.total_race_time - this.getLeaderEntry().total_race_time)}"`
    }

    return '';
  }

  reload(): void {
    const result_type_id = <string> $(`#${this.id} .result-types select`).val()

    if(this.local_entry.points === undefined) {
      $(`#${this.id} .points input`).val(
        this.getPointsFromDistribution(this.local_entry.finish_position)
      );
  
      this.local_entry.points = this.getPointsFromDistribution(this.local_entry.finish_position)
    }

    if(this.isLapped() || parseInt(result_type_id) !== FINISHED_RESULT_TYPE) {
      $(`#${this.id} .total-race-time input`).val('00:00.000')
      $(`#${this.id} .total-race-time input`).prop('disabled', true);
      $(`#${this.id} .total-race-time-offset input`).val('00:00.000')
      $(`#${this.id} .total-race-time-offset input`).prop('disabled', true);

      this.local_entry.total_race_time = 0;
      return;
    }
    
    if(! this.isLeader() && ! this.isLapped()) {
      $(`#${this.id} .total-race-time input`).val(
        TimeUtils.GET_FORMATTED_TIME(this.getLeaderEntry().total_race_time)
      )
      $(`#${this.id} .total-race-time input`).prop('disabled', true);


      if(this.hasTotalRaceTime()) {
        $(`#${this.id} .total-race-time-offset input`).val(TimeUtils.GET_FORMATTED_TIME(this.local_entry.total_race_time - this.getLeaderEntry().total_race_time))
      } else {
        $(`#${this.id} .total-race-time-offset input`).val('')
      }

      $(`#${this.id} .total-race-time-offset input`).prop('disabled', false);
      return;
    }
  }

  private isLapped() {
    return this.getLeaderEntry()?.laps > this.local_entry?.laps;
  }

  private hasLeader() {
    return this.getLeaderEntry();
  }

  private hasLapsCompleted() {
    return this.local_entry?.laps
  }

  private hasTotalRaceTime() {
    return this.local_entry?.total_race_time
  }

  private hasFastestLapTime() {
    return this.local_entry?.fastest_lap_time
  }

  private isLeader() {
    return this.getLeaderEntry() === undefined || this.getLeaderEntry().finish_position === this.local_entry?.finish_position;
  }

  private getLeaderEntry(): RaceResultEntry {
    return this.origin_card?.items.find(item => item.finish_position === 1)
  }

  registerSpecificChangeListeners(): void {
    EventUtils.REGISTER_CHANGE_EVENT(`#${this.id} .total-laps-completed input`, (selector, event) => {
      this.local_entry.laps = parseInt(event.target.value)
      this.reload()
    })

    EventUtils.REGISTER_CHANGE_EVENT(`#${this.id} .fastest-lap-time input`, (selector, event) => {
      this.local_entry.fastest_lap_time = TimeUtils.PARSE_FORMATTED_TIME(
        event.target.value
      )
    })

    EventUtils.REGISTER_CHANGE_EVENT(`#${this.id} .points input`, (selector, event) => {
      this.local_entry.points = parseInt(event.target.value)
    })

    EventUtils.REGISTER_CHANGE_EVENT(`#${this.id} .stops input`, (selector, event) => {
      this.local_entry.stops = parseInt(event.target.value)
    })

    EventUtils.REGISTER_CHANGE_EVENT(`#${this.id} .grid-starting-position input`, (selector, event) => {
      this.local_entry.start_position = parseInt(event.target.value)
    })

    EventUtils.REGISTER_CHANGE_EVENT(`#${this.id} .total-race-time input`, (selector, event) => {
      if(this.isLeader()) {
        this.local_entry.total_race_time = TimeUtils.PARSE_FORMATTED_TIME(event.target.value)
      }
    })

    EventUtils.REGISTER_CHANGE_EVENT(`#${this.id} .total-race-time-offset input`, (selector, event) => {
      this.local_entry.total_race_time = this.getLeaderEntry().total_race_time + TimeUtils.PARSE_FORMATTED_TIME(event.target.value)
    })
  }

  private getStartingPosition(): string {
    if(this.local_entry && this.entry && this.entry.start_position) {
      return `value="${this.local_entry.start_position}"`
    }

    if(this.local_entry && this.local_entry.user && this.event.qualifying_results && this.event.qualifying_results.length > 0) {
      const quali_result = this.event.qualifying_results[0].find(result => result.user.id === this.local_entry.user.id)

      if(quali_result) {
        this.local_entry.start_position = quali_result.finish_position
        return `value="${quali_result.finish_position}"`
      }
    }

    return ''
  }

  private getPointsFromDistribution(position: number): number {
    if(this.point_distribution === undefined) return 0;

    if(position > Object.keys(this.point_distribution.points).length) {
      return 0;
    }

    return (<Array<PositionPointDistribution>> Object.values(this.point_distribution.points))[position - 1].points
  }
}

export default SubmitRaceResultEntryModal
import { Division, Event } from "../../types";
import { EventActions } from "../actions";
import EventCardComponent, { EventListItemActions } from "../components/card/event-card-component";
import ListComponent from "../components/list/list-component";

const ON_PAGE_LOAD = (active_division: Division): void => {
  const LC = new ListComponent(
    '#index-first-row', 
    active_division, 
    EventCardComponent,
    [ EventListItemActions.VIEW, EventListItemActions.NEGATIVE_SIGN_UP, EventListItemActions.POSITIVE_SIGN_UP, EventListItemActions.CANCELLED_SIGN_UP, EventListItemActions.ENTERED_SIGN_UP, EventListItemActions.REFRESH_SIGN_UP ], 
    (division_id: number, loading_callback: () => void, callback: (content: Array<Event>) => void) => {
      EventActions.GET_EVENTS(division_id, loading_callback, (content: Array<Event>) => {
        const first_upcoming_event = content.find(event => event.date > new Date().getTime())
        
        if(first_upcoming_event) {
          EventActions.GET_EVENT_SIGN_UPS(first_upcoming_event.id, () => {}, (signups) => {
            first_upcoming_event.event_signups = signups;
  
            callback(content);
          })
        } else {
          callback(content);
        } 
      })
    }
  )
  
  LC.render()
}

export { ON_PAGE_LOAD }
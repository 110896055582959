import { Division, Report } from "../../../types";
import { ReportActions } from "../../actions";
import { RowAction, CardAction } from "./card-types";

import CardComponent from "./card-component";

import EventUtils from "../../utils/event-utils";

import ReportDetailsModal, { ReportDetailPanel } from "../../modals/report/report-details-modal";
import SubmitAdditionalInfoModal from "../../modals/report/submit-additional-info-modal";
import SubmitVoteModal from "../../modals/report/submit-vote-modal";
import SubmitPenaltyModal from "../../modals/report/submit-penalty-modal";
import ConfirmationModal from "../../modals/confirmation-modal";
import ServePenaltyModal from "../../modals/report/serve-penalty-modal";

export type ReportRowActions = {
  DETAILS: RowAction
  ADDITIONAL_INFO: RowAction
  VOTE: RowAction
  PENALIZE: RowAction
  SERVE: RowAction
  DELETE: RowAction
}

class ReportCardComponent extends CardComponent<Report> {

  division: Division

  constructor(container_selector: string, id: string, title: string, color: string, full_width = false, division: Division,
    content_function: (division_id: number, callback: (content: Array<Report>) => void) => void = () => {}, 
    alt_content_function: (division_id: number, callback: (content: Array<Report>) => void) => void = () => {},
    switcher_values: Array<string> = [], row_actions: Array<RowAction> = [], alt_row_actions: Array<RowAction> = [],
    card_actions: Array<CardAction> = []) {
    
    super(container_selector, id, title, color, full_width, true, content_function, alt_content_function, switcher_values, row_actions, alt_row_actions, card_actions)

    this.division = division;
  }

  getCardContent(reports: Array<Report>, color: string, row_actions: Array<RowAction>): string {
    let card_content = ''

    reports.reverse().forEach(report => {
        card_content += `
          <div class="media py-3 align-items-center justify-content-between" data-id="${report.id}">
            <div class="d-flex rounded-circle align-items-center justify-content-center mr-3 media-icon iconbox-45 bg-${color} text-white">
              <i class="mdi mdi-file-document-multiple-outline font-size-20"></i>
            </div>
            <div style="overflow: hidden; white-space: nowrap;" class="media-body pr-3 ">
              <a class="mt-0 mb-1 font-size-15 text-dark"><span style="color: gray;">(${report.id})</span> ${report.reporter_user.username}</a>
              <p style="text-overflow: ellipsis; overflow: hidden;">${report.description}</p>
            </div>
            <span style="text-align: right;" class="font-size-12 report-track-and-time">
              <i style="float: right; position: relative; margin-left: -20px;" class="mdi mdi-clock-outline"></i> 
              <span style="display: block; width: 100%; padding-right: 25px;" >${report.track.name}</span><span style="display: block; width: 100%; padding-right: 25px;">${this.division.name}</span>
            </span>
            <span class="report-actions ${color}">
              ${ this.getRowActionContent(report, row_actions) }
            </span>
          </div>
        `
    })

    if(reports.length === 0) {
        card_content = `
        <div style="text-align: center;">
            NO REPORTS FOUND
        </div>
        `
    }

    return card_content;
  }

  setRowEvents(selector: string, report: Report, row_actions: Array<RowAction>): void {
    if(row_actions.includes(RowActions.DETAILS)) {
      EventUtils.REGISTER_CLICK_EVENT(`${selector} .${RowActions.DETAILS.class}[data-id="${report.id}"]`, () => new ReportDetailsModal(report).render())  
    }

    if(row_actions.includes(RowActions.ADDITIONAL_INFO)) {
      EventUtils.REGISTER_CLICK_EVENT(`${selector} .${RowActions.ADDITIONAL_INFO.class}[data-id="${report.id}"]`, () => new SubmitAdditionalInfoModal(report, this).render())  
    }

    if(row_actions.includes(RowActions.VOTE)) {
      EventUtils.REGISTER_CLICK_EVENT(`${selector} .${RowActions.VOTE.class}[data-id="${report.id}"]`, () => new SubmitVoteModal(this.division, report, this).render())  
    }

    if(row_actions.includes(RowActions.PENALIZE)) {
      EventUtils.REGISTER_CLICK_EVENT(`${selector} .${RowActions.PENALIZE.class}[data-id="${report.id}"]`, () => new SubmitPenaltyModal(this.division, report, this).render())  
    }

    if(row_actions.includes(RowActions.SERVE)) {
      EventUtils.REGISTER_CLICK_EVENT(`${selector} .${RowActions.SERVE.class}[data-id="${report.id}"]`, () => {
        new ServePenaltyModal(this.division, report, this).render()
      })  
    }

    if(row_actions.includes(RowActions.DELETE)) {
      EventUtils.REGISTER_CLICK_EVENT(`${selector} .${RowActions.DELETE.class}[data-id="${report.id}"]`, () => 
        new ConfirmationModal(
          `Are you sure you want to delete report <b>#${report.id}</b>?`,
          () => {
            ReportActions.DELETE_REPORT(
              report.id,
              () => {},
              () => this.reload()
            )
          }
        ).render()
      )
    }
  }
}

export const RowActions: ReportRowActions = {
  DETAILS: {
    name: 'DETAILS',
    class: 'mdi-text',
    service: -1,
  },
  ADDITIONAL_INFO: {
    name: 'ADDITIONAL_INFO',
    class: 'mdi-chat-plus',
    service: -1,
  },
  VOTE: {
    name: 'VOTE',
    class: 'mdi-vote',
    service: 15,
  },
  PENALIZE: {
    name: 'PENALIZE',
    class: 'mdi-police-badge-outline',
    service: 16
  },
  SERVE: {
    name: 'SERVE',
    class: 'mdi-gavel',
    service: 19
  },
  DELETE: {
    name: 'DELETE',
    class: 'mdi-close',
    service: 22
  }
}

export default ReportCardComponent
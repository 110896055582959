import { TimeTrialEvent, TimeTrialEventEntry, User } from "../../../types";
import { TimeTrialActions } from "../../actions";

import AlertUtils from "../../../utils/alert-utils";
import EventUtils from "../../utils/event-utils";
import LoaderUtils from "../../utils/loader-utils";
import ValidationUtils from "../../utils/validation-utils";

import Modal from "../modal";
import CardComponent from "../../components/card/card-component";
import TimeUtils from "../../utils/time-utils";

const id = 'submit-time-trial-event-entry-modal'
const title = 'Submit Time Trial Entry'

class SubmitTimeTrialEventEntryModal extends Modal<void> {

  origin_card?: CardComponent<TimeTrialEventEntry>

  constructor(origin_card?: CardComponent<TimeTrialEventEntry>) {
    super(id, title, undefined)
    this.origin_card = origin_card;
  }

  getTemplate(): string {
    TimeTrialActions.GET_EVENTS(
      () => LoaderUtils.LOADING(`#${this.id} .events`),
      (events_response) => {
        const events_content = this.getEventsContent(events_response)
        LoaderUtils.LOADED(`#${this.id} .events`, events_content)
      }
    )

    return `
      <div class="modal fade" id="${this.id}" tabindex="-1" role="dialog" aria-labelledby="${this.id}" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">${this.title}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group events">
                <label>Event</label>
                <div class="sk-three-bounce select-loader loader">
                  <div class="bounce1"></div>
                  <div class="bounce2"></div>
                  <div class="bounce3"></div>
                </div>

                <select class="loaded-content form-control" required>
                    
                </select>
              </div>

              <div class="form-group time">
                <label>Time</label><br/>
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="mdi mdi-timetable"></i>
                    </span>
                  </div>
                  <input autocomplete="off" type="text" placeholder="01:12.901" class="form-control" />
                </div>
              </div>

              <div class="form-group media-link">
                <label>Video link</label><br/>
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="mdi mdi-movie-open-outline"></i>
                    </span>
                  </div>
                  <input type="text" class="form-control" placeholder="" aria-label="">
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">Close</button>
              <button id="submit-time-trial-event-entry" type="button" class="btn btn-primary btn-pill">
                <div style="display:none; height: 20px;" class="sk-three-bounce loader">
                  <div style="background-color:white;" class="bounce1"></div>
                  <div style="background-color:white;" class="bounce2"></div>
                  <div style="background-color:white;" class="bounce3"></div>
                </div>
                <span class="loaded-content">Submit</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    `
  }

  getModalActions(): void  {
    EventUtils.REMOVE_ALL_EVENTS(`#${this.id} button#submit-time-trial-event-entry`)
    
    EventUtils.REGISTER_CLICK_EVENT(`#${this.id} button#submit-time-trial-event-entry`, () => {
      const event_id = <number> $(`#${this.id} .events .loaded-content`).val()
      const time = <string> $(`#${this.id} .time input`).val()
      const media_link = <string> $(`#${this.id} .media-link input`).val()

      let is_valid = true;

      if(media_link !== undefined && media_link !== '' && (media_link.startsWith('http://') || media_link.startsWith('https://'))) {
        ValidationUtils.RESET(`#${this.id} .media-link input`)
      } else {
        ValidationUtils.INVALIDATE(`#${this.id} .media-link input`, 'Please provide a valid url.')
        is_valid = false;
      }

      if(! is_valid) {
        return;
      }

      const entry = {
        time_trial_event_id: event_id,
        media_link: media_link,
        time: TimeUtils.PARSE_FORMATTED_TIME(time)
      }

      TimeTrialActions.SUBMIT_EVENT_ENTRY(
        entry,
        () => { LoaderUtils.LOADING(`#${this.id} button#submit-time-trial-event-entry`) },
        () => { 
          this.close()
          AlertUtils.NOTIFY('Entry was succesfully submitted.', 'Success!')
          LoaderUtils.LOADED(`#${this.id} button#submit-time-trial-event-entry`, 'Submit')
          
          if(this.origin_card) this.origin_card.reload()
        }
      )
    })
  }

  private getEventsContent(events: Array<TimeTrialEvent>) {
    let event_content = ''

    events.forEach(event => {
      event_content += `<option value="${event.id}">${event.title} (${event.track.name})</option>`
    })

    return event_content;
  }
}

export default SubmitTimeTrialEventEntryModal
import { LocalDate } from '../../types';

export interface PARSED_TIME {
  hours: string,
  minutes: string,
  seconds: string,
  milliseconds: string
}

const PARSE_EPOCH = (epoch_ms: number): LocalDate => {
  const date = new Date(epoch_ms);

  const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
  ]

  const monthIndex = date.getMonth()
  const month = months[monthIndex]

  const weekdays = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat'
]

const weekdayIndex = date.getDay()
const weekday = weekdays[weekdayIndex]

  return {
    year: date.getFullYear(),
    month: {
      name: month,
      number: monthIndex + 1,
    },
    day: {
      name: weekday,
      number: date.getDate()
    },
    hour: date.getHours(),
    minute: date.getMinutes(),
    second: date.getSeconds()

  }
}

const PARSE_MS = (milliseconds: number): PARSED_TIME => {
  let hours, minutes, seconds: number;
  seconds = Math.floor(milliseconds / 1000);
  minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;
  hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  hours = hours % 24;
  milliseconds = milliseconds % 1000

  const milliseconds_string = (milliseconds < 100 && milliseconds < 10) ? "00" + milliseconds : (milliseconds < 100 && milliseconds > 10) ? "0" + milliseconds : milliseconds;
  const seconds_string = (seconds < 10) ? "0" + seconds : seconds;
  const minutes_string = (minutes < 10) ? "0" + minutes : minutes;
  const hours_string = (hours < 10) ? "0" + hours : hours;

  return {
    hours: hours_string.toString(),
    minutes: minutes_string.toString(),
    seconds: seconds_string.toString(),
    milliseconds: milliseconds_string.toString()
  };
}

const GET_FORMATTED_TIME = (milliseconds: number): string => {
  const parsed = PARSE_MS( isNaN(milliseconds) || milliseconds <= 0 ? 0 : milliseconds)
  return `${parsed.hours === '00' ? '' : `${parsed.hours}:`}${parsed.minutes === '00' ? '' : `${parsed.minutes}:`}${parsed.seconds}.${parsed.milliseconds}`
}

const PARSE_FORMATTED_TIME = (value: string): number => {
  if (value === '0') {
    return 0;
  }

  let hours = 0;
  let mins = 0;
  let secs = 0;

  // Appareantly no `:`
  if (value.split(':').length === 1) {
    mins = 0
    secs = Number(value.split('.')[0])
  }

  if (value.split(':').length === 2) {
    mins = Number(value.split(':')[0])
    secs = Number(value.split(':')[1].split('.')[0])
  }

  if (value.split(':').length > 2) {
    hours = Number(value.split(':')[0])
    mins = Number(value.split(':')[1])
    secs = Number(value.split(':')[2].split('.')[0])
  }

  let result = (((hours * 60 * 60) + (mins * 60) + secs) * 1000)

  if (value.includes(".")) {
    const ms = Number(value.split('.')[1])
    result = result + ms;
  }

  return result;
}

export default {
  PARSE_EPOCH, PARSE_MS, PARSE_FORMATTED_TIME, GET_FORMATTED_TIME
}
import * as Toastr from 'toastr'

const NOTIFY = (long: string, short: string, status = "success"): void => {    
  Toastr.options.closeButton = true
  Toastr.options.debug = false
  Toastr.options.newestOnTop = false
  Toastr.options.progressBar = true
  Toastr.options.positionClass = "custom-toast-top-right toast-top-right"
  Toastr.options.preventDuplicates = false
  Toastr.options.onclick = null
  Toastr.options.showDuration = 300
  Toastr.options.hideDuration = 1000
  Toastr.options.timeOut = 5000
  Toastr.options.extendedTimeOut = 1000
  Toastr.options.showEasing = "swing"
  Toastr.options.hideEasing = "linear"
  Toastr.options.showMethod = "fadeIn"
  Toastr.options.hideMethod = "fadeOut"
  
  if(status === "success") {
    Toastr.success(long, short);
  }

  if(status === "error") {
    Toastr.error(long, short);
  }
}
      
export default { NOTIFY }
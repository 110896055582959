import { Division, Game, Platform } from "../../../types";
import { DivisionActions, GameActions } from "../../actions";

import AlertUtils from "../../../utils/alert-utils";
import EventUtils from "../../utils/event-utils";
import LoaderUtils from "../../utils/loader-utils";

import Modal from "../modal";

const id = 'create-division-modal'
const title = 'Create a division'

class DivisionModal extends Modal<Division> {

  constructor(division: Division = undefined) {
    super(id, title, division)
  }

  getTemplate(division: Division): string {
    return `
      <div class="modal fade" id="${this.id}" tabindex="-1" role="dialog" aria-labelledby="submit-report-modals" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="submit-report-modals">${this.title}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">         
               <div class="form-group consoles">
                  <label>Platform</label>
                  <div class="sk-three-bounce select-loader loader">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                  </div>

                  <select class="loaded-content form-control" required ${division !== undefined ? 'disabled' : ''}>
                      
                  </select>
                </div>

                <div class="form-group games">
                  <label>Game</label>
                  <div class="sk-three-bounce select-loader loader">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                  </div>

                  <select class="loaded-content form-control" required ${division !== undefined ? 'disabled' : ''}>
                      
                  </select>
                </div>

                <div class="form-group name">
                  <label for="name">Name</label><br/>
                  <div class="input-group mb-2">
                    <input type="text" ${division === undefined ? '' : `value="${division.name}"`} class="form-control" placeholder="" aria-label="">
                  </div>
                  <p style="font-size: 90%">&nbsp;&nbsp;<b>Note:</b> Provide the <u>name</u> of the division.</p>
                </div>

                <div class="form-group code">
                  <label for="code">Code</label><br/>
                  <div class="input-group mb-2">
                    <input type="text" ${division === undefined ? '' : `value="${division.code}"`} class="form-control" placeholder="" aria-label="">
                  </div>
                  <p style="font-size: 90%">&nbsp;&nbsp;<b>Note:</b> Provide the <u>code</u> of the division.</p>
                </div>
              </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">Close</button>
              <button id="division-btn" type="button" class="btn btn-primary btn-pill">
                <div style="display:none; height: 20px;" class="sk-three-bounce loader">
                  <div style="background-color:white;" class="bounce1"></div>
                  <div style="background-color:white;" class="bounce2"></div>
                  <div style="background-color:white;" class="bounce3"></div>
                </div>
                <span class="loaded-content">${division === undefined ? 'Create' : 'Save' }</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    `
  }

  getModalActions(division: Division): void  {
    this.getFormData(division)

    EventUtils.REMOVE_ALL_EVENTS(`#${this.id} button#division-btn`)

    EventUtils.REGISTER_CLICK_EVENT(`#${this.id} button#division-btn`, () => {
      const games = <string> $(`#${this.id} .games .loaded-content`).val()
      const name = <string> $(`#${this.id} .name input`).val()
      const code = <string> $(`#${this.id} .code input`).val()

      const division_request = {
        game_id: parseInt(games),
        name: name,
        code: code
      }

      if(division === undefined) {
        DivisionActions.CREATE_DIVISION(
          division_request,
          () => { LoaderUtils.LOADING(`#${this.id} button#division-btn`) },
          () => { 
            this.close()
            AlertUtils.NOTIFY('Division was succesfully created.', 'Success!')
            LoaderUtils.LOADED(`#${this.id} button#division-btn`, 'Create')
          },
          () => { LoaderUtils.LOADED(`#${this.id} button#division-btn`, 'Create') }
        )
      } else {
        DivisionActions.EDIT_DIVISION(
          division.id,
          division_request,
          () => { LoaderUtils.LOADING(`#${this.id} button#division-btn`) },
          () => { 
            this.close()
            AlertUtils.NOTIFY('Division was succesfully updated.', 'Success!')
            LoaderUtils.LOADED(`#${this.id} button#division-btn`, 'Save')
          },
          () => { LoaderUtils.LOADED(`#${this.id} button#division-btn`, 'Save') }
        )
      }      
    })
  }

  private getFormData(division: Division): void {
    GameActions.GET_GAMES(
      () => {
        LoaderUtils.LOADING(`#${this.id} .consoles`)
        LoaderUtils.LOADING(`#${this.id} .games`)
      },
      (games_response) => {
        const platforms = games_response.map(item => item.console)
        const unique_platforms: Array<Platform> = Array.from(
          new Set(
            platforms.map(p => JSON.stringify(p))
          )
        )
        .map(string => JSON.parse(string))

        const active_platform = division === undefined ? unique_platforms[0] : division.game.console
        const platform_content = this.getPlatformContent(unique_platforms, active_platform);

        LoaderUtils.LOADED(`#${this.id} .consoles`, platform_content)

        const active_game = division === undefined ? games_response.filter(game => game.console.id === active_platform.id)[0] : division.game
        const games_content = this.getGamesContent(games_response, unique_platforms[0], active_game)
        LoaderUtils.LOADED(`#${this.id} .games`, games_content)

        EventUtils.REGISTER_CHANGE_EVENT(`#${this.id} .consoles`, (element, event) => {
          LoaderUtils.LOADING(`#${this.id} .games`)
          const active = platforms.find(platform => platform.id === parseInt(event.target.value))
          LoaderUtils.LOADED(`#${this.id} .games`, this.getGamesContent(games_response, active, games_response.filter(game => game.console.id === active_platform.id)[0]))
        })
      }
    )
  }

  private getPlatformContent(platforms: Array<Platform>, active_platform: Platform) {
    let platform_content = ''

    platforms.forEach(platform => {
        platform_content += `<option ${active_platform.id === platform.id ? 'selected' : ''} value="${platform.id}">${platform.name}</option>`
    })

    return platform_content;
  }

  private getGamesContent(games: Array<Game>, platform: Platform, active_game: Game): string {
    let game_content = ''

    games
      .filter(game => game.console.id === platform.id)
      .forEach(game => {
      game_content += `<option ${active_game.id === game.id ? 'selected' : ''} value="${game.id}">${game.name} (${game.console.code})</option>`
    })

    return game_content;
  }
}

export default DivisionModal
import { selectors } from "sizzle"
import Store from "../../../store/store"
import { OnlineRacingLeague, Pages } from "../../../types"
import { LeagueActions } from "../../actions"
import ConfirmationModal from "../../modals/confirmation-modal"
import EventUtils from "../../utils/event-utils"

class LeagueListComponent {

  id: string
  selector: string

  leagues: Array<OnlineRacingLeague>
  my_leagues: Array<OnlineRacingLeague>
  my_joined_leagues: Array<OnlineRacingLeague>

  content_function: (callback: (content: Array<OnlineRacingLeague>) => void) => void
  search_content_function: (pattern: string, callback: (content: Array<OnlineRacingLeague>) => void) => void

  constructor(id: string, content_function: (callback: (content: Array<OnlineRacingLeague>) => void) => void, 
                          search_content_function: (pattern: string, callback: (content: Array<OnlineRacingLeague>) => void) => void,
                          my_leagues: Array<OnlineRacingLeague>, my_joined_leagues: Array<OnlineRacingLeague>) {
      this.id = id;
      this.leagues = [];
      this.my_leagues = my_leagues;
      this.my_joined_leagues = my_joined_leagues;
      this.selector = `#${id}`
      this.content_function = content_function;
      this.search_content_function = search_content_function;
  }

  render(): void {       
    // 2. Enable loading  
    this.setLoading()

    // 3. Initial load of content
    this.content_function((leagues) =>  { 
      this.leagues = leagues
      let content = this.getContent(this.selector, leagues)
      content = this.getMyLeagues(this.selector, this.my_joined_leagues) + content;
      $(`${this.selector} .leagues-content`).html(content)

      EventUtils.REGISTER_CLICK_EVENT(`${this.selector} .leagues-content .joined-league .league-action button`, (event) => {
        Store.SET_ACTIVE_LEAGUE(event.target.getAttribute("data-id"))
        window.location.pathname = '/'
      })

      EventUtils.REGISTER_CLICK_EVENT(`${this.selector} .leagues-content .league-details .league-action button`, (event) => {
        const selected_league_id = event.target.getAttribute("data-id");
        const selected_league = leagues.find(league => league.id === parseInt(selected_league_id))

        new ConfirmationModal(
          `Are you sure you want to join the '<b>${selected_league.name}</b>' league?`,
          () => {
            LeagueActions.JOIN(
              event.target.getAttribute("data-id"),
              () => { },
              () => { window.location.reload() }
            )
          }
        ).render()     
      })
    })

    EventUtils.REGISTER_CLICK_EVENT(`${this.selector} .default_option`, () => { 
      const target = $(".dropdown ul");
      if(target.hasClass("active")) {
        target.removeClass("active"); 
      } else {
        target.addClass("active"); 
      }
    })

    EventUtils.REGISTER_CLICK_EVENT(`${this.selector} .dropdown ul li`, () => { 
      const text = $(this).text();
      $(".default_option").text(text);
      $(".dropdown ul").removeClass("active"); 
    })
    
    EventUtils.REGISTER_ON_KEY_UP_EVENT(`${this.selector} .input`, (element, event) => {
      this.search_content_function(event.target.value, (leagues) =>  { 
        this.leagues = leagues
        let content = this.getContent(this.selector, leagues)
        content = this.getMyLeagues(this.selector, this.my_joined_leagues) + content;
        $(`${this.selector} .leagues-content`).html(content)

        EventUtils.REGISTER_CLICK_EVENT(`${this.selector} .joined-league .league-action button`, (event) => {
          Store.SET_ACTIVE_LEAGUE(event.target.getAttribute("data-id"))
          Store.UNSET_ACTIVE_DIVISION()
          window.location.pathname = Pages.DEFAULT.path
        })

        EventUtils.REGISTER_CLICK_EVENT(`${this.selector} .league-details .league-action button`, (event) => {
          const selected_league_id = event.target.getAttribute("data-id");
          const selected_league = leagues.find(league => league.id === parseInt(selected_league_id))

          new ConfirmationModal(
            `Are you sure you want to join the '<b>${selected_league.name}</b>' league?`,
            () => {
              LeagueActions.JOIN(
                event.target.getAttribute("data-id"),
                () => { },
                () => { window.location.reload() }
              )
            }
          ).render() 
        })
      })
    })
  }

  reload(): void {
    this.setLoading()
    this.content_function((leagues) =>  { 
      this.leagues = leagues
      this.getContent(this.selector, leagues) 
    })
  }

  protected getContent(selector: string, leagues: Array<OnlineRacingLeague>): string {
    let content = ''

    leagues.forEach(league => {
      if(! this.isCreator(league) && this.my_joined_leagues.find(joined => joined.id === league.id) === undefined) {
        content += `
          <div class="row justify-content-center mt-3">
            <div class="col-md-7">
              <div class="card">
                <div class="card-body league-body p-4">
                  <h4><span>${league.name} (${league.code.toUpperCase()})</span></h4>
                  <p>${league.description}</p>
                  <div class="league-details">
                    <div class="platforms">
                      <span>PS4</span>
                      <span>PC</span>
                    </div>
                    <div class="league-action">
                      <button data-id="${league.id}" class="primary">JOIN</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        `
      }
    })

    return content;
  }

  protected getMyLeagues(selector: string, my_leagues: Array<OnlineRacingLeague>): string {
    let content = ''

    my_leagues.forEach(league => {
      if(! this.isCreator(league)) {
        content += `
          <div class="row justify-content-center mt-3">
            <div class="col-md-7">
              <div class="card joined-league">
                <div class="card-body league-body p-4">
                  <h4>${league.name} (${league.code})</h4>
                </div>
                <div class="league-action">
                  <button data-id="${league.id}" class="alt">GO TO</button>
                </div>
              </div>
            </div>
          </div>
        `
      }
    })

    return content;
  }

  private isCreator(league: OnlineRacingLeague): boolean {
    return this.my_leagues.find(my_league => my_league.id === league.id) !== undefined;
  }

  setLoading(): void {
    $(`${this.selector} .loaded-content`).html(
      'Loading..'
    )
  }

  onCardAction(): void { }
}

export default LeagueListComponent
import { Division, Team, User } from "../../../types";
import { TeamActions } from "../../actions";
import { CardAction, RowAction } from "./card-types";

import EventUtils from "../../utils/event-utils";

import CardComponent from "./card-component";
import SubmitTeamEntryModal from "../../modals/team/submit-team-entry-modal";
import EditTeamModal from "../../modals/team/edit-team-modal";
import ConfirmationModal from "../../modals/confirmation-modal";

export type TeamRowActions = {
  DELETE: RowAction
}

class TeamCardComponent extends CardComponent<User> {

  static CARD_ACTIONS = [{
    icon: 'mdi-account-multiple-plus-outline',
    service: 12
  },{
    icon: 'mdi-square-edit-outline',
    service: 12
  }]

  team: Team
  division: Division

  refresh_list_function: () => void;

  constructor(container_selector: string, team: Team, division: Division,
    content_function: (team_id: number, callback: (content: Array<User>) => void) => void = () => {},
    refresh_list_function: () => void = () => { }) {
    super(container_selector, `team-${team.id}`, team.name, 'primary', false, true, content_function, () => {}, [], [ RowActions.DELETE ], [], TeamCardComponent.CARD_ACTIONS)

    this.team = team;
    this.division = division;

    this.refresh_list_function = refresh_list_function
  }

  getEntityId(): number {
    return this.team.id;
  }

  onCardAction(action: CardAction): void {
    switch(action.icon) {
      case 'mdi-account-multiple-plus-outline': {
        new SubmitTeamEntryModal(this.division, this.team, this.items, this)
          .render()
        break;
      }
      case 'mdi-square-edit-outline': {
        new EditTeamModal(this.division, this.team, this)
          .onClose(() => { this.reload() })
          .render()
        break;
      }
    }
  }

  getCardContent(users: Array<User>, color: string, row_actions: Array<RowAction>): string {
    let card_content = ''

    users.forEach(user => {
      const car_image = `https://images.onlineformularacing.co.uk/Vehicles/${this.team.vehicle?.id}.png`

      card_content += `
        <div data-id="${user.id}" class="roster-entry media py-3 align-items-center justify-content-between">
          <div class="driver-icon d-flex rounded-circle align-items-center justify-content-center media-icon iconbox-45 bg-primary text-white">
            <i class="mdi mdi-account-outline font-size-20"></i>
          </div>
          <div class="driver-details media-body pr-3 ">
            <a class="mt-0 mb-1 font-size-15 text-dark" href="#">${user.username}</a>
            <p style="text-overflow: ellipsis; overflow: hidden;">${this.team.vehicle !== null && this.team.vehicle !== undefined ? this.team.vehicle.manufacturer : ''}</p>
          </div>

          ${
            this.team.vehicle === null || this.team.vehicle === undefined
              ? `<div class="vehicle-preview media-body pr-3">
                  <img style="width: 85%;" src="https://images.onlineformularacing.co.uk/Vehicles/unknown-dark.png" />
                </div>`
              : ` <div class="vehicle-preview media-body pr-3">
                    <img style="width: 85%;" src="${car_image}" />
                  </div>`
          }
          
          <div style="padding-top: 8px; display: inline-block; text-align: right; overflow: hidden; white-space: nowrap;" class="media-body pr-3 report-actions">
            ${ this.getRowActionContent(user, row_actions) }
          </div>
        </div>
      `
    })

    if(users.length === 0) {
      card_content = `
      <div style="text-align: center;">
          NO DRIVERS ASSIGNED TO THIS ROSTER
      </div>
      `
    }

    return card_content;
  }

  setRowEvents(selector: string, user: User, row_actions: Array<RowAction>): void {
    if(row_actions.includes(RowActions.DELETE)) {
      EventUtils.REGISTER_CLICK_EVENT(`${selector} .${RowActions.DELETE.class}[data-id="${user.id}"]`, () => { this.onRemoveAction(user) })  
    } 
  }

  private onRemoveAction(user: User) {
    new ConfirmationModal(`Are you sure you want to remove <b>${user.username}</b> from <b>${this.team.name}</b>?`, () => {
      TeamActions.REMOVE_USER(
        { user_id: user.id, division_team_id: this.team.id },
        () => { this.setLoading() },
        () => { this.reload() }
      )
    }).render()
  }
}

export const RowActions: TeamRowActions = {
  DELETE: {
    name: 'DELETE',
    class: 'mdi-close',
    service: 12,
  }
}

export default TeamCardComponent
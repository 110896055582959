import { Division, Event } from "../../types"
import AlertUtils from "../../utils/alert-utils"
import { EventActions, ResultActions } from "../actions"
import QualifyingResultCardComponent, { QualifyingResultDetail } from "../components/card/qualifying-result-card-component"
import qualifyingResultMapper from "../mappers/qualifying-result-mapper"

import EventUtils from "../utils/event-utils"
import LoaderUtils from "../utils/loader-utils"

const ON_PAGE_LOAD = (active_division: Division): void => {
  $('#index-first-row').html(
    `
      <div class="col-lg-6">
        <div class="form-group events">
          <label>Event</label>
          <div class="sk-three-bounce select-loader loader">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>

          <select class="form-control loaded-content" required=""></select>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="form-group video-link">
          <label>Stream link</label><br>
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="mdi mdi-movie-open-outline"></i>
              </span>
            </div>
            <input type="text" class="form-control" placeholder="" aria-label="">
          </div>
        </div>
      </div>
    `
  )

  $("#index-third-row").html(`
    <div class="col-lg-12" style="text-align:right;">
      <button type="button" id="submit-result-btn" class="btn btn-primary btn-pill">
        <div class="sk-three-bounce loader" style="display:none;">
          <div style="background-color:white;" class="bounce1"></div>
          <div style="background-color:white;" class="bounce2"></div>
          <div style="background-color:white;" class="bounce3"></div>
        </div>
        <span class="loaded-content">Submit</span>
      </button>
    </div>  
  `)

  EventActions.GET_EVENTS(
    active_division.id,
    () => LoaderUtils.LOADING(`#index-first-row .events`),
    (events) => {
      if(events.length > 0) {
        const event_content = getEventsContent(events)
        LoaderUtils.LOADED(`#index-first-row .events`, event_content)

        EventUtils.REGISTER_CHANGE_EVENT(`#index-first-row .events select`, (element, change_event) => {
            const event = events.find(event => event.id === parseInt(change_event.target.value))
            if(event !== undefined) onEventChange(active_division, event)
        })

        onEventChange(active_division, events[0])
      }
    }
  )

  new QualifyingResultCardComponent(
    '#index-second-row',
    'quali-results',
    'Results',
    'primary',
    () => { },
    undefined,
    undefined,
    undefined,
    [ QualifyingResultDetail.TYRE_COMPOUND, QualifyingResultDetail.LAP_TIME, QualifyingResultDetail.POINTS ]
  ).render()
}

const getEventsContent = (events: Array<Event>): string => {
  let event_content = ''

  events.forEach(event => {
    event_content += `<option value="${event.id}">${event.track.name}</option>`
  })

  return event_content;
}

function onEventChange(division: Division, event: Event): void {
  $('#index-second-row').html('')
  $('.video-link input').val(event.stream_url)

  const card = new QualifyingResultCardComponent(
    '#index-second-row',
    'quali-results',
    'Results',
    'primary',
    ResultActions.GET_QUALI_RESULTS,
    division,
    event,
    undefined,
    [ QualifyingResultDetail.TYRE_COMPOUND, QualifyingResultDetail.LAP_TIME, QualifyingResultDetail.POINTS ]
  )

  EventUtils.REMOVE_ALL_EVENTS('#submit-result-btn')

  EventUtils.REGISTER_CLICK_EVENT('#submit-result-btn', () => {
    const stream_link = $('.video-link input').val();

    if(stream_link && stream_link !== '') {
      EventActions.EDIT_EVENT({
        division_id: division.id,
        event_id: event.id,
        track_id: event.track.id,
        stream_url: <string> stream_link,
        date: event.date,
        description: event.description,
        event_configuration: event.event_configuration,
        event_configuration_schema_id: event.event_configuration_schema_id,
      },
      () => {},
      () => {})
    }

    ResultActions.SUBMIT_QUALIFYING_RESULTS(
      {
        event_id: event.id,
        results: qualifyingResultMapper.MAP_TO_REQUEST(card.items)
      },
      () => LoaderUtils.LOADING('#submit-result-btn'),
      () => {
        AlertUtils.NOTIFY('Result succesfully submitted.', 'Success!')
        LoaderUtils.LOADED('#submit-result-btn', 'Submit')
      },
      () => LoaderUtils.LOADED('#submit-result-btn', 'Submit')
    )
  })

  card.render()
}

export { ON_PAGE_LOAD }

import { DriverStandingEntry } from "../../../types";
import StandingUtils from "../../utils/standing-utils";
import StandingBannerComponent from "./standing-banner-component";

class DriverStandingBannerComponent extends StandingBannerComponent<DriverStandingEntry> {
    
    getLeaderDetail(leader: DriverStandingEntry): string {
        return `
            <div class="username">${leader.user.username}</div>
            <div class="constructor">${StandingUtils.GET_CONSTRUCTOR(leader)}</div>
        `
    }

    getEntryDetail(entry: DriverStandingEntry): string {
        return `<a href="#/standings" class="clickable-username color-white">${entry.user.username}</a>`
    }
    
  }
  
  export default DriverStandingBannerComponent;
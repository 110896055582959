import { LeagueActions } from '../../dashboards/actions'
import TermsConditionsModal from '../../dashboards/modals/terms-conditions-modal'
import EventUtils from '../../dashboards/utils/event-utils'
import Store from '../../store/store'
import { Response } from '../../types'

import AlertUtils from '../../utils/alert-utils'
import JwtUtils from '../../utils/jwt-utils'

import { LoginActions } from '../actions'
import { LoginEvents } from '../events'
import { LoginRequest } from '../types'

const ON_PAGE_LOAD = (is_successfully_registered: boolean, has_successfully_reset_password: boolean): void => {
  if(is_successfully_registered) {
    AlertUtils.NOTIFY("You can now login with you PSN and password.", "Registration success!")
  }

  if(has_successfully_reset_password) {
    AlertUtils.NOTIFY("Successfully reset password, you can now login with your new password.", "Password reset success!")
  }

  EventUtils.REGISTER_CLICK_EVENT(`#open-terms-and-condition-modal`, () => {
    new TermsConditionsModal().render();
  })

  LoginEvents.ON_SUBMIT(
    LOGIN
  )
}

const LOGIN = async (login_request: LoginRequest) => {
  LoginActions.LOGIN(login_request, LOGIN_LOADING, LOGIN_SUCCEEDED, LOGIN_FAILED)
}

const LOGIN_SUCCEEDED = (response: Response) => {
  Store.SET_IS_AUTHENTICATED(true)
  Store.SET_ACCESS_TOKEN(response.message)

  const jwt = JwtUtils.PARSE(response.message)

  Store.SET_USER_ID(jwt.user_id)

  LeagueActions.GET_JOINED_LEAGUES((my_joined_leagues) => {
    if(my_joined_leagues.length > 0) {
      window.location.href = "/"
    } else {
      window.location.href = "/landing-page.html"
    }
  })
}

const LOGIN_FAILED = () => {
  $("button").prop('disabled', false);
  $("#signin-loader").hide();
  $("button span").show();
}

const LOGIN_LOADING = () => {
  $("button").prop('disabled', true);
  $("button span").hide();
  $("#signin-loader").show();
}

export { ON_PAGE_LOAD }
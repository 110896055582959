import ApiUtils from '../../utils/api-utils'
import Store from '../../store/store'
import { api } from '../../config'
import { DriverStandingEntry, StandingEntry } from '../../types'

const GET_DRIVER_STANDINGS = (division_id: number, loading_callback = () => {}, success_callback: (standings: Array<DriverStandingEntry>) => void = () => {}, error_callback = () => {}): void => {
  loading_callback()
  
  $.ajax({
    type: "GET",
    url: `${api}/division/standings/driver?division_id=${division_id}&token=${Store.ACCESS_TOKEN()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const GET_TEAM_STANDINGS = (division_id: number, loading_callback = () => {}, success_callback: (standings: Array<StandingEntry>) => void = () => {}, error_callback = () => {}): void => {
  loading_callback()
  
  $.ajax({
    type: "GET",
    url: `${api}/division/standings/team?division_id=${division_id}&token=${Store.ACCESS_TOKEN()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

export { GET_DRIVER_STANDINGS, GET_TEAM_STANDINGS }
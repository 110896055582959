import { OnlineRacingLeague } from "../../types";
import InfoCardComponent from "../components/card/info-card-component";

const ON_PAGE_LOAD = (active_league: OnlineRacingLeague): void => {
    new InfoCardComponent(
        '#index-first-row', 
        `Welcome to ${active_league.name}!`, 
        `<p class="mb-3">Look who decided to join our league!</p> 
         <p>Currently you're not enlisted in any of our divisions, which is why you're not able to see any information.</p>
         <p class="mb-3">Please reach out to one of the division managers who will happily help you join a division, and once enlisted come back here, and you'll be able to see all the necessary information.</p>
         <p>Cheerios!</p>`
    )
    .render();
}

export { ON_PAGE_LOAD }
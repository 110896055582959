import { DriverStandingEntry, ResultEntry, StandingEntry, Vehicle } from "../../types"

const GET_CONSTRUCTOR = (standing_entry: StandingEntry) : string => {
  if(standing_entry.team && standing_entry.team.vehicle) {
    return `${standing_entry.team.name} (${standing_entry.team.vehicle.name})`
  }

  if(standing_entry.team && (standing_entry.team.vehicle === null || standing_entry.team.vehicle === undefined)) {
    return `${standing_entry.team.name} (${ (<DriverStandingEntry> standing_entry).vehicle.name })`
  }

  if((standing_entry.team === null || standing_entry.team === undefined) && (<DriverStandingEntry> standing_entry).vehicle != null) {
    return `${ (<DriverStandingEntry> standing_entry).vehicle.manufacturer } (${ (<DriverStandingEntry> standing_entry).vehicle.name })`
  }

  return `Unknown`;
}

const GET_VEHICLE = (standing: StandingEntry): Vehicle => {
  return standing.team && standing.team.vehicle !== null && standing.team.vehicle !== undefined
    ? standing.team.vehicle
    : (<DriverStandingEntry> standing).vehicle
}

const GET_COLOR_BY_TEAM = (standing: StandingEntry): string => {
  return standing.team?.color ? standing.team.color : GET_COLOR(standing)
}

const GET_COLOR = (standing: StandingEntry): string => {
  const vehicle = GET_VEHICLE(standing);
  
  switch(vehicle?.id) {
    // Mercedes
    case 364: {
      return '#00D2BE'
    }
    // Aston Martin
    case 365: {
      return '#006F62'
    }
    // Ferrari
    case 366: {
      return '#DC0000'
    }
    // Alfa Romeo
    case 367: {
      return '#900000'
    }
    // Alpine
    case 368: {
      return '#0090FF'
    }
    // Alpha Tauri
    case 369: {
      return '#2B4562'
    }
    // Haas
    case 370: {
      return '#FFFFFF'
    }
    // Mclaren
    case 371: {
      return '#FF8700'
    }
    // Redbull
    case 372: {
      return '#0600EF'
    }
    // Williams
    case 373: {
      return '#005AFF'
    } 
    default: {
      return '#FF1801'
    }
  }
}

export default { GET_CONSTRUCTOR, GET_VEHICLE, GET_COLOR, GET_COLOR_BY_TEAM }
import { Division, OnlineRacingLeague, Report } from "../../types"
import { PenaltyActions, ReportActions } from "../actions"
import ReportCardComponent, { RowActions as ReportRowActions } from "../components/card/report-card-component"
import { ReportDetailPanel } from "../modals/report/report-details-modal"

const ON_PAGE_LOAD = (active_league: OnlineRacingLeague, active_division: Division): void => {
  new ReportCardComponent(
    '#index-first-row', 
    'awaiting-vote', 
    'Awaiting vote',  
    'warning', 
    false,
    active_division,
    ReportActions.GET_UNVOTED_REPORTS, 
    () => { },
    [], 
    [ ReportRowActions.VOTE ]
  ).render()

  new ReportCardComponent(
    '#index-first-row', 
    'received-vote', 
    'Received vote',  
    'success', 
    false,
    active_division,
    ReportActions.GET_VOTED_REPORTS, 
    () => { },
    [], 
    [ ReportRowActions.VOTE, ReportRowActions.PENALIZE ]
  ).render()

  new ReportCardComponent(
    '#index-first-row', 
    'unserved-penalties', 
    'Unserved penalties',  
    'primary', 
    false,
    active_division,
    (division_id: number, callback: (content: Array<Report>) => void) => {
      ReportActions.GET_VOTED_REPORTS(division_id, (reports => {
        PenaltyActions.GET_UNSERVED_PENALTIES(division_id, (unserved_penalties) => {
          const unserved_penalty_ids = unserved_penalties.map(penalty => penalty.id)
          const report_with_unserved_penalties = reports
            .filter(report => report.report_penalties.length > 0)
            .filter(report => report.report_penalties.find(penalty => unserved_penalty_ids.includes(penalty.id)) !== undefined)
            .map(report => {
              report.report_penalties = report.report_penalties.filter(
                penalty => unserved_penalty_ids.includes(penalty.id)
              )

              return report;
            })

          callback(report_with_unserved_penalties)
        })
      }))
    }, 
    () => { },
    [],
    [ ReportRowActions.DETAILS, ReportRowActions.SERVE ],
  ).render()
}

export { ON_PAGE_LOAD }
import { ReportActions } from '../actions'
import ReportCardComponent, { RowActions as ReportRowActions } from '../components/card/report-card-component'
import EventUtils from '../utils/event-utils'
import SubmitReportModal from '../modals/report/submit-report-modal'
import { Division, OnlineRacingLeague } from '../../types'


const ON_PAGE_LOAD = (league: OnlineRacingLeague, active_division: Division): void => {
  $('#index-first-row').css("margin-top", "2.5rem")
  $('.page-buttons').html(
    `<button type="button" id="open-submit-report-modal-btn" class="btn btn-primary btn-pill">
        Submit a report
      </button>`
  )

  const myOpenReportsCard = new ReportCardComponent(
    '#index-first-row', 
    'my-reports', 
    'My Reports', 
    'primary', 
    false,
    active_division,
    ReportActions.GET_MY_OPEN_REPORTS, 
    ReportActions.GET_MY_CLOSED_REPORTS,
    ['OPEN', 'CLOSED'], 
    [ ReportRowActions.DETAILS, ReportRowActions.DELETE ], 
    [ ReportRowActions.DETAILS ]
  )

  myOpenReportsCard.render()

  new ReportCardComponent(
    '#index-first-row', 
    'my-involvements', 
    'My Involvements',  
    'info', 
    false,
    active_division,
    ReportActions.GET_MY_OPEN_INVOLVEMENTS, 
    ReportActions.GET_MY_CLOSED_INVOLVEMENTS,
    ['OPEN', 'CLOSED'], 
    [ ReportRowActions.DETAILS, ReportRowActions.ADDITIONAL_INFO ], 
    [ ReportRowActions.DETAILS ]
  ).render()

  // new PenaltyCardComponent(
  //   '#index-second-row', 
  //   'my-penalties', 
  //   'My Penalties', 
  //   'warning', 
  //   false,
  //   PenaltyActions.GET_MY_UNSERVED_PENALTIES, 
  //   PenaltyActions.GET_MY_SERVED_PENALTIES, 
  //   ['UNSERVED', 'SERVED'], 
  //   [ PenaltyRowActions.DETAILS, PenaltyRowActions.APPEAL ], 
  //   [ PenaltyRowActions.DETAILS ]
  // ).render()

  EventUtils.REGISTER_CLICK_EVENT("#open-submit-report-modal-btn", () => new SubmitReportModal(active_division.id, myOpenReportsCard).render())
}

export { ON_PAGE_LOAD }
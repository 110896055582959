import { QualifyingResultEntry, QualifyingResultEntryModel, SubmitQualifyingResultEntryRequest, SubmitRaceResultEntryRequest } from "../../types";

const MAP = (models: Array<QualifyingResultEntryModel>): Array<QualifyingResultEntry> => {
    return models.map(model => {
        const { qualifying_result_type, ...entry } = model
        return {
            ...entry,
            result_type: model.qualifying_result_type
        };
    })
}

const MAP_TO_REQUEST = (entries: Array<QualifyingResultEntry>): Array<SubmitQualifyingResultEntryRequest> => {
    return entries.map(entry => {
        return {
            user_id: entry.user.id,
            vehicle_id: entry.vehicle.id,
            qualifying_result_type_id: entry.result_type.id,
            fastest_time: entry.fastest_time,
            tyre_compound_id: entry.tyre_compound.id,
            finish_position: entry.finish_position,
            points: entry.points,
            division_team_id: entry.team?.id
        }
    })
}

export default { MAP, MAP_TO_REQUEST }
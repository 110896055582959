const GET_PREVIEW = (youtube_link: string): string => {
  if(youtube_link.includes('youtu.be/') || youtube_link.includes('shorts')) {
    const regex = /https:\/\/.*\/(.*)$/ig
    let link = regex.exec(youtube_link)[1]
    link = link.replace(/t=(\d+)/, 'start=$1')
    return `<iframe width="100%" height="265px" src="https://youtube.com/embed/${link}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
  } else if(youtube_link.includes('youtube.com/') && youtube_link.includes('watch')) {
    const regex = /http(?:s)?:\/\/.*watch\?v=(.*)/ig
    let link = regex.exec(youtube_link)[1]
    link = link.replace(/t=(\d+)/, 'start=$1')
    return `<iframe width="100%" height="265px" src="https://youtube.com/embed/${link}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
  } else {
    return `<a href="${youtube_link}">Click this link to go to the video.</a>`
  }
}

export default { GET_PREVIEW }
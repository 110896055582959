import ApiUtils from '../../utils/api-utils'
import Store from '../../store/store'
import { api } from '../../config'
import { QualifyingResultEntry, RaceResultEntry, ResultType, SubmitQualifyingResultRequest, SubmitRaceResultRequest } from '../../types';
import RaceResultMapper from '../mappers/race-result-mapper'
import QualifyingResultMapper from '../mappers/qualifying-result-mapper';
import { EventActions } from '.';

const GET_RACE_RESULTS = (event_id: number, success_callback: (response: Array<RaceResultEntry>) => void = () => {}, error_callback = () => {}): void => {
  EventActions.GET_EVENT(
    event_id,
    () => { },
    (event: any) => {
      ApiUtils.DEFAULT_SUCCESS_HANDLER(
        { code: 0, values: RaceResultMapper.MAP(event.race_results === undefined || event.race_results.length === 0 ? [] : event.race_results[0]), success: true },
        success_callback
      )
    },
    error_callback
  )
}

const GET_QUALI_RESULTS = (event_id: number, success_callback: (response: Array<QualifyingResultEntry>) => void = () => {}, error_callback = () => {}): void => {  
  EventActions.GET_EVENT(
    event_id,
    () => { },
    (event: any) => {
      ApiUtils.DEFAULT_SUCCESS_HANDLER(
        { code: 0, values: QualifyingResultMapper.MAP(event.qualifying_results === undefined || event.qualifying_results.length === 0 ? [] : event.qualifying_results[0]), success: true },
        success_callback
      )
    },
    error_callback
  )
}

const GET_RACE_RESULT_TYPES = (loading_callback: () => void = () => {}, success_callback: (result_types: Array<ResultType>) => void = () => {}, error_callback = () => {}): JQuery.jqXHR<any> => {
  loading_callback();

  return $.ajax({
    type: "GET",
    url: `${api}/result_types/race?token=${Store.ACCESS_TOKEN()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const GET_QUALIFYING_RESULT_TYPES = (loading_callback = () => {}, success_callback: (result_types: Array<ResultType>) => void = () => {}, error_callback = () => {}): JQuery.jqXHR<any> => {
  loading_callback();

  return $.ajax({
    type: "GET",
    url: `${api}/result_types/qualifying?token=${Store.ACCESS_TOKEN()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const SUBMIT_RACE_RESULTS = (race_results: SubmitRaceResultRequest, loading_callback = () => {}, success_callback = () => {}, error_callback = () => {}): void => {
  loading_callback();

  const results = [
    race_results.results
  ]

  $.ajax({
    type: "POST",
    url: `${api}/event/results/race`,
    data: JSON.stringify({
      token: Store.ACCESS_TOKEN(),
      results: results,
      event_id: race_results.event_id
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const SUBMIT_QUALIFYING_RESULTS = (quali_results: SubmitQualifyingResultRequest, loading_callback = () => {}, success_callback = () => {}, error_callback = () => {}): void => {
  loading_callback();

  const results = [
    quali_results.results
  ]

  $.ajax({
    type: "POST",
    url: `${api}/event/results/qualifying`,
    data: JSON.stringify({
      token: Store.ACCESS_TOKEN(),
      results: results,
      event_id: quali_results.event_id
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

export { GET_RACE_RESULTS, GET_QUALI_RESULTS, GET_RACE_RESULT_TYPES, GET_QUALIFYING_RESULT_TYPES, SUBMIT_RACE_RESULTS, SUBMIT_QUALIFYING_RESULTS }
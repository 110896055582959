import { ResultEntry, Vehicle } from "../../types"

const GET_CONSTRUCTOR = (result_entry: ResultEntry) : string => {
  if(result_entry.team && result_entry.team.vehicle) {
    return `${result_entry.team.name} (${result_entry.team.vehicle.name})`
  }

  if(result_entry.team && (result_entry.team.vehicle === null || result_entry.team.vehicle === undefined)) {
    return result_entry.vehicle 
      ? `${result_entry.team.name} (${result_entry.vehicle.name})`
      : `${result_entry.team.name}`
  }

  if(result_entry.team === null || result_entry.team === undefined) {
    return `${result_entry.vehicle.manufacturer} (${result_entry.vehicle.name})`
  }

  return `Unknown`;
}

const GET_VEHICLE = (result: ResultEntry): Vehicle => {
  return result.team && result.team.vehicle !== null && result.team.vehicle !== undefined
    ? result.team.vehicle
    : result.vehicle
}

const GET_COLOR = (result: ResultEntry): string => {
  const vehicle = GET_VEHICLE(result);
  
  return GET_COLOR_BY_VEHICLE(vehicle);
}

const GET_COLOR_BY_TEAM = (result: ResultEntry): string => {
  return result.team?.color ? result.team.color : GET_COLOR(result)
}

const GET_COLOR_BY_VEHICLE = (vehicle: Vehicle): string => {  
  switch(vehicle.id) {
    // Mercedes
    case 364: {
      return '#00D2BE'
    }
    // Aston Martin
    case 365: {
      return '#006F62'
    }
    // Ferrari
    case 366: {
      return '#DC0000'
    }
    // Alfa Romeo
    case 367: {
      return '#900000'
    }
    // Alpine
    case 368: {
      return '#0090FF'
    }
    // Alpha Tauri
    case 369: {
      return '#2B4562'
    }
    // Haas
    case 370: {
      return '#FFFFFF'
    }
    // Mclaren
    case 371: {
      return '#FF8700'
    }
    // Redbull
    case 372: {
      return '#0600EF'
    }
    // Williams
    case 373: {
      return '#005AFF'
    } 
    default: {
      return '#FF1801'
    }
  }
}

export default { GET_CONSTRUCTOR, GET_VEHICLE, GET_COLOR, GET_COLOR_BY_VEHICLE, GET_COLOR_BY_TEAM }
import MenuHandler from "../../../menu/event-handlers/menu-handler";
import Store from "../../../store/store";
import { Division, OnlineRacingLeague, User } from "../../../types";
import { DivisionActions } from "../../actions";
import ConfirmationModal from "../../modals/confirmation-modal";
import DivisionModal from "../../modals/division/division-modal";
import EventUtils from "../../utils/event-utils";
import CardComponent from "./card-component";
import { RowAction } from "./card-types";

export type DivisionRowActions = {
  EDIT: RowAction
  DELETE: RowAction
}
class DivisionCardComponent extends CardComponent<Division> {

  league: OnlineRacingLeague
  divisions: Array<Division>

  constructor(container_selector: string, color: string, league: OnlineRacingLeague, division_content_function: (loading_callback: () => void, callback: (content: Array<Division>) => void) => void) {
    super(container_selector, 'league-divisions', 'Divisions', color, true, false,
      (league_id: number, callback: (content: Array<Division>) => void) => {  division_content_function(() => {}, (divisions: Array<Division>) => { callback(divisions) }) }, 
      () => {}, [], [RowActions.EDIT, RowActions.DELETE], [], [])

    this.league = league;
  }

  getEntityId(): number { return this.league.id; }

  getCardContent(divisions: Array<Division>, color: string, row_actions: RowAction[]): string {
    if(divisions.length === 0) {
      return `
        <div style="text-align: center;">
          NO DIVISIONS YET.
        </div>
      `
    }

    let divisions_content = '';

    divisions.forEach((division, index) => {
      divisions_content += `
        <div data-id="${division.id}" class="division-entry media py-3 align-items-center justify-content-between">
          <div style="display: flex; flex-direction: column; flex: 0 0 45px;" class="driver-position rounded-circle align-items-center justify-content-center media-icon iconbox-45 bg-primary text-white">
            ${index + 1}
          </div>
          
          <div class="details media-body pr-3 mr-3">
            <span class="text-truncate color-dark">${division.code}</span>
            <span class="subtitle text-truncate" style="font-size: 12px;">${division.name}</span>
          </div>        

          <div class="tags media-body pr-3 mr-3">
            <span class="text-truncate bg-secondary text-white">${division.game.console.code}</span>
            <span class="text-truncate">${division.game.name}</span>
            ${division.active 
              ? '<span class="bg-success text-white text-truncate">ACTIVE</span>' 
              : '<span class="bg-danger text-white text-truncate">NOT ACTIVE</span>'}
            
          </div>        
            
          <div class="static-result-actions media-body pr-3 report-actions">
            ${this.getRowActionContent(division, row_actions)}
          </div>
        </div>
      `   
    })

    return divisions_content;
  }

  setRowEvents(selector: string, division: Division, row_actions: Array<RowAction>): void {
    if(row_actions.includes(RowActions.EDIT)) {
      EventUtils.REGISTER_CLICK_EVENT(`${selector} .${RowActions.EDIT.class}[data-id="${division.id}"]`, () => { this.onEditAction(division) })
    }

    if(row_actions.includes(RowActions.DELETE)) {
      EventUtils.REGISTER_CLICK_EVENT(`${selector} .${RowActions.DELETE.class}[data-id="${division.id}"]`, () => { this.onRemoveAction(division) })
    }
  }

  onCardAction(): void { }

  onReload(divisions: Array<Division>): void {
    MenuHandler.RENDER_DIVISIONS(
      divisions, 
      divisions.find(division => division.id === Store.ACTIVE_DIVISION())
    )
  }

  private onEditAction(division: Division) {
    new DivisionModal(division)
      .onClose(() => this.reload() )
      .render()
  }

  private onRemoveAction(division: Division) {
    new ConfirmationModal(
      `Are you sure you want to remove <b>${division.name} (${division.code})</b>?`,
      () => {
        DivisionActions.REMOVE(
          division.id,
          () => { this.setLoading() },
          () => {
            this.reload()
          }
        )
      }
    ).render()
  }
}

export const RowActions: DivisionRowActions = {
    EDIT: {
      name: 'EDIT',
      class: 'mdi-pencil',
      service: 8
    },
    DELETE: {
      name: 'DELETE',
      class: 'mdi-close',
      service: 9,
    }
  }

export default DivisionCardComponent;
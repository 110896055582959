import { Division, Event, User } from "../../../types";
import { DivisionActions, EventActions, ReportActions, UserActions } from "../../actions";

import AlertUtils from "../../../utils/alert-utils";
import EventUtils from "../../utils/event-utils";
import LoaderUtils from "../../utils/loader-utils";
import WordUtils from "../../../utils/word-utils";
import ValidationUtils from "../../utils/validation-utils";

import Modal from "../modal";
import CardComponent from "../../components/card/card-component";

const id = 'submit-report-modal'
const title = 'Submit Report'

class SubmitReportModal extends Modal<void> {

  division_id: number
  origin_card?: CardComponent<any>

  constructor(division_id: number, origin_card?: CardComponent<any>,) {
    super(id, title, undefined)
    this.division_id = division_id;
    this.origin_card = origin_card;
  }

  getTemplate(): string {
    EventActions.GET_EVENTS(
      this.division_id,
      () => LoaderUtils.LOADING(`#${id} .events`),
      (events_response) => {
        const events_content = this.getEventsContent(events_response)
        LoaderUtils.LOADED(`#${id} .events`, events_content)
      }
    )

    UserActions.GET_DIVISION_USERS(
      this.division_id,
      () => LoaderUtils.LOADING(`#${this.id} .drivers`),
      (users_response) => {
        const users_content = this.getUsersContent(users_response)
        LoaderUtils.LOADED(`#${this.id} .drivers`, users_content)
      }
    )

    return `
      <div class="modal fade" id="${this.id}" tabindex="-1" role="dialog" aria-labelledby="submit-report-modals" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="submit-report-modals">Submit a report</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">         
                <div class="form-group events">
                  <label>Event</label>
                  <div class="sk-three-bounce select-loader loader">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                  </div>

                  <select class="loaded-content form-control" required>
                      
                  </select>
                </div>

                <div class="form-group drivers">
                  <label>Drivers involved</label><br/>
                  <div class="sk-three-bounce select-loader loader" style="z-index: 99;">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                  </div>
                
                  <select style="width: 100%;" class="loaded-content js-example-basic-multiple form-control" name="users[]" multiple="multiple" required>
                  
                  </select>
                  <p class="mt-2" style="font-size: 90%">&nbsp;&nbsp;<b>Note:</b> Only include <u>yourself</u> if you're submitting an injustice that was done to you.</p>
                </div>

                <div class="form-group video-link">
                  <label for="users">Video link</label><br/>
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                          <i class="mdi mdi-movie-open-outline"></i>
                      </span>
                    </div>
                    <input type="text" class="form-control" placeholder="" aria-label="">
                  </div>
                  <p style="font-size: 90%">&nbsp;&nbsp;<b>Note:</b> Provide the <u>start time</u> in the youtube link when using the stream url.</p>
                </div>

                <div class="form-group description">
                  <label for="description">Description</label>
                  <textarea class="form-control" id="description" rows="3"></textarea>
                </div>
              </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">Close</button>
              <button id="submit-report" type="button" class="btn btn-primary btn-pill">
                <div style="display:none; height: 20px;" class="sk-three-bounce loader">
                  <div style="background-color:white;" class="bounce1"></div>
                  <div style="background-color:white;" class="bounce2"></div>
                  <div style="background-color:white;" class="bounce3"></div>
                </div>
                <span class="loaded-content">Submit</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    `
  }

  getModalActions(): void  {
    EventUtils.REMOVE_ALL_EVENTS(`#${this.id} button#submit-report`)

    EventUtils.REGISTER_CLICK_EVENT(`#${this.id} button#submit-report`, () => {
      const event = <string> $(`#${this.id} .events .loaded-content`).val()
      const users = <string[]> $(`#${this.id} .drivers .loaded-content`).val()
      const video_link = <string> $(`#${this.id} .video-link input`).val()
      const description = <string> $(`#${this.id} .description textarea`).val()

      let is_valid = true;

      if(video_link.startsWith('http://') || video_link.startsWith('https://')) {
        ValidationUtils.RESET(`#${this.id} .video-link input`)
      } else {
        ValidationUtils.INVALIDATE(`#${this.id} .video-link input`, 'Please provide a valid url.')
        is_valid = false;
      }

      if(description && description.length >= 25 && description.length <= 500) {
        ValidationUtils.RESET(`#${this.id} .description textarea`)
      } else {
        ValidationUtils.INVALIDATE(`#${this.id} .description textarea`, 'Description must be between 25 and 500 characters.')
        is_valid = false;
      }

      if(! is_valid) {
        return;
      }

      const report = {
        event_id: parseInt(event),
        user_ids: users.map(user => parseInt(user)),
        media_link: video_link,
        description: description
      }

      ReportActions.SUBMIT_REPORT(
        report,
        () => { LoaderUtils.LOADING(`#${this.id} button#submit-report`) },
        () => { 
          this.close()
          AlertUtils.NOTIFY('Report was succesfully submitted.', 'Success!')
          LoaderUtils.LOADED(`#${this.id} button#submit-report`, 'Submit')
          
          if(this.origin_card) this.origin_card.reload()
        }
      )
    })

    $(`#${id} .drivers select`).select2();
  }

  private getEventsContent(events: Array<Event>) {
    let event_content = ''

    events.forEach((event, index) => {
      event_content += `<option value="${event.id}">(${index + 1}) ${WordUtils.FIRST_UPPER_CASE(event.track.name)}</option>`
    })

    return event_content;
  }

  private getUsersContent(users: Array<User>) {
    let user_content = ''

    users.forEach(user => {
      user_content += `<option value="${user.id}">${user.username}</option>`
    })

    return user_content;
  }
}

export default SubmitReportModal
import EventUtils from "../utils/event-utils";
import Modal from "./modal";

class ConfirmationModal extends Modal<void> {
  
  message: string    
  onConfirmFunction: () => void;

  constructor(message: string, onConfirmFunction: () => void) {
    super('confirmation-modal', 'Hoolllld upp!', undefined, "#confirmation-modals")

    this.message = message;
    this.onConfirmFunction = onConfirmFunction;
  }

  getTemplate(): string {
    return `
      <div class="modal fade" id="${this.id}" tabindex="-1" role="dialog" aria-labelledby="submit-report-modals" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="submit-report-modals">${this.title}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">         
              <p>${this.message}</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">No!</button>
              <button id="confirm-btn" type="button" class="btn btn-primary btn-pill" data-dismiss="modal">Yes, i'm sure.</button>
            </div>
          </div>
        </div>
      </div>
    `
  }

  getModalActions(): void {
    EventUtils.REMOVE_ALL_EVENTS(`#${this.id} #confirm-btn`)
    EventUtils.REGISTER_CLICK_EVENT(`#${this.id} #confirm-btn`, () => {
      this.close()
      this.onConfirmFunction()
    })
  }
}

export default ConfirmationModal;
import ApiUtils from '../../utils/api-utils'
import Store from '../../store/store'
import { api } from '../../config'
import { Event } from '../../types';

const GET_SCHEMA = (game_id: number, loading_callback = () => {}, success_callback: (schemas: Array<any>) => void = () => {}, error_callback = () => {}): void => {
  loading_callback()
  
  $.ajax({
    type: "GET",
    url: `${api}/event_configuration/schema?game_id=${game_id}&token=${Store.ACCESS_TOKEN()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback),
    async: false
  });
}

const GET_SCHEMA_SYNC = (game_id: number, error_callback = () => {}): Array<any> => {
  let result = [{}];

  $.ajax({
    type: "GET",
    url: `${api}/event_configuration/schema?game_id=${game_id}&token=${Store.ACCESS_TOKEN()}`,
    success: (response) => {
      result = response.values;
    },
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback),
    async: false
  });

  return result;
}

export { GET_SCHEMA, GET_SCHEMA_SYNC }
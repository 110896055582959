import { Division, ResultEntry, ResultType, Team, UniqueEntity, User, Vehicle } from "../../../types";
import { TeamActions, UserActions, VehicleActions } from "../../actions";

import EventUtils from "../../utils/event-utils";
import LoaderUtils from "../../utils/loader-utils";

import Modal from "../modal";
import CardComponent from "../../components/card/card-component";

const id = 'submit-result-entry-modal'
const title = 'Submit Result Entry'

const NONE_TEAM: Team = { 
  id: -1, 
  name: 'None', 
  color: '#FF1801',
  vehicle: { 
    id: -1, 
    name: '', 
    manufacturer: '', 
    game: { 
      id: -1, 
      name: '', 
      code: '', 
      console: { 
        id: -1, 
        name: '', 
        code: ''
      }
    }, 
    vehicle_category: ''
  }, 
  users: <Array<User>> [] 
}
abstract class  SubmitResultEntryModal<T extends ResultEntry> extends Modal<void> {

  division: Division
  entry?: T
  local_entry: T
  result_types: (loading_callback: () => void) => JQuery.jqXHR<any>
  origin_card?: CardComponent<T>

  constructor(division: Division, result_types: (loading_callback: () => void) => JQuery.jqXHR<any>, entry: T = undefined, origin_card?: CardComponent<T>) {
    super(id, title, undefined)
    this.division = division;
    this.entry = entry;
    this.local_entry = { ...entry }
    this.result_types = result_types
    this.origin_card = origin_card;
  }

  getTemplate(): string {
    Promise.all([
      this.result_types(() => { }),
      TeamActions.GET_TEAMS(this.division.id),
      VehicleActions.GET_VEHICLES(this.division.game.id),
      UserActions.GET_DIVISION_USERS(this.division.id),
    ]).then((responses) => {
      let result_types = <Array<ResultType>> responses[0].values

      const FIN_INDEX = result_types.findIndex(type => type.code === 'FIN');

      result_types = this.swapArrayElements(result_types, FIN_INDEX, 0)

      const active_result_type = this.getActiveItem(this.entry?.result_type, result_types)
      const result_types_content = this.getResultTypeContent(result_types, active_result_type)
      LoaderUtils.LOADED(`#${this.id} .result-types`, result_types_content)

      const users_response = responses[3].values
      const active_user = this.getActiveItem(this.entry?.user, users_response)
      const users_content = this.getUsersContent(users_response, active_user)
      LoaderUtils.LOADED(`#${this.id} .drivers`, users_content)

      const teams_response = responses[1].values;
      teams_response.unshift(NONE_TEAM);

      const active_team = this.getActiveItem(this.entry?.team, teams_response);
      const teams_content = this.getTeamsContent(teams_response, active_team)
      LoaderUtils.LOADED(`#${this.id} .teams`, teams_content)

      const vehicles_response = responses[2].values
      const active_vehicle = this.getActiveItem(this.entry?.vehicle, vehicles_response)

      const vehicle_content = this.getVehiclesContent(vehicles_response, active_vehicle)
      LoaderUtils.LOADED(`#${this.id} .vehicles`, vehicle_content)

      const result_specifics_content = this.getResultSpecifics(active_result_type)
      LoaderUtils.LOADED(`#${this.id} .submit-result-specifics`, result_specifics_content)

      // Defaults
      this.local_entry.result_type = active_result_type
      this.local_entry.user = active_user
      this.local_entry.team = active_team
      this.local_entry.vehicle = active_vehicle

      if(this.entry?.team === undefined) {
        this.triggerUserChange(active_user.id.toString(), teams_response, vehicles_response)
      }

      if(this.local_entry?.team.id === NONE_TEAM.id || this.local_entry?.team.vehicle === undefined || this.local_entry?.team.vehicle === null) {
        $(`#${this.id} .vehicles select`).removeAttr('disabled')
      } else {
        $(`#${this.id} .vehicles select`).attr('disabled', 'true')
      }
      
      this.registerChangeListeners(result_types, users_response, vehicles_response, teams_response)
      this.registerSpecificChangeListeners()
    })

    return `
      <div class="modal fade" id="${this.id}" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">${this.title}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">         
              <div class="form-group result-types">
                <label>Result Type</label>
                <div class="sk-three-bounce select-loader loader" style="display: block;">
                  <div class="bounce1"></div>
                  <div class="bounce2"></div>
                  <div class="bounce3"></div>
                </div>

                <select class="form-control loaded-content" required>
                    
                </select>
              </div>

              <div class="form-group drivers">
                <label>Drivers</label><br/>
                <div class="sk-three-bounce select-loader loader" style="display: block;">
                  <div class="bounce1"></div>
                  <div class="bounce2"></div>
                  <div class="bounce3"></div>
                </div>
              
                <select style="width: 100%;" class="form-control loaded-content" name="users" required>
                
                </select>
              </div>

              <div class="form-group teams">
                <label>Team</label>
                <div class="sk-three-bounce select-loader loader" style="display: block;">
                  <div class="bounce1"></div>
                  <div class="bounce2"></div>
                  <div class="bounce3"></div>
                </div>

                <select class="form-control loaded-content" required>
                    
                </select>
              </div>

              <div class="form-group vehicles">
                <label>Vehicle</label>
                <div class="sk-three-bounce select-loader loader" style="display: block;">
                  <div class="bounce1"></div>
                  <div class="bounce2"></div>
                  <div class="bounce3"></div>
                </div>

                <select class="form-control loaded-content" required>
                    
                </select>
              </div>

              <div class="submit-result-specifics">
                <div class="loaded-content"> 
                  <div class="media py-3 align-items-center justify-content-between">
                    <div style="overflow: hidden; white-space: nowrap;" class="media-body pr-3 ">
                      <a class="is-loading mt-0 mb-1 font-size-15 text-dark" href="#"></a>
                      <p class="is-loading" style="text-overflow: ellipsis; overflow: hidden;"></p>
                    </div>
                    <div style="overflow: hidden; white-space: nowrap;" class="media-body pr-3 ">
                      <a class="is-loading mt-0 mb-1 font-size-15 text-dark" href="#"></a>
                      <p class="is-loading" style="text-overflow: ellipsis; overflow: hidden;"></p>
                    </div>
                  </div>
                  <div class="media py-3 align-items-center justify-content-between">
                    <div style="overflow: hidden; white-space: nowrap;" class="media-body pr-3 ">
                      <a class="is-loading mt-0 mb-1 font-size-15 text-dark" href="#"></a>
                      <p class="is-loading" style="text-overflow: ellipsis; overflow: hidden;"></p>
                    </div>
                    <div style="overflow: hidden; white-space: nowrap;" class="media-body pr-3 ">
                      <a class="is-loading mt-0 mb-1 font-size-15 text-dark" href="#"></a>
                      <p class="is-loading" style="text-overflow: ellipsis; overflow: hidden;"></p>
                    </div>
                  </div>
                  <div class="media py-3 align-items-center justify-content-between">
                    <div style="overflow: hidden; white-space: nowrap;" class="media-body pr-3 ">
                      <a class="is-loading mt-0 mb-1 font-size-15 text-dark" href="#"></a>
                      <p class="is-loading" style="text-overflow: ellipsis; overflow: hidden;"></p>
                    </div>
                    <div style="overflow: hidden; white-space: nowrap;" class="media-body pr-3 ">
                      <a class="is-loading mt-0 mb-1 font-size-15 text-dark" href="#"></a>
                      <p class="is-loading" style="text-overflow: ellipsis; overflow: hidden;"></p>
                    </div>
                  </div>
                  <div class="media py-3 align-items-center justify-content-between">
                    <div style="overflow: hidden; white-space: nowrap;" class="media-body pr-3 ">
                      <a class="is-loading mt-0 mb-1 font-size-15 text-dark" href="#"></a>
                      <p class="is-loading" style="text-overflow: ellipsis; overflow: hidden;"></p>
                    </div>
                    <div style="overflow: hidden; white-space: nowrap;" class="media-body pr-3 ">
                      <a class="is-loading mt-0 mb-1 font-size-15 text-dark" href="#"></a>
                      <p class="is-loading" style="text-overflow: ellipsis; overflow: hidden;"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
            ${ this.entry !== undefined 
              ? ''
              : `<div>
                  <div class="form-check">
                    <input id="keep-open-input" class="form-check-input" type="checkbox" checked>
                    <label style="text-transform: none; color: #495057;" class="form-check-label">
                      Keep <a class="clickable-username color-dark" href="https://en.wikipedia.org/wiki/Modal_window" target="blank">this</a> open.
                    </label>
                  </div>
                </div>`
            }
              
              <div style="margin-left:auto;">
                <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">Close</button>
                <button id="submit-result-entry" type="button" class="btn btn-primary btn-pill">
                  <div style="display:none; height: 20px;" class="sk-three-bounce loader">
                    <div style="background-color:white;" class="bounce1"></div>
                    <div style="background-color:white;" class="bounce2"></div>
                    <div style="background-color:white;" class="bounce3"></div>
                  </div>
                  <span class="loaded-content">Submit</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    `
  }
  
  abstract getResultSpecifics(result_type: ResultType): string

  abstract registerSpecificChangeListeners(): void

  abstract onSubmit(): void

  reload(): void { }

  onDriverChange(): void { }

  getModalActions(): void  {
    EventUtils.REMOVE_ALL_EVENTS(`#${this.id} button#submit-result-entry`)
    EventUtils.REGISTER_CLICK_EVENT(`#${this.id} button#submit-result-entry`, () => {
      if(this.entry === undefined) {
        this.entry = this.local_entry
        this.origin_card.items.push(this.entry)
      } else {
        this.entry.result_type = this.local_entry.result_type
        this.entry.team = this.local_entry.team
        this.entry.vehicle = this.local_entry.vehicle
        this.entry.user = this.local_entry.user
      }
      
      this.onSubmit()

      const keep_open = $('#keep-open-input').is(":checked")

      if (keep_open) {
        this.entry = undefined;
        this.local_entry = { ...this.local_entry, finish_position: this.local_entry.finish_position + 1, points: undefined }
        this.reload()
      } else {
        this.close()
      }
      
      if(this.origin_card) {
        this.origin_card.refresh()
      }
    })
  }

  private registerChangeListeners(result_types: Array<ResultType>, users: Array<User>, vehicles: Array<Vehicle>, teams: Array<Team>): void {
    EventUtils.REGISTER_CHANGE_EVENT(`#${this.id} .result-types select`, (selector, event) => {
      const active_result_type = result_types.find(type => type.id === parseInt(event.target.value))

      this.local_entry.result_type = active_result_type;

      const result_specifics_content = this.getResultSpecifics(active_result_type)

      LoaderUtils.LOADED(`#${this.id} .submit-result-specifics`, result_specifics_content)
      
      // Content changed so we have to renew our listeners
      this.registerSpecificChangeListeners()
    })

    EventUtils.REGISTER_CHANGE_EVENT(`#${this.id} .drivers select`, (selector, event) => {      
      const active_user = users.find(user => user.id === parseInt(event.target.value))
      this.local_entry.user = active_user;

      this.triggerUserChange(event.target.value, teams, vehicles)

      const result_specifics_content = this.getResultSpecifics(this.local_entry.result_type)

      LoaderUtils.LOADED(`#${this.id} .submit-result-specifics`, result_specifics_content)
      
      // Content changed so we have to renew our listeners
      this.registerSpecificChangeListeners()
    })

    EventUtils.REGISTER_CHANGE_EVENT(`#${this.id} .teams select`, (selector, event) => {      
      const active_team = teams.find(team => team.id === parseInt(event.target.value))
      this.local_entry.team = active_team;
      
      this.triggerTeamChange(event.target.value, teams, vehicles)
    })

    EventUtils.REGISTER_CHANGE_EVENT(`#${this.id} .vehicles select`, (selector, event) => {  
      const active_vehicle = vehicles.find(vehicle => vehicle.id === parseInt(event.target.value))

      const vehicle_content = this.getVehiclesContent(vehicles, active_vehicle)
      LoaderUtils.LOADED(`#${this.id} .vehicles`, vehicle_content)
      
      this.local_entry.vehicle = active_vehicle;
    })
  }

  protected getActiveItem<T extends UniqueEntity>(active: T, entities: Array<T>): T {
    return active === undefined || active === null
      ? entities[0]
      : entities.find(entity => entity.id === active.id)
  }

  private getResultTypeContent(result_types: Array<ResultType>, active: ResultType) {
    let result_type_content = ''

    result_types.forEach(result_type => {
      result_type_content += `<option ${active.id === result_type.id ? 'selected' : ''} value="${result_type.id}">${result_type.code} (${result_type.name})</option>`
    })

    return result_type_content;
  }

  private getUsersContent(users: Array<User>, active: User) {
    let users_content = ''

    users.forEach(user => {
      users_content += `<option ${active.id === user.id ? 'selected' : ''} value="${user.id}">${user.username}</option>`
    })

    return users_content;
  }

  private getTeamsContent(teams: Array<Team>, active: Team) {
    let teams_content = ``

    teams.forEach(team => {
      teams_content += `<option ${active.id === team.id ? 'selected' : ''} value="${team.id}">${team.name}</option>`
    })

    return teams_content;
  }

  private getVehiclesContent(vehicles: Array<Vehicle>, active: Vehicle) {
    const hasDifferentCategories = [ ...new Set(vehicles.filter(vehicle => vehicle.vehicle_category !== '').map(vehicle => vehicle.vehicle_category)) ].length > 1

    let vehicles_content = ''

    vehicles.forEach(vehicle => {
      vehicles_content += `<option ${active.id === vehicle.id ? 'selected' : ''} value="${vehicle.id}">${vehicle.manufacturer} ${vehicle.name} ${hasDifferentCategories ? `(${vehicle.vehicle_category})` : ''}</option>`
    })

    return vehicles_content;
  }

  private triggerUserChange(value: string, teams: Array<Team>, vehicles: Array<Vehicle>) {
    const team = teams.find(team => team.users.find(u => u.id === parseInt(value)) !== undefined)

    this.local_entry.team = team === undefined ? NONE_TEAM : team;
    
    const teams_content = this.getTeamsContent(teams, this.local_entry.team)
    LoaderUtils.LOADED(`#${this.id} .teams`, teams_content)

    if(this.local_entry.team.id === NONE_TEAM.id || this.local_entry.team.vehicle === undefined || this.local_entry.team.vehicle === null) {
      $(`#${this.id} .vehicles select`).removeAttr('disabled')
    } else {
      const vehicle_content = this.getVehiclesContent(vehicles, this.local_entry.team.vehicle)
      LoaderUtils.LOADED(`#${this.id} .vehicles`, vehicle_content)
    
      this.local_entry.vehicle = this.local_entry.team.vehicle;

      $(`#${this.id} .vehicles select`).attr('disabled', 'true')
    }
  }

  private triggerTeamChange(value: string, teams: Array<Team>, vehicles: Array<Vehicle>) {
    const active_team = teams.find(team => team.id === parseInt(value))

    if(active_team.id === NONE_TEAM.id || active_team.vehicle === undefined || active_team.vehicle === null) {
      $(`#${this.id} .vehicles select`).removeAttr('disabled')
    } else {
      const vehicle_content = this.getVehiclesContent(vehicles, active_team.vehicle)
      LoaderUtils.LOADED(`#${this.id} .vehicles`, vehicle_content)
    
      this.local_entry.vehicle = active_team.vehicle;
    
      $(`#${this.id} .vehicles select`).attr('disabled', 'true')
    }
  }

  private swapArrayElements = (arr: Array<any>, left: number, right: number) => { 
    const _arr = [...arr]; 
    const temp = _arr[left]; 
    _arr[left] = _arr[right]; 
    _arr[right] = temp; 
    return _arr 
  }
}

export default SubmitResultEntryModal
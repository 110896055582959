import { Division, OnlineRacingLeague } from "../../types"
import { EventActions, ResultActions } from "../actions"
import RaceResultBannerComponent from "../components/banner/race-result-banner-component"
import EventNavigationComponent from "../components/event-navigation-component"
import QualifyingResultComponent from "../components/qualifying-result-component"
import RaceResultComponent from "../components/race-result-component"

const ON_PAGE_LOAD = (active_league: OnlineRacingLeague, active_division: Division): void => {

    $('#index-third-row').html(`
      <div id="race-result" class="col-xl-7 mb-4"></div>
      <div id="qualifying-result" class="col-xl-5 mb-2"></div>
    `)

    new EventNavigationComponent(
      '#index-first-row',
      'Event Result',
      active_division,
      EventActions.GET_EVENTS
    ).onEventChange((event) => {
      new RaceResultBannerComponent(
        '#index-second-row',
        event.id,
        active_division.game,
        ResultActions.GET_RACE_RESULTS
      ).render()
  
      new RaceResultComponent(
        '#race-result',
        event.id,
        ResultActions.GET_RACE_RESULTS
      ).render()
  
      new QualifyingResultComponent(
        '#qualifying-result',
        event.id,
        ResultActions.GET_QUALI_RESULTS
      ).render()
    }).render()    
}

export { ON_PAGE_LOAD }
import { Division, OnlineRacingLeague } from "../../types";
import { DivisionActions } from "../actions";
import DivisionCardComponent from "../components/card/division-card-component";

const ON_PAGE_LOAD = (active_league: OnlineRacingLeague, divisions: Array<Division>): void => {
  new DivisionCardComponent(
      '#index-first-row',
      'primary',
      active_league,
      DivisionActions.GET_DIVISIONS
  ).render()
}

export { ON_PAGE_LOAD }
import ApiUtils from '../../utils/api-utils'
import Store from '../../store/store'
import { api } from '../../config'
import { CreateEventRequest, EditEventRequest, Event, EventSignup, PointDistribution, RetrievalResponse, SubmitSignUpRequest } from '../../types';

const GET_EVENT = (event_id: number, loading_callback = () => {}, success_callback: (response: Event) => void = () => {}, error_callback = () => {}): void => {
  loading_callback();

  $.ajax({
    type: "GET",
    url: `${api}/event?event_id=${event_id}&token=${Store.ACCESS_TOKEN()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const GET_EVENT_SIGN_UPS = (event_id: number, loading_callback = () => {}, success_callback: (response: Array<EventSignup>) => void = () => {}, error_callback = () => {}): void => {
  loading_callback();

  $.ajax({
    type: "GET",
    url: `${api}/event/signup?event_id=${event_id}&token=${Store.ACCESS_TOKEN()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const GET_EVENTS = (division_id: number, loading_callback = () => {}, success_callback: (response: Array<Event>) => void = () => {}, error_callback = () => {}): JQuery.jqXHR<RetrievalResponse<Event>> => {
  loading_callback();

  return $.ajax({
    type: "GET",
    url: `${api}/event/division?division_id=${division_id}&token=${Store.ACCESS_TOKEN()}`,
    success: (response) => {
      const events = <Array<Event>> response.values;

      events.sort((left, right): number => {
        return new Date(left.date).getTime() - new Date(right.date).getTime();
      })
      
      ApiUtils.DEFAULT_SUCCESS_HANDLER({ values: events, ...response }, success_callback)
    },
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const GET_POINT_DISTRIBUTIONS = (loading_callback = () => {}, success_callback: (response: Array<PointDistribution>) => void = () => {}, error_callback = () => {}) : JQuery.jqXHR<RetrievalResponse<PointDistribution>> => {
  loading_callback();

  return $.ajax({
    type: "GET",
    url: `${api}/event/race_points?token=${Store.ACCESS_TOKEN()}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const CREATE_EVENT = (event: CreateEventRequest, loading_callback = () => {}, success_callback: () => void = () => {}, error_callback = () => {}): void => {
  loading_callback();

  $.ajax({
    type: "POST",
    url: `${api}/event`,
    data: JSON.stringify({
      token: Store.ACCESS_TOKEN(),
      ...event
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const EDIT_EVENT = (event: EditEventRequest, loading_callback = () => {}, success_callback: () => void = () => {}, error_callback = () => {}): void => {
  loading_callback();

  $.ajax({
    type: "POST",
    url: `${api}/event/edit`,
    data: JSON.stringify({
      token: Store.ACCESS_TOKEN(),
      ...event
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const DELETE_EVENT = (event_id: number, loading_callback = () => {}, success_callback: () => void = () => {}, error_callback = () => {}): void => {
  loading_callback();

  $.ajax({
    type: "DELETE",
    url: `${api}/event`,
    data: JSON.stringify({
      token: Store.ACCESS_TOKEN(),
      event_id: event_id
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const SUBMIT_SIGN_UP = (request: SubmitSignUpRequest, loading_callback = () => {}, success_callback: () => void = () => {}, error_callback = () => {}): void => {
  loading_callback();

  $.ajax({
    type: "POST",
    url: `${api}/event/signup`,
    data: JSON.stringify({
      token: Store.ACCESS_TOKEN(),
      ...request
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

export { GET_EVENT, GET_EVENT_SIGN_UPS, GET_EVENTS, GET_POINT_DISTRIBUTIONS, CREATE_EVENT, EDIT_EVENT, DELETE_EVENT, SUBMIT_SIGN_UP }
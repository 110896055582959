import { ReportPenalty, Report, Event, Division } from "../../../types";
import AlertUtils from "../../../utils/alert-utils";
import WordUtils from "../../../utils/word-utils";
import { EventActions, PenaltyActions } from "../../actions";
import CardComponent from "../../components/card/card-component";
import EventUtils from "../../utils/event-utils";
import LoaderUtils from "../../utils/loader-utils";
import ReportDetailsModal, { ReportDetailPanel } from "./report-details-modal";

class ServePenaltyModal extends ReportDetailsModal {

  id = 'serve-penalty'
  title = 'Serve Penalty'
  penalties: Array<ReportPenalty>
  origin_card?: CardComponent<any>

  constructor(division: Division, report: Report, origin_card?: CardComponent<any>) {
      super(report, [ ReportDetailPanel.PENALTIES ])
      this.division = division;
      this.penalties = report.report_penalties;
      this.origin_card = origin_card;
  }

  hasExtraPanels(): boolean {
    return true;
  }

  getExtraPanels(report: Report): string {
    return `
      ${super.getExtraPanels(report)}

      <div id="${this.id}" class="ofr-modal modal-content" style="">
        <div class="modal-header">
          <h5 class="modal-title">${this.title}</h5>
        </div>
        <div class="modal-body">                
          <div class="form-group events">
            <label>Served at</label>
            <div class="sk-three-bounce select-loader loader">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
            </div>
            
            <select class="form-control loaded-content" required>
                
            </select>
          </div> 

          <div class="form-group penalties">
            <label>Penalty</label>
            <div class="sk-three-bounce select-loader loader">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
            </div>
            
            <select class="form-control loaded-content" required>
                
            </select>
          </div> 
        </div>
        <div class="modal-footer">
          <button id="serve-penalty" type="button" class="btn btn-primary btn-pill">
            <div style="display:none; height: 20px;" class="loader sk-three-bounce">
              <div style="background-color:white;" class="bounce1"></div>
              <div style="background-color:white;" class="bounce2"></div>
              <div style="background-color:white;" class="bounce3"></div>
            </div>
            <span class="loaded-content">Serve</span>
          </button>
        </div>
      </div>
    `
  }

  getModalActions(report: Report): void {
    this.getFormData(report)

    EventUtils.REMOVE_ALL_EVENTS(`#${this.id} button#serve-penalty`)

    EventUtils.REGISTER_CLICK_EVENT(
      `#${this.id} button#serve-penalty`,
      () => {
        const event_id = <string> $(`#${this.id} .events select`).val()
        const penalty_id = <string> $(`#${this.id} .penalties select`).val()

        PenaltyActions.SERVE_PENALTY(
          {
            report_penalty_id: parseInt(penalty_id),
            event_id: parseInt(event_id)
          },
          () => { LoaderUtils.LOADING(`#${this.id} button#serve-penalty`) },
          () => { 
            AlertUtils.NOTIFY('Penalty successfully served.', 'Success!')
            $(`#${this.id}`).modal('hide')
            
            if(this.origin_card) this.origin_card.reload()
          },
          () => { LoaderUtils.LOADED(`#${this.id} button#serve-penalty`, 'Serve') }
        )
      }
    )
  }

  private getFormData(report: Report): void {
    LoaderUtils.LOADING(`#${this.id} .penalties`)

    EventActions.GET_EVENTS(
      this.division.id,
      () => { LoaderUtils.LOADING(`#${this.id} .events`) },
      (events) => {
        const events_content = this.getEventContent(events);
        LoaderUtils.LOADED(`#${this.id} .events`, events_content)
      }
    )

    const penalty_content = this.getPenaltyContent(report.report_penalties)
    LoaderUtils.LOADED(`#${this.id} .penalties`, penalty_content);
  }

  private getEventContent(events: Array<Event>): string {
    let event_content = ''

    events.forEach((event, index) => {
      event_content += `<option value="${event.id}">(${index + 1}) ${WordUtils.FIRST_UPPER_CASE(event.track.name)}</option>`
    })

    return event_content;
  }

  private getPenaltyContent(penalties: Array<ReportPenalty>): string {
    let penalty_content = ''

    penalties.forEach(penalty => {
      penalty_content += `<option value="${penalty.id}">${penalty.penalty_user.username} - ${WordUtils.FIRST_UPPER_CASE(penalty.penalty.name)} (${penalty.penalty.penalty_points} PP)</option>`
    })

    return penalty_content;
  }
}

export default ServePenaltyModal
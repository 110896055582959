import ApiUtils from '../../utils/api-utils'
import Store from '../../store/store'
import { api } from '../../config'
import { Vehicle } from '../../types'

const GET_VEHICLES = (game_id: number, loading_callback = () => {}, success_callback: (response: Array<Vehicle>) => void = () => {}, error_callback = () => {}): JQuery.jqXHR => {
  loading_callback()

  return $.ajax({
    type: "GET",
    url: `${api}/vehicle?token=${Store.ACCESS_TOKEN()}&game_id=${game_id}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

export { GET_VEHICLES }
import ApiUtils from '../../utils/api-utils'
import Store from '../../store/store'
import { api } from '../../config'
import { Report, RetrievalResponse, SubmitAdditionalInfoRequest, SubmitReportRequest, SubmitVoteRequest } from '../../types'

const GET_OPEN_REPORTS = (division_id: number, loading_callback = () => {}, success_callback: (response: Array<Report>) => void = () => {}, error_callback = () => {}): void => {
  loading_callback()

  $.ajax({
    type: "GET",
    url: `${api}/reports/open?token=${Store.ACCESS_TOKEN()}&${division_id === null ? '' : `season_id=${division_id}` }`,
    success: (response: RetrievalResponse<Report>) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  })
}

const GET_CLOSED_REPORTS = (division_id: number, success_callback: (response: Array<Report>) => void = () => {}, error_callback = () => {}): void => {
  $.ajax({
    type: "GET",
    url: `${api}/reports/closed?token=${Store.ACCESS_TOKEN()}&${division_id === null ? '' : `season_id=${division_id}` }`,
    success: (response: RetrievalResponse<Report>) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  })
}

const GET_MY_OPEN_REPORTS = (division_id: number, success_callback: (response: Array<Report>) => void = () => {}, error_callback = () => {}): void => {
  $.ajax({
    type: "GET",
    url: `${api}/reports_penalties/my_reports/open?token=${Store.ACCESS_TOKEN()}&division_id=${Store.ACTIVE_DIVISION()}`,
    success: (response: RetrievalResponse<Report>) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  })
}

const GET_MY_CLOSED_REPORTS = (division_id: number, success_callback: (response: Array<Report>) => void = () => {}, error_callback = () => {}): void => {
  $.ajax({
    type: "GET",
    url: `${api}/reports_penalties/my_reports/closed?token=${Store.ACCESS_TOKEN()}&division_id=${Store.ACTIVE_DIVISION()}`,
    success: (response: RetrievalResponse<Report>) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  })
}

const GET_MY_OPEN_INVOLVEMENTS = (division_id: number, success_callback: (response: Array<Report>) => void = () => {}, error_callback = () => {}): void => {
  $.ajax({
    type: "GET",
    url: `${api}/reports_penalties/involved_reports/open?token=${Store.ACCESS_TOKEN()}&division_id=${Store.ACTIVE_DIVISION()}`,
    success: (response: RetrievalResponse<Report>) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  })
}

const GET_MY_CLOSED_INVOLVEMENTS = (division_id: number, success_callback: (response: Array<Report>) => void = () => {}, error_callback = () => {}): void => {
  $.ajax({
    type: "GET",
    url: `${api}/reports_penalties/involved_reports/closed?token=${Store.ACCESS_TOKEN()}&division_id=${Store.ACTIVE_DIVISION()}`,
    success: (response: RetrievalResponse<Report>) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  })
}

const GET_VOTED_REPORTS = (division_id: number, success_callback: (response: Array<Report>) => void = () => {}, error_callback = () => {}): void => {
  $.ajax({
    type: "GET",
    url: `${api}/reports_penalties/steward/voted?token=${Store.ACCESS_TOKEN()}&division_id=${Store.ACTIVE_DIVISION()}`,
    success: (response: RetrievalResponse<Report>) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  })
}

const GET_UNVOTED_REPORTS = (division_id: number, success_callback: (response: Array<Report>) => void = () => {}, error_callback = () => {}): void => {
  $.ajax({
    type: "GET",
    url: `${api}/reports_penalties/steward/unvoted?token=${Store.ACCESS_TOKEN()}&division_id=${Store.ACTIVE_DIVISION()}`,
    success: (response: RetrievalResponse<Report>) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  })
}

const SUBMIT_REPORT = (report: SubmitReportRequest, loading_callback = () => {}, success_callback: () => void = () => { }, error_callback = () => {}) : void=> {
  loading_callback()

  $.ajax({
    type: "POST",
    url: `${api}/reports_penalties/report`,
    data: JSON.stringify({
      token: Store.ACCESS_TOKEN(),
      ...report
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const SUBMIT_ADDITIONAL_INFO = (additional_info: SubmitAdditionalInfoRequest, loading_callback = () => {}, success_callback: () => void = () => { }, error_callback = () => {}): void => {
  loading_callback()

  $.ajax({
    type: "POST",
    url: `${api}/reports_penalties/additional_info`,
    data: JSON.stringify({
      token: Store.ACCESS_TOKEN(),
      ...additional_info
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const SUBMIT_VOTE = (vote: SubmitVoteRequest, loading_callback = () => {}, success_callback = () => {}, error_callback = () => {}): void => {
    loading_callback();

  $.ajax({
    type: "POST",
    url: `${api}/reports_penalties/steward/vote`,
    data: JSON.stringify({
      token: Store.ACCESS_TOKEN(),
      ...vote
    }),
    contentType: 'application/json',
    dataType: 'json',
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback, error_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const DELETE_REPORT = (report_id: number, loading_callback = () => { }, success_callback = () => { }, error_callback = () => {}): void => {
  loading_callback()

  $.ajax({
    type: "DELETE",
    url: `${api}/reports_penalties/report?token=${Store.ACCESS_TOKEN()}&report_id=${report_id}`,
    success: (response) => ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback),
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

export { GET_OPEN_REPORTS, GET_CLOSED_REPORTS, GET_MY_OPEN_REPORTS, GET_MY_CLOSED_REPORTS, GET_MY_OPEN_INVOLVEMENTS, GET_MY_CLOSED_INVOLVEMENTS, GET_VOTED_REPORTS, GET_UNVOTED_REPORTS, SUBMIT_VOTE, SUBMIT_ADDITIONAL_INFO, SUBMIT_REPORT, DELETE_REPORT }
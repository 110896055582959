import { Division, StandingEntry } from "../../../types";
import ResultUtils from "../../utils/result-utils";
import StandingUtils from "../../utils/standing-utils";

abstract class StandingBannerComponent<R extends StandingEntry> {

  container_selector: string
  division: Division
  standings_fn: (division_id: number, loading_callback: () => void, callback: (standings: Array<R>) => void, error_callback: () => void) => void

  constructor(container_selector: string, division: Division, standings_fn: (division_id: number, loading_callback: () => void, callback: (standings: Array<R>) => void, error_callback: () => void) => void) {
    this.container_selector = container_selector;
    this.division = division;
    this.standings_fn = standings_fn;
  }

  render(): void {
    this.standings_fn(
      this.division.id,
      () => { },
      (standings) => {
        const content = this.getContent(standings);
        $(this.container_selector).html(content);
      },
      () => { }
    )    
  }

  getContent(standings: Array<R>): string {
    if(standings.length === 0) {
      return '';
    }

    const leader = standings[0]

    return `
      <div class="col-md-12 mb-9">
        <div class="results-header" style="border-top:4px solid ${ResultUtils.GET_COLOR_BY_VEHICLE(StandingUtils.GET_VEHICLE(leader))};">
          <div class="position">
              <img src="images/first-ribbon.png" />
          </div>
          <div class="driver-details">
              ${ this.getLeaderDetail(leader) }
          </div>
          <div class="top-3">
              <table class="race-leaderboard leaderboard" id="race-leaderboard">
                  ${ this.getRemainingTop3(standings) }
              </table>
          </div>
          <div class="car-details">
              <img ${this.division.game.code === 'ACC-PS4' ? 'class="acc"' : ''}  src="https://images.onlineformularacing.co.uk/Vehicles/${StandingUtils.GET_VEHICLE(leader).id}${this.division.game.code === 'ACC-PS4' ? '-featured' : ''}.webp" />
          </div>
        </div>
      </div>
    `
  }

  getRemainingTop3(standings: Array<R>): string {
    let content = ''

    standings
      .filter((standing, index) => index === 0 || index === 1)
      .forEach((standing, index) => {
        content += `
          <tr class="driver">
            <td style="padding-right: 0px;" class="position"><span>${index + 1}</span></td>
            <td class="driver" style="border-left: 4px solid ${StandingUtils.GET_COLOR(standing)};">
                ${ this.getEntryDetail(standing) }
            </td>
            <td class="gap">
                <span>${ standing.points }</span>
            </td>
          </tr>
        `
      })

    return content;
  }

  abstract getLeaderDetail(leader: R): string
  abstract getEntryDetail(entry: R): string
}

export default StandingBannerComponent
import { Division, OnlineRacingLeague } from "../../types";
import { PrivilegeActions } from "../actions";
import DivisionUserCardComponent from "../components/card/division-user-card-component";

const ON_PAGE_LOAD = (active_league: OnlineRacingLeague, active_division: Division): void => {
  new DivisionUserCardComponent(
      '#index-first-row',
      'primary',
      active_league,
      active_division,
      PrivilegeActions.GET_DIVISION_USERS_PRIVILEGES
  ).render()
}

export { ON_PAGE_LOAD }
import { Division, StandingEntry } from "../../types";
import StandingUtils from "../utils/standing-utils";

abstract class StandingComponent<R extends StandingEntry> {
    
  container_selector: string
  col_class: string

  title: string
  division: Division
  standings_fn: (division_id: number, loading_callback: () => void, callback: (standings: Array<R>) => void, error_callback: () => void) => void


  constructor(container_selector: string, col_class: string, title: string, division: Division, standings_fn: (division_id: number, loading_callback: () => void, callback: (standings: Array<R>) => void, error_callback: () => void) => void) {
    this.container_selector = container_selector;
    this.col_class = col_class;
    
    this.title = title
    this.division = division
    this.standings_fn = standings_fn
  }

  render(): void {
    this.standings_fn(
      this.division.id,
      () => { },
      (standings) => {
        const content = this.getContent(standings);
        $(this.container_selector).append(content);
      },
      () => { }
    )
  }

  getContent(standings: Array<R>): string {
    return `
      <div class="${this.col_class} mb-4">
        <div class="heading-section ftco-animate fadeInUp ftco-animated">
          <span class="subheading">${this.title}</span>
          <h2 class="mb-4"></h2>
        </div>
        <div class="scoreboard mb-lg-3">
          <table class="race-leaderboard leaderboard" id="race-leaderboard">
            ${ this.getRowContent(standings) }
          </table>
        </div>
      </div>
    `
  }

  getRowContent(standings: Array<R>): string {
    if(standings.length === 0) {
      return `<tr><td style="color:#C8C8C8;">NOT AVAILABLE YET.</td></tr>`
    }

    let content = ''

    standings.forEach((standing, index) => {
      content += `
        <tr class="driver">
          <td style="padding-right: 0px;" class="position"><span class="color-dark">${index + 1}</span> </td>
          <td class="driver" style="border-left: 4px solid ${ StandingUtils.GET_COLOR_BY_TEAM(standing) };">
            ${ this.getDetail(standing) }
          </td>
          <td class="pts"><span>${standing.points}</span></td>
        </tr>
      `
    })

    return content;
  }

  abstract getDetail(entry: R): string
}

export default StandingComponent;
import { Service } from "../authentication/types"
import ServiceActions from "../dashboards/actions/service-actions"
import Store from "../store/store"

const HAS_PERMISSION = (service_id: number, force_refresh = false): boolean => {
    const permissions: Array<Service> = Store.USER_AUTHORIZED_SERVICES() === null || force_refresh
        ? ServiceActions.LOAD_AUTHORIZED_SERVICES_SYNC(Store.ACTIVE_DIVISION())
        : <Array<Service>> JSON.parse(Store.USER_AUTHORIZED_SERVICES())

    Store.SET_USER_AUTHORIZED_SERVICES(
        JSON.stringify(permissions)
    )
        
    return service_id === -1 || permissions.find(permission => permission.id === service_id) !== undefined
}

export default { HAS_PERMISSION }
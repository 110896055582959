import { Division, Event } from "../../types";
import { DivisionActions, EventActions } from "../actions"
import EventCardComponent, { EventListItemActions } from "../components/card/event-card-component";
import ListComponent from "../components/list/list-component";
import CreateEventModal from "../modals/event/create-event-modal";
import EventUtils from "../utils/event-utils";

const ON_PAGE_LOAD = (active_division: Division): void => {
  $('#index-first-row').css("margin-top", "2.5rem")
  $('.page-buttons').html(
    `<button type="button" id="open-create-event-modal-btn" class="btn btn-primary btn-pill">
        Create event
      </button>`
  )

  const LC = new ListComponent(
    '#index-first-row', 
    active_division, 
    EventCardComponent, 
    [ EventListItemActions.EDIT ], 
    (division_id: number, loading_callback: () => void, callback: (content: Array<Event>) => void) => {
      EventActions.GET_EVENTS(division_id, loading_callback, (content: Array<Event>) => {
        const first_upcoming_event = content.find(event => event.date > new Date().getTime())
        
        if(first_upcoming_event) {
          EventActions.GET_EVENT_SIGN_UPS(first_upcoming_event.id, () => {}, (signups) => {
            first_upcoming_event.event_signups = signups;
  
            callback(content);
          })
        } else {
          callback(content);
        }       
      })
    }
  )
  
  LC.render()

  EventUtils.REGISTER_CLICK_EVENT('#open-create-event-modal-btn', () => {
    new CreateEventModal(active_division)
      .onClose(() => { LC.refresh() })
      .render()  
  })
}

export { ON_PAGE_LOAD }
import { UserActions } from "../../dashboards/actions";
import SidebarUtils from "../../dashboards/utils/sidebar-utils"
import LoaderUtils from "../../dashboards/utils/loader-utils";
import { Message, OnlineRacingLeague, Pages } from "../../types";
import EventUtils from "../../dashboards/utils/event-utils";
import { menu } from "../../menu/menu";
import Navigation from "../../menu/navigation";
import TimeUtils from "../../dashboards/utils/time-utils";
import WordUtils from "../../utils/word-utils";

const ON_PAGE_LOAD = (active_league: OnlineRacingLeague): void => {  
  UserActions.GET_USER(
    () => LoaderUtils.LOADING('header .ofr-username'),
    (user) => {
      LoaderUtils.LOADED('header .ofr-username', user.username)
      $(".user-image").attr("src", `https://images.onlineformularacing.co.uk/UserImages/${user.user_image_id}.png`)
      $(".img-circle").attr("src", `https://images.onlineformularacing.co.uk/UserImages/${user.user_image_id}.png`)
    }
  )

  $(".brand-name").html(active_league.name)

  const content = getContent();

  $("#header").html(content)  

  EventUtils.REGISTER_ON_KEY_UP_EVENT(`#search-input`, (element, event) => {
    let content = ''

    const query = event.target.value;

    if(query !== '') {
      menu.filter(menu_item => {
        const pages = menu_item.childs;

        pages.forEach(page => {
          if(page.tags.includes(query)) {
            content += `
              <li>
                <div class="link">
                  <a href="/${page.page.path}" data-path="${page.page.path}">${page.label}</a>
                </div>
                <div class="location">
                  ${menu_item.label} <i class="mdi mdi-chevron-right"></i> ${page.label}
                </div>
              </li>`
          }
        })
      })

      if(content === '') {
        content = `<li>No results found</li>`
      }

      $('#search-results').html(content)
    } else {
      $('#search-results').html('')
    }

    EventUtils.REGISTER_CLICK_EVENT(`#search-results li .link a`, (event) => {
      const path = event.target.getAttribute("data-path");
      Navigation.NAVIGATE(path)

      $('#search-results').html('')
    })
  })

  UserActions.GET_MESSAGES(
    () => { },
    (messages) => {
      const relevant_messages = messages
        .filter(m => m.league.id === active_league.id)
        .filter(m => ! m.read)

      if(relevant_messages.length > 0) {
        let content = `
          <li class="dropdown-header">
            Notifications

            <div style="float:right;" class="d-flex rounded-circle align-items-center justify-content-center mr-3 iconbox-25 bg-primary text-white">
              ${relevant_messages.length}
            </div>
          </li>`

        content += getMessageContent(relevant_messages)

        content += `<li class="dropdown-footer action">
          <a class="text-center" href="#/inbox"> View All </a>
        </li>`

        $("ul#messages-container").html(content)

        EventUtils.REGISTER_CLICK_EVENT('ul#messages-container .dropdown-footer a', () => {
          Navigation.NAVIGATE(Pages.INBOX.path)
        })
      } else {
        $("ul#messages-container").html(`
          <li class="dropdown-header" style="border-bottom:0px;">
            Notifications

            <div style="float:right;" class="d-flex rounded-circle align-items-center justify-content-center mr-3 iconbox-25 bg-primary text-white">
              ${relevant_messages.length}
            </div>
          </li>
          <li class="dropdown-footer action"><a class="text-center" href="#/inbox">Go to inbox</a></li>
        `)

        EventUtils.REGISTER_CLICK_EVENT('ul#messages-container .dropdown-footer a', () => {
          Navigation.NAVIGATE(Pages.INBOX.path)
        })
      }
    }
  )

  SidebarUtils.LOAD()
}

function getMessageContent(messages: Array<Message>): string {
  let messages_content = ''

  messages.forEach(message => {
    const parsed = TimeUtils.PARSE_EPOCH(message.date);

    messages_content += `
      <li>
        <a href="javscript:void(0)" class="media media-message media-notification">

          <div class="d-flex rounded-circle align-items-center justify-content-center mr-3 media-icon iconbox-25 bg-primary text-white">
            <i class="mdi mdi-message font-size-10"></i>
          </div>

          <div class="media-body d-flex justify-content-between">
            <div class="message-contents">
              <h4 class="title">${WordUtils.FIRST_UPPER_CASE(message.subject)}</h4>
              <p class="last-msg font-size-14">${message.message}</p>

              <span class="font-size-10 font-weight-medium text-secondary" style="float: right; margin-top: 0.3rem;>
                <i class="mdi mdi-clock-outline"></i> ${parsed.day.number} ${parsed.month.name} ${parsed.year}
              </span>
            </div>
          </div>
        </a>
      </li>
    `
  })

  return messages_content;
}

function getContent() {
  return `
    <nav class="navbar navbar-static-top navbar-expand-lg">
      <!-- Sidebar toggle button -->
      <button id="sidebar-toggler" class="sidebar-toggle">
        <span class="sr-only">Toggle navigation</span>
      </button>
      <!-- search form -->
      <div class="search-form d-none d-lg-inline-block">
        <div class="input-group">
          <button type="button" name="search" id="search-btn" class="btn btn-flat">
            <i class="mdi mdi-magnify"></i>
          </button>
          <input type="text" name="query" id="search-input" class="form-control" placeholder="'reports', 'results', 'standings' etc." autofocus="" autocomplete="off">
        </div>
        <div id="search-results-container">
          <ul id="search-results"></ul>
        </div>
      </div>

      <div class="navbar-right ">
        <ul class="nav navbar-nav">
          <li class="dropdown notifications-menu">
            <button class="dropdown-toggle" data-toggle="dropdown">
              <i class="mdi mdi-bell-outline"></i>
            </button>
            <ul id="messages-container" class="dropdown-menu dropdown-menu-right">
              
            </ul>
          </li>
          <!-- User Account -->
          <li class="dropdown user-menu">
            <button href="#" class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="false">
            <img src="images/anonymous-user.jpeg" class="user-image" alt="User Image">
              <span class="ofr-username d-none d-lg-inline-block">
                <div class="sk-three-bounce loader">
                  <div class="bounce1"></div>
                  <div class="bounce2"></div>
                  <div class="bounce3"></div>
                </div>
                <span class="loaded-content"></span>
              </span>
            </button>
            <ul class="dropdown-menu dropdown-menu-right">
              <!-- User image -->
              <li class="dropdown-header">
                <img src="images/anonymous-user.jpeg" class="img-circle" alt="User Image">
                <div class="d-inline-block">
                  <span class="ofr-username">
                    <div class="sk-three-bounce loader">
                      <div class="bounce1"></div>
                      <div class="bounce2"></div>
                      <div class="bounce3"></div>
                    </div>
                    <span class="loaded-content"></span>
                  </span>
                  <small class="pt-1 ofr-email">something@gmail.com</small>
                </div>
              </li>

              <li class="action">
                <a href="#">
                  <i class="mdi mdi-account"></i> My Profile
                </a>
              </li>
              <li class="action">
                <a href="#"> <i class="mdi mdi-cog"></i> Settings </a>
              </li>

              <li class="dropdown-footer action">
                <a class="logout" href="login.html?logout"> <i class="mdi mdi-logout"></i> Log Out </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  `
}

export default { ON_PAGE_LOAD }
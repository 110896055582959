const IS_VALID_TIME_FORMAT = (value: string): boolean => {
    return ! /^(\d{2}:)?\d{2}:\d{2}\.\d{3}$/.test(value)
}

const INVALIDATE = (selector: string, reason: string): void => {
    if($(selector).next() === undefined || $(selector).next().hasClass("invalid-feedback")) {
        return
    }

    $(selector).addClass("is-invalid")
    $(`<div class="invalid-feedback">&nbsp;&nbsp;${reason}</div>`).insertAfter(selector)
}

const RESET = (selector: string): void => {
    $(selector).removeClass("is-invalid")
    $(`${selector} + .invalid-feedback`).remove()
}
  
export default { IS_VALID_TIME_FORMAT, INVALIDATE, RESET }
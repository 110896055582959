import ApiUtils from '../../utils/api-utils'
import Store from '../../store/store'
import { api } from '../../config'
import { Service } from '../../authentication/types';

const LOAD_AUTHORIZED_SERVICES = (division_id: number, success_callback: (response: Array<Service>) => void = () => { }, error_callback = () => {}): void => {
  if(division_id === null) {
    console.error("Unknown division to retrieve services for.")
    return;
  }

  $.ajax({
    type: "GET",
    url: `${api}/user/services?token=${Store.ACCESS_TOKEN()}&division_id=${division_id}`,
    success: (response) => {
      Store.SET_USER_AUTHORIZED_SERVICES(JSON.stringify(response.values))
      ApiUtils.DEFAULT_SUCCESS_HANDLER(response, success_callback)
    },
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback)
  });
}

const LOAD_AUTHORIZED_SERVICES_SYNC = (division_id: number, error_callback = () => {}): Array<Service> => {
  let result: Array<Service> = []

  if(division_id === null) {
    console.error("Unknown division to retrieve services for.")
    return result;
  }

  $.ajax({
    type: "GET",
    url: `${api}/user/services?token=${Store.ACCESS_TOKEN()}&division_id=${division_id}`,
    success: (response) => {
      Store.SET_USER_AUTHORIZED_SERVICES(JSON.stringify(response.values))
      result = response.values
    },
    error: (xhr, status, error) => ApiUtils.DEFAULT_ERROR_HANDLER(xhr, status, error, error_callback),
    async: false
  });

  return result
}
  
export default { LOAD_AUTHORIZED_SERVICES, LOAD_AUTHORIZED_SERVICES_SYNC }
